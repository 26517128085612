.game-chat {
  border: 1px solid #f2f2f2;
  padding: 10px 5px;

  h4 {
    text-align: center;
    margin-bottom: 10px;
  }

  .messages {
    display: flex;
    flex-direction: column;
    height: 240px;
    overflow: auto;
    margin-bottom: 5px;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #384c6c;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    h6 {
      margin-top: 50% - 10;
      text-align: center;
      color: #384c6c;
    }
  }

  .message-container {
    .message {
      width: 60%;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 20px;

      .message-header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }

  .message-right {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;

    .message {
      background-color: #384c6c;
      color: #fff;
    }
  }

  .message-left {
    .message {
      background-color: #e6ecee;
      color: #000;
    }
  }

  .game-chat-footer {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    .send-button {
      height: 45px;
      font-size: 14px;
      width: 30%;
      max-width: 58px;
    }
    .emoji-picker-button {
      height: 45px;
    }
    .message-input {
      width: 100%;
    }
  }
}

.emoji-picker-item {
  display: inline-block;
  font-size: 24px;
  margin-right: 5px;
  cursor: pointer;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.3);
  }

  &:last-of-type {
    margin-right: 0;
  }

  img {
    width: 32px;
    height: 32px;
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animation-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
}

.emoji-animation {
  img {
    width: 140px;
    height: 140px;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: scale 3s linear infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
    transform-origin: center;
  }
  100% {
    transform: scale(4);
    transform-origin: center;
  }
}

