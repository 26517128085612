.about-main{
    margin-top: 120px;
    margin-bottom: 60px;
}

.about_head{
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #384C6C;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
}

.about-main .cardbody{
    padding: 40px;
}

@media(max-width: 575px){
    .about-main .cardbody{
        padding: 20px;
    }
}

// aboutus_header
.aboutus_header{
    text-align: center;
    img{
        width: 80px;
        height: 80px;
        text-align: center;
        display: block;
        margin: 0 auto 15px auto;
    }
}

.aboutus_header p{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 22px;
    color: #031424;
    margin-bottom: 0px;
} 

@media(max-width: 767px){
    .aboutus_header img{ 
        width: 70px;
        height: 70px;
        margin: 0 auto 10px auto;
    }
    .aboutus_header p{
        font-size: 14px;
    }
}

@media(max-width: 480px){
    .aboutus_header p{
        text-align: justify;
    }
}

// aboutus_body
.aboutus_body{
    margin: 35px 0px;
}

.founder{
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

.founder img{
    margin-bottom: 10px;
}

.founder h5{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 6px;
}

.founder p{
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #031424;
    margin-bottom: 0px;
}

@media(max-width: 991px){
    .founder{
        width: 100%;
    }
}

@media(max-width: 767px){
    .founder img{
        width: 100%;
    }
    .aboutus_body{
        margin: 35px 0px;
    }
    .founder h5{
        font-size: 17px;
    }
    .founder p{
        font-size: 14px;
    }
}

@media(max-width: 767px){
    .founder{
        width: 50%;
        margin-bottom: 30px;
    }
    .aboutus_body{
        margin: 35px 0px 0px 0px;
    }
}

@media(max-width: 480px){
    .founder{
        width: 100%;
    }
    .founder img{ 
        width: 50%;
    }
    .founder p{
        width: 70%;
        margin: 0 auto;
    }
}

//aboutus_footer
.aboutus_footer p{
    margin-bottom: 15px;
}

.aboutus_footer img{
    width: auto;
    margin-bottom: 0px;
}