// PLAYER DETAIL
.playerdetail {
  position: relative;
}

.playerdetail .player_image {
  width: 60%;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: cover;
  filter: blur(1px);
}

// CAROUSEL
.slick-slide.slick-active.slick-center .playerdetail {
  .player_image1 {
    animation: ani 5s linear;
    box-shadow: inset 6px 6px 20px 0 rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
      rgba(0, 0, 0, 0.05) 0px 16px 45px;
  }
}

.slick-track {
  display: flex !important;
  align-items: center;
}

@media (max-width: 991px) {
  
}

.playerreveal-slider .slick-list {
  overflow: inherit;
  min-height: 320px;
}

// CAROUSEL BLUR IMAGE REMOVE
.slick-active.slick-center .playerdetail .player_image {
  filter: blur(0px);
}

// CAROUSEL CENTER IMAGE WIDTH
.slick-active.slick-center {
  .playerdetail .player_image {
    width: 70%;
    height: 100%;
  }
}

@media (max-width: 991px) {
  .slick-active.slick-center {
    .playerdetail .player_image {
      width: 80%;
    }
  }
}

@media (max-width: 767px) {
  .slick-active.slick-center {
    .playerdetail .player_image {
      width: 50%;
    }
  }
}

@media (max-width: 575px) {
  .slick-active.slick-center {
    .playerdetail .player_image {
      width: 60%;
    }
  }
}

@media (max-width: 480px) {
  .slick-active.slick-center {
    .playerdetail .player_image {
      width: 100%;
    }
  }
}

// CAROUSEL REVEAL CODE SHOW IN CENTER IMAGE
.slick-active.slick-center .reveal-code {
  display: block;
}

// PLAYER REVEAL ANIMATION
.playeranimation_main {
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  align-items: center;
}

@keyframes ani {
  0% {
    z-index: -10000;
  }
  30% {
    z-index: -1;
  }
  55% {
    transform: rotateY(180deg);
  }
  38% {
    z-index: 10000;
  }
  47% {
    z-index: 0;
  }
  47% {
    transform: rotateY(180deg);
  }
  100% {
    filter: none;
  }
}

.slick-slide .playeranimation_main .animation {
  position: absolute;
  object-fit: contain;
  width: 100%;
  display: none;
  z-index: 99;
  height: 100%;
}
.slick-slide .playeranimation_main .animationView {
  position: absolute;
  object-fit: contain;
  width: 100%;
  display: none;
  z-index: 99;
  height: 100%;
}

.slick-active.slick-center .playeranimation_main .animation {
  display: none;
}
.slick-active.slick-center .playeranimation_main .animationView {
  display: block;
}

// SUPER SKILL
.slick-slide.slick-active.slick-center .superskill_animation {
  .player_image1 {
    animation: superskill 5s linear !important;
  }
}

// .slick-active.slick-center .superskill_animation .reveal-code{

// }

// .slick-active.slick-center .superskill_animation .reveal-code1{
//     animation: superskill 5s linear!important;
// }

@keyframes superskill {
  55% {
    transform: rotateY(180deg);
  }
  47% {
    transform: rotateY(180deg);
  }
  100% {
    filter: none;
  }
}

// REVEAL CODE
.reveal-code {
  display: none;
  transition: 0.5s all;
}

.reveal-code1 {
  animation: ani 5s linear;
}

.reveal-code h5 {
  position: absolute;
  bottom: -4px;
  width: 100%;
  color: #384c6c;
  font-size: 25px;
  font-weight: 700;
}

.slick-active.slick-center .reveal-code {
  background-color: #d75a57;
  color: #384c6c;
  position: absolute;
  top: 20px;
  right: 30px;
  width: 65%;
  float: right;
  height: 100%;
  margin-top: 0vh;
  z-index: -1;
  border-radius: 4px;
  box-shadow: inset 6px 6px 20px 0 rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
    rgba(0, 0, 0, 0.05) 0px 16px 45px;
}

@media (max-width: 991px) {
  .slick-active.slick-center .reveal-code {
    width: 80%;
    right: 0;
  }
}

@media (max-width: 767px) {
  .slick-active.slick-center .reveal-code {
    width: 50%;
    right: 100px;
  }
}

@media (max-width: 575px) {
  .slick-active.slick-center .reveal-code {
    width: 60%;
    right: 40px;
  }
}

@media (max-width: 480px) {
  .slick-active.slick-center .reveal-code {
    width: 100%;
    right: 0px;
  }
}

.playeranimation_main {
  video {
    width: 100%;
    height: 100%;
  }
}

// PLAYER IMAGE
.playerimage_main {
  position: relative;
}

.playerimage_main {
  span {
    position: absolute;
    width: 60%;
    background-color: rgba(3, 20, 36, 0.5);
    height: 100%;
    top: 0;
    right: 66px;
    border-radius: 3px;
  }
}

.slick-slide.slick-active.slick-center .playerimage_main span {
  display: none;
}

.player-type {
  display: none;
  text-transform: uppercase;
}
