.info {
  display: flex;
  gap: 20px;
}

.container {
  display: flex;
  justify-content: space-between;

  div:first-child {
    max-width: 70%;
  }
}
