.offensive-name-modal-title {
  font-size: 24px;
  text-align: center;
}

.offensive-name-modal-text {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.offensive-name-modal {
  .ant-modal-content {
    .ant-modal-header {
      border: 0;
    }
  }

  .offensive-name-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}