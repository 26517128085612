.blueButton {
  background-color: #384c6c;
  border: 1px solid #384c6c;
  padding: 9px 30px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.9px;
  transition: 0.5s all;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: transparent;
    color: #384c6c;
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #575757;
  }
  p {
    margin-bottom: 0;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-wrapper {
    margin-right: 1rem;
  }
}
