.profile-trade-proposal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  &__side-container {
    display: flex;
    align-items: center;
    gap: 10px;

    &__side {
      font-weight: bold;
      font-size: 24px;
    }
  
    &__players {
      display: flex;
      flex-direction: column;
    }
  }
}