.sign-up-form-modal {
  max-width: 60vw;
  width: 100%;
  h5 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    font-style: italic;
    color: #cf6766;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    span {
      color: #cf6766;
    }
  }
  a {
    margin-top: 2vh;
    font-size: 15px;
    text-decoration: none;
    color: #000000d9;
    font-weight: 500;
    font-style: normal;
    text-align: center;
  }
  .loginuser,
  .password,
  .reenter_password {
    margin-bottom: 24px;
  }

  .error {
    color: #ff0000d9;
    text-decoration: none;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
  }

  .row {
    p.error {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 25%;
      text-align: center;
    }

    p.error.login-error {
      bottom: 36%;
    }
  }

  @media (max-width: 768px) {
    .row p.error {
      position: static;
      left: auto;
      transform: none;
      bottom: auto;
    }
  }

  .error-placeholder {
    color: white;
    text-decoration: none;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
  }
  .controls-btn {
    padding: 0;
    margin-top: 10px;
    text-align: center;
  }

  .ant-select-selector {
    border-radius: 50px !important;
    height: 45px !important;
    padding: 7px 19px !important;
  }
  .ant-picker {
    height: 45px !important;
    padding: 5px 19px !important;
    border-radius: 50px !important;
    background-color: #f4f7f7 !important;
    border: 0px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f4f7f7 !important;
    border: 0px !important;
  }
  .rmdp-container {
    width: 100%;
  }
  .rmdp-input {
    padding: 5px 19px !important;
    border-radius: 50px !important;
    background-color: #f4f7f7 !important;
    height: 45px !important;
    width: 100%;
    border: 0 !important;
    color: #464c4e;
  }
  .select_date_year .rmdp-ep-shadow {
    display: none;
  }
  .rmdp-header {
    margin-top: 0;
    line-height: inherit;
    height: inherit !important;
  }
  .rmdp-wrapper.rmdp-shadow.ant-select-selector {
    border-radius: 15px !important;
    height: inherit !important;
    padding: 19px !important;
  }

  .step-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    .step-indicator-item {
      width: 24px;
      height: 3px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .active {
      background-color: rgb(209, 54, 57);
    }
    .inactive {
      background-color: rgb(138, 138, 138);
    }
  }

  .formControls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .controls-buttons {
      flex-wrap: nowrap;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;

      .whiteButton {
        padding: 5px 17px;
      }
    }
  }
}

.sign-in-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.sign-in-options-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .sign-in-option {
    display: flex;
    justify-content: center;
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    button {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
