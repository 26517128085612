//Container
.home-container,
.home-container-corner,
.home-container-corner-team {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0 5%;
}

.home-container-corner::after {
    content: "";
    position: absolute;
    background-image: url(../../assets/images/homeScreen/GMD_Header.png);
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.9;
    z-index: -1;
}

.home-container-corner {
    &::before {
        border-left: 0 solid transparent;
        border-right: 1em solid transparent;
        border-bottom: 1em solid #F4F5F7;
        left: 0;
        height: 0;
        top: -1em;
        width: 1em;
        position: absolute;
        content: "";
        display: block;
        font-size: 5rem;
        z-index: 2;
    }
}

.home-container-corner-team {
    &::before {
        border-left: 0 solid transparent;
        border-right: 1em solid transparent;
        border-bottom: 1em solid #CF6766;
        left: 0;
        height: 0;
        top: -1em;
        width: 1em;
        position: absolute;
        content: "";
        display: block;
        font-size: 5rem;
        z-index: 2;
    }
}

//Container Responsive
@media(min-width: 1024px) {

    .home-container,
    .home-container-corner,
    .home-container-corner-team {
        padding-left: 7.3%;
        padding-right: 7.3%;
        padding-bottom: 5vw;
    }
}

// BANNER
.bannerMain {
    width: 100%;

    .banner {
        background: #384C6C;

        .slider1 {
            position: relative;
            width: 100%;
            height: 90vh;

            .videoWrapper {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 80%;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                object-position: 46% center;
                z-index: 0;

                video {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    filter: brightness(0.3);
                }

                .gradientOverlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 25%;
                    height: 100%;
                    background: linear-gradient(to left, transparent, #384C6C);
                }
            }
        }

        .bannerInfoMain {
            height: 90vh;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            position: relative;
            /* Ensure text is positioned relative to .bannerInfoMain */
            z-index: 1;
            /* Ensure text is above the video and overlay */
        }
    }

    .bannerInfo {
        width: 100%;
        align-items: center;
        line-height: normal;

        img {
            margin: 0 auto;
        }

        .app-download-button {
            object-fit: cover;
            cursor: pointer;
            height: 80px;
            width: 180px;
        
            &:first-of-type {
                margin-right: 20px;
            }
        }

        h2 {
            font-size: 70px;
            font-weight: 600;
            font-style: italic;
            letter-spacing: 0.9px;
            line-height: 1;
            font-family: 'Barlow Condensed', sans-serif;
            color: #fff;
            margin-bottom: 0;
            margin-left: 10px;
        }

        p {
            font-size: 30px;
            font-family: 'Barlow', sans-serif;
            color: #fff;
            text-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
            font-weight: 400;
            margin-bottom: 0px;
            margin-left: 10px;
        }

        .whiteButton {
            margin-top: 10px;
            padding: 4px 35px;
            font-weight: 630;
            font-size: 20px;
        }

        .pinkButton {
            box-shadow: 0px 5px 30px rgba(230, 236, 238, 0.5);
            padding: 7px 50px;
            background-color: #000;
            box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.251);
            border: 1px solid #000;
            margin-left: 10px;

            &:hover {
                background-color: #fff !important;
                border: 1px solid #fff !important;
            }
        }
    }

    .marquee {
        white-space: nowrap;
        overflow: hidden;
        margin-left: auto;
        margin-right: 0;
        width: 70%;
        color: white;
        background: linear-gradient(to right, transparent, black);

        span {
            display: inline-block;
            padding-left: 20px;
            cursor: pointer;

            span {
                color: #CF6766;
                padding-left: 0;
                animation: none;
            }
        }
    }
}

// BANNER INFO
@media(max-width: 1600px) {
    .bannerMain {
        .banner {
            .slider1 {
                video {
                    height: 95%;
                    filter: brightness(0.3);
                }
            }
        }

        .bannerInfo {
            h2 {
                font-size: 70px;
            }

            p {
                font-size: 30px;
            }

            .pinkButton {
                font-size: 18px;
            }
        }
    }
}

@media(max-width: 1400px) {
    .bannerMain {
        .bannerInfo {
            h2 {
                font-size: 60px;
            }

            p {
                font-size: 25px;
            }

            .pinkButton {
                font-size: 18px;
            }
        }
    }
}

@media(max-width: 767px) {
    .bannerMain {
        .bannerInfo {
            h2 {
                font-size: 50px;
            }

            p {
                font-size: 20px;
            }

            .pinkButton {
                font-size: 14px;
            }
        }
    }
}

@media(max-width: 575px) {
    .bannerMain {
        .bannerInfo {
            img {
                margin-bottom: 10px;
            }

            h2 {
                font-size: 35px;
            }

            p {
                font-size: 18px;
            }

            .pinkButton {
                font-size: 14px;
            }
        }
    }
}

@media(max-width: 480px) {
    .bannerMain {
        .bannerInfo {
            img {
                margin-bottom: 10px;
                width: 75px;
            }

            h2 {
                font-size: 25px;
            }

            p {
                font-size: 16px;
            }

            .pinkButton {
                font-size: 12px;
            }
        }
    }
}

@media(max-width: 350px) {
    .bannerMain {
        .bannerInfo {
            img {
                margin-bottom: 10px;
                width: 60px;
            }

            h2 {
                font-size: 24px;
            }

            p {
                font-size: 16px;
            }

            .pinkButton {
                font-size: 12px;
            }
        }
    }
}

// SLICK SLIDER
.bannerMain {
    .slick-track {
        display: inherit !important;
    }
}

.bannerMain .slick-arrow.slick-next {
    margin-right: 50px;
}

.bannerMain .slick-arrow.slick-prev {
    margin-left: 50px;
    z-index: 1;
}

.bannerMain .slick-dots {
    bottom: 15px;
}

.bannerMain .slick-dots li.slick-active button:before {
    color: #fff;
    opacity: 1;
}

.bannerMain .slick-dots li button:before {
    color: #fff;
    opacity: 0.50;
}

@media(max-width: 575px) {
    .bannerMain .slick-arrow.slick-next {
        margin-right: 30px;
    }

    .bannerMain .slick-arrow.slick-prev {
        margin-left: 30px;
    }
}

// NEWS
.news-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    span {
        display: inline-flex;
        font-weight: 700;
        font-size: 70px;
        align-items: flex-end;
        text-transform: uppercase;

        .news-title {
            font-size: 70px;
            font-weight: 700;
            color: #CF6766;
        }

    }

    .news-card-wrapper {
        display: flex;
        transform: translate3d(0%, 0px, 0px);
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: space-around;

        .news-card {
            margin-top: 20px;
            margin-right: 5rem;
            min-width: auto;
            flex: 1 0;

            &:hover {
                cursor: pointer;
            }

            .image-container {
                position: relative;
                overflow: hidden;
                margin: 0 auto;
                width: 350px;
                height: 200px;

                .news-image {
                    width: 350px;
                    height: 200px;
                    display: block;
                    transition: filter 0.5s ease;
                    object-fit: cover;
                    object-position: center center;
                }

                .gradient-overlay {
                    position: absolute;
                    top: 0;
                    left: -100%;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 0, 0, 0.35);
                    transition: left 0.5s ease;
                    z-index: 1;
                }

                &:hover .gradient-overlay {
                    left: 0;
                    transition: left 0.5s ease;
                }
            }

            .news-card-title {
                text-transform: uppercase;
                color: #CF6766;
                font-weight: 700;
                margin-top: 10px;
            }

            .news-description-card {
                text-transform: uppercase;
                font-weight: 600;
                margin-top: 10px;
                color: black;
                text-align: center;
                text-decoration: none;
            }
        }
    }
}

@keyframes loadAnimation {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }

    100% {
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}

//NEWS RESPONSIVE
@media(max-width: 1400px) {
    .news-wrapper {
        span {
            font-size: 45px;

            .news-title {
                font-size: 45px;
            }
        }

        .news-card-wrapper {
            .news-card {
                margin-right: 3rem;
                margin-top: 10px;

                .image-container {
                    width: 280px;
                    height: 220px;

                    .news-image {
                        width: 280px;
                        height: 220px;
                    }
                }

                .news-card-title {
                    margin-top: 5px;
                }

                .news-description-card {
                    margin-top: 5px;
                }
            }
        }
    }
}

@media(max-width: 1200px) {
    .news-wrapper {
        .news-title {
            font-size: 40px;
        }

        .news-card-wrapper {
            .news-card {
                margin-right: 3rem;
                margin-top: 10px;
                margin-bottom: 10px;

                .image-container {
                    width: 250px;
                    height: 220px;

                    .news-image {
                        width: 250px;
                        height: 220px;
                    }
                }

                .news-card-title {
                    margin-top: 5px;
                }

                .news-description-card {
                    margin-top: 5px;
                }
            }
        }
    }
}

@media(max-width: 850px) {
    .news-wrapper {
        .news-title {
            font-size: 30px;
        }

        .news-card-wrapper {
            .news-card {
                margin-top: 10px;
                margin-right: 1rem;

                .image-container {
                    width: 180px;
                    height: 150px;

                    .news-image {
                        width: 200px;
                        height: 150px;
                    }
                }
            }
        }
    }
}

@media(max-width: 575px) {
    .news-wrapper {
        height: 100%;
        margin-bottom: 20px;

        span {
            font-size: 30px;

            .news-title {
                font-size: 30px;
            }
        }

        .news-card-wrapper {
            flex-direction: column;
            align-items: center;

            .news-card {
                margin-right: 0;

                .image-container {
                    width: 250px;
                    height: 120px;

                    .news-image {
                        width: 250px;
                        height: 120px;
                    }
                }

                .news-card-title {
                    margin-top: 1px;
                    margin-bottom: 0;
                }

                .news-description-card {
                    margin-top: 1px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

// Mobile Link
@media(min-width: 768px) {
    .mobile_link {
        display: none;
    }
}

@media(max-width: 767px) {
    .mobile_link {
        display: block;
    }
}

@media(max-width: 575px) {
    .news-description p {
        text-align: justify;
        line-height: 27px;
        font-weight: 500;
    }
}

//Video Banner
.video-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(../../assets/images/homeScreen/slide2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 90vh;
}

.video-banner-info {
    height: 90vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100vw;
    color: #fff;
    text-transform: uppercase;

    h2 {
        position: relative;
        font-size: 70px;
        font-weight: 600;
        font-style: italic;
        letter-spacing: 0.9px;
        line-height: 0.9;
        font-family: 'Barlow Condensed', sans-serif;
        color: #fff;
        margin-bottom: 0;
        margin-left: 10px;

        .video-banner-logo {
            position: absolute;
            width: 200px;
            top: -80%;
            left: -8%;
            opacity: 0.5;
        }
    }

    p {
        font-size: 45px;
        font-family: 'Barlow', sans-serif;
        color: #fff;
        text-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
        font-weight: 400;
        margin-bottom: 1px;
        text-align: right;
    }

    .vide-banner-btn {
        margin-top: 10px;
        color: #CF6766;
        display: inline-block;
        float: right;
    }

    .pinkButton {
        background-color: #CF6766;
        border: 1px solid #CF6766;
        padding: 4px 35px;
        border-radius: 3px;
        color: #fff;
        font-weight: 600;
        font-size: 19px;
        letter-spacing: 2px;
        transition: 0.5s all;
        border-radius: 50px;

        &:hover {
            color: #CF6766;
        }
    }

}

//Video Responsive
@media(max-width: 1600px) {
    .video-banner-info {
        h2 {
            font-size: 70px;
        }

        p {
            font-size: 45px;
        }

        .vide-banner-btn {
            font-size: 18px;
        }
    }
}

@media(max-width: 1400px) {
    .video-banner-info {
        width: 90vw;

        h2 {
            font-size: 60px;
        }

        p {
            font-size: 40px;
        }

        .vide-banner-btn {
            font-size: 18px;
        }
    }
}

@media(max-width: 767px) {
    .video-banner-info {
        h2 {
            font-size: 50px;

            .video-banner-logo {
                width: 150px;
                top: -50%;
                left: -1%;
            }
        }

        p {
            font-size: 20px;
            text-align: center;
            margin-right: 20px;
        }

        .vide-banner-btn {
            font-size: 14px;
            float: unset;
        }
    }
}

@media(max-width: 575px) {
    .video-banner-info {
        h2 {
            font-size: 35px;
            margin-right: 40px;

            .video-banner-logo {
                width: 130px;
                top: -50%;
                left: -1%;
            }
        }

        p {
            font-size: 25px;
        }

        .vide-banner-btn {
            font-size: 14px;
        }
    }
}

//Team Banner
.your-team-banner {
    position: relative;
    width: 100%;
    height: 90vh;
    background-color: #CF6766;


    .your-team-section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 90vh;
        align-items: center;

        .your-team-banner-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 30%;

            .your-team-banner-title {
                position: relative;
                text-transform: uppercase;
                font-size: 70px;
                font-weight: 600;
                letter-spacing: 0.9px;
                font-family: 'Barlow Condensed', sans-serif;
                color: #fff;
                text-align: left;
                margin-bottom: 0;
            }

            p {
                font-size: 18px;
                font-family: 'Barlow', sans-serif;
                color: #fff;
                text-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
                font-weight: 400;
                margin-top: 10px;
                text-align: left;
            }

        }

        .your-team-banner-players {
            position: relative;
            display: flex;
            align-items: center;
            width: 50vw;

            .player1,
            .player2,
            .player3 {
                position: absolute;
                width: 468px;
                height: 728px;
            }

            .player3 {
                left: -5%;
            }

            .player1 {
                left: 50%;
            }

            .player2 {
                left: 15%;
            }
        }
    }
}

.your-team-banner::before {
    content: "";
    position: absolute;
    background-image: url(../../assets/images/homeScreen/GMD_Header.png);
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    z-index: 0;
}

//Team Responsive

@media(max-width: 1200px) {
    .your-team-banner {
        .your-team-section {
            .your-team-banner-players {
                width: 65vw;

                .player1,
                .player2,
                .player3 {
                    width: 400px;
                    height: 600px;
                }

                .player1 {
                    left: 55%;
                }

                .player2 {
                    left: 20%;
                }
            }
        }
    }
}

@media(max-width: 1100px) {
    .your-team-banner {
        .your-team-section {
            .your-team-banner-players {
                width: 60vw;

                .player1,
                .player2,
                .player3 {
                    width: 350px;
                    height: 500px;
                }

                .player1 {
                    left: 55%;
                }

                .player2 {
                    left: 20%;
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .your-team-banner {
        .your-team-section {
            .your-team-banner-info {
                .your-team-banner-title {
                    font-size: 50px;
                }

                p {
                    font-size: 16px;
                }
            }

            .your-team-banner-players {
                width: 65vw;

                .player1,
                .player2,
                .player3 {
                    width: 240px;
                    height: 450px;
                }

                .player1 {
                    left: 50%;
                }

                .player2 {
                    left: 15%;
                }
            }
        }
    }
}

@media(max-width: 575px) {
    .your-team-banner {
        .your-team-section {
            flex-direction: column;
            align-items: center;

            .your-team-banner-info {
                width: 90%;

                .your-team-banner-title {
                    font-size: 30px;
                }

                p {
                    font-size: 18px;
                }
            }

            .your-team-banner-players {
                width: 100vw;

                .player1,
                .player2,
                .player3 {
                    margin-bottom: 60px;
                    width: 180px;
                    height: 400px;
                }

                .player1 {
                    left: 50%;
                }

                .player2 {
                    left: 15%;
                }
            }
        }
    }
}


//Dynasty Banner
.your-dynasty-wrapper {
    background-image: linear-gradient(to right, #30425D, rgba(255, 0, 0, 0)),
        url(../../assets/images/homeScreen/slide1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .your-dynasty {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 70vh;
        color: #fff;

        .your-dynasty-img {
            margin-left: 20px;
            height: 320px;
            transform: rotate(10deg);
            filter: drop-shadow(30px 10px 4px rgb(12, 12, 12, 0.5));
        }

        .your-dynasty-info {
            width: 40%;
            margin-left: 40px;

            .your-dynasty-title {
                position: relative;
                text-transform: uppercase;
                font-size: 70px;
                font-weight: 600;
                letter-spacing: 0.9px;
                font-family: 'Barlow Condensed', sans-serif;
                color: #fff;
                text-align: left;
                margin-bottom: 0;
                line-height: 60px;
            }

            p {
                font-size: 25px;
                font-family: 'Barlow', sans-serif;
                color: #fff;
                text-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
                font-weight: 100;
                margin-top: 0px;
                text-align: left;
                line-height: 25px;
            }
        }
    }
}


//Dynasty Responsive
@media(min-width: 1200px) {
    .your-dynasty-wrapper {
        .your-dynasty {
            .your-dynasty-img {
                margin-left: 30px;
            }
        }
    }
}

@media(max-width: 767px) {
    .your-dynasty-wrapper {
        .your-dynasty {
            .your-dynasty-img {
                height: 230px;
            }

            .your-dynasty-info {
                width: 75%;

                .your-dynasty-title {
                    font-size: 55px;
                    line-height: 28px;
                }

                p {
                    font-size: 18px;
                    line-height: 18px;
                }
            }
        }
    }
}

@media(max-width: 575px) {
    .your-dynasty-wrapper {
        .your-dynasty {
            flex-direction: column;
            justify-content: center;

            .your-dynasty-img {
                height: 200px;
            }
        }

        .your-dynasty-info {
            .your-dynasty-title {
                font-size: 60px;
                line-height: 30px;
            }

            p {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}
