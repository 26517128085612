.pinkButton{
    background-color: #D1403C;
    border: 1px solid #D1403C;
    padding: 9px 25px;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    transition: 0.5s all;
    border-radius: 50px;
    &:hover{
        background-color: transparent!important;
        color: #D1403C;
    }
    &:disabled,
    &[disabled] {
        background-color: #dfb1b0;
    }
}