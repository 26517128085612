.subTabs {
  display: flex;
  gap: 20px;

  .tab {
    cursor: pointer;
    text-decoration: underline;
  }

  .selectedTab {
    font-weight: bold;
    cursor: default;
  }

  .divider {
    cursor: default;
  }
}