.notificationsettingsection {
    border-top: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    h5 {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .ant-switch {
        background-color: #BACCD4;
    }

    .ant-switch-checked{
        background-color: #384C6C!important;
    }
}

.notificationsetting {
    .update-button-container {
        margin-top: 15px;
        height: 33px;
        display: flex;
        justify-content: space-between;
        transition: opacity 0.5s ease-in-out;

        .whiteButton {
            height: 33px;
            padding: 5px 17px;
        }
    }
}
