.settingmain{
    padding-bottom: 30px;
    .cardbody{
        padding: 2em;
    }
}

.settingtabmain{
    .nav-item{
        margin-bottom: 5px;
        .nav-link{
            color: #031424!important;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.4px;
        }
    }
}

.settingtabmain .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: transparent;
    color: #29569E!important;
    font-weight: 600;
}

.settingtabmain{
    .nav-pills{
        border-right: 1px solid rgba(86,204,212,0.2);
    }
}