.prestige-level-alert-modal .ant-modal-content {
  text-align: center;

  .ant-modal-body {
    .level {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: center;
      margin-bottom: 10px;
      
      .corner img {
        width: 100px;
      }
    }

    .blueButton:first-of-type {
      margin-right: 5px;
    }
  }
}