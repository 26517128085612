.titleBadge {
  margin-left: 3%;
  background: #d1403c;
  padding: 4px 10px;
  border: 1px solid #d1403c;
  border-radius: 0px;
  color: #fff !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
}

.tableTitleRowEnd {
  h6 {
    font-size: 15px;
    font-weight: 600;
    color: #031424;
    letter-spacing: 0.3px;
  }
}

.RosterBtn {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

//ERROR MODAL
.rostererror {
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-footer {
    .blueButton {
      margin-top: 0px;
      border-radius: 100px !important;
      font-size: 13px;
      padding: 8px 30px;
    }
  }
  .ant-modal-content {
    padding: 1.5rem !important;
  }
}

// Table Heading
.table_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.table_head {
  div {
    display: flex;
    width: 50%;
  }
}

.table_head {
  .title {
    margin-bottom: 0px;
  }
}

.table_head {
  h6 {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-bottom: 0px;
  }
}

.a_color {
  color: #031424;
  text-decoration: none;
}

// Coming Popup
.comingmain {
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    text-align: center;
  }
  h5 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 0px;
  }
}

.standingmain {
  margin-bottom: 25px;
  .ant-table-content {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #384c6c;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #384c6c;
    }
  }
}

@media (max-width: 575px) {
  .table_head {
    display: block;
    .title {
      margin-bottom: 5px;
    }
  }
  .table_head div {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    .titleBadge {
      margin-left: 0px;
      display: inline-block;
    }
  }
}

.ratingWithChange {
  .ratingWithChange__change--up {
    color: #00b559;
  }

  .ratingWithChange__change--down {
    color: #d1403c;
  }
}

.modal-footer-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;

  button {
    width: 100%;
    border-radius: 10px;
    padding: 2px;
  }
}
