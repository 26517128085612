.legendmain{
    max-width: 650px;
}

.legendmain h5{
    font-size: 24px;
    margin-bottom: 5px;
    font-style: italic;
    margin-bottom: 4px;
    color: #d1403c;
}

.legendmain h6{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #384C6C;
    margin-bottom: 25px;
}

@media(max-width: 575px){
    .legendmain h5{
        font-size: 20px;
    }
    .legendmain h6{
        font-size: 20px;
    }
}

// City Image
.cityimage{
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
        box-shadow: 4px 6px 5px 0px rgba(0,0,0,0.2);
    }
}

@media(max-width: 767px){
    .cityimage{
        img{
            width: 30%;
            margin: 0 auto;
            display: block;
            margin-bottom: 20px;
        }
    }
}

@media(max-width: 575px){
    .cityimage{
        img{
            width: 50%;
            margin: 0 auto;
            display: block;
            margin-bottom: 20px;
        }
    }
}

// Legend Content
.legendcontent{
    p{
        font-size: 16px;
        font-weight: 500;
        text-align: justify;
        line-height: 22px;
        margin-bottom: 15px;
    }
}

.legendcontent{
    ul{
        padding-left: 20px;
        margin-bottom: 15px;
    }
    li{
        font-size: 14px;
        font-weight: 500;
        text-align: justify;
        line-height: 22px;
        margin-bottom: 5px;
    }
}

// Form
.legendcontent{
    .form-control{
        border-radius: 0px!important;
        border: 1px solid #384C6C!important;
    }
}

.submit-btn{
    background-color: #384C6C;
    width: 100%;
    border-radius: 0px;
    border: 1px solid #384C6C;
    padding: 8px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;    
}