.whiteButton {
  background-color: #e6ecee;
  border: 1px solid #e6ecee;
  color: #384c6c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.6px;
  border-radius: 50px;
  transition: 0.5s all;
  
  &:hover {
    background-color: transparent;
    border: 1px solid #384c6c;
    color: #384c6c;
  }
}
