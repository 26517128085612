.underage-component {
  text-align: center;

  img {
    margin-bottom: 12px;
    width: 90px;
  }

  p {
    color: #384C6C;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 25px;
    text-transform: initial;
  }
}