.h2h-result-card {
    min-height: 65px;
    line-height: 65px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 12px;
    background-color: #FFFFFF;
    box-shadow: 0.4rem 0.4rem 1rem rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .row {
        width: 100%;

        .col-2, .col-3 {
            margin: 0 auto;
        }
    }

    .elimination-details {
        width: 100%;
        justify-content: center;
        max-height: 0px;
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
    }

    .elimination-details-opened {
        width: 100%;
        justify-content: center;
        max-height: 2000px;
    }
}

.box-score-button {
    border: none;
    background-color: #3B4D6B;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    height: 40px;
    border-radius: 6px;
    width: 100%;
}

.game-detail {
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.game-name {
    flex-basis: 25%;
    margin-bottom: 0;
    cursor: pointer;
}

.game-type-e {
    text-decoration: underline;
    cursor: pointer;
    color: #3B4D6B;
}

.game-date {
    flex-basis: 12%;
}

.game-record {
    flex-basis: 18%;
}

.game-winnings {
    flex-basis: 20%;
}

.game-detail-divider {
    padding: 0 1px 0 0 !important;
    height: 50px !important; 
}

.game-detail-divider-franchiseoverview {
    margin-top: 8px !important;
}

.result-card-game-detail-link {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.result-card-game-detail-arrow-rotated {
    transform: rotate(90deg);
}

.result-card-game-detail-arrow {
    transition: transform 0.5s ease-in-out;
}

