.games_list {
  background-color: transparent;
  height: 15rem;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: height 1s ease;
}

.lobby__game_row {
  display: flex;
  align-items: center;
  padding: 4px;
  margin-bottom: 7px;
  border-radius: 5px;
  border: 1px solid #384c6c40;
  box-shadow: 0px 5px 7px -3px rgba(0,0,0,0.1);
  background-color: white;

  .franchise-helmet {
    display: unset;

  }
  .helmet {
    width: 47px;
    height: 35px;
  }
  .no-padding {
    padding: 0;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .score {
    @media(max-width: 575px){
      margin-top: 10px;
      margin-bottom: 10px; 
    }
  }

  &_watch_button_cell{
    .pinkButton {
      border-radius: 5px;
      width: 100%;

      @media(max-width: 991px){
        padding-left: 3px;
        padding-right: 3px;
        }
    
    }
  }
}