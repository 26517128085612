.howworksmain {
  position: relative;
  padding: 40px;
}

@media (max-width: 767px) {
  .howworksmain {
    padding: 20px;
  }
}

// Heading
.howworksmain h5 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #384c6c;
}

@media (max-width: 767px) {
  .howworksmain h5 {
    font-size: 22px;
  }
}

// howworksslider
.howworksslider {
  position: relative;
  margin: 40px 0px 80px 0px;
}

.howworksslider {
  .computer {
    position: absolute;
    display: block;
    width: 100%;
    top: -17px;
    img {
      display: block;
      margin: 0px auto;
    }
  }
}

.howworksslider .slick-slide img {
  margin: 0 auto;
  width: 219px;
}

.howworksslider .slick-prev:before {
  content: "";
  background-image: url(../../assets/images/howItWorks/prev-arrow.png);
  width: 30px;
  height: 30px;
  position: absolute;
  left: -50px;
}

.howworksslider .slick-next:before {
  content: "";
  background-image: url(../../assets/images/howItWorks/next-arrow.png);
  width: 30px;
  height: 30px;
  position: absolute;
  right: -50px;
}

.howworksmain .slick-slider {
  width: 250px;
  margin: 0 auto;
}

.howworksmain {
  .slick-track {
    display: inherit !important;
  }
}

.computer_video{
  margin: 40px 0px 0px 0px;
  .computer{
    position: relative!important;
    display: flex;
    align-items: center;
    justify-content: center;
    video{
      width: 100%;
      height: auto;
    }
  }
  iframe{
    border-radius: 5px;
  }
}

@media (max-width: 480px) {
  .howworksslider .slick-prev:before {
    left: 0px;
    display: none;
  }
  .howworksslider .slick-next:before {
    right: 0px;
    display: none;
  }
  // .howworksmain .slick-slider{
  //     width: 200px;
  // }
  .howworksslider .slick-dots {
    bottom: -90px;
  }
  .howworksslider .slick-dots li {
    margin: 0px;
  }
  .howworkscontent {
    padding-top: 50px;
  }
}

// howworkscontent
.howworkscontent h6 {
  color: #384c6c;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}

.howworkscontent ul {
  padding-left: 0px;
  // .bold {
  //   font-weight: bold;
  // }
}

.howworkscontent ul li {
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: justify;
  margin-bottom: 5px;
  line-height: 23px;
  // &:nth-child(1){
  //     font-weight: 700;
  // }
}

@media (max-width: 767px) {
  .howworkscontent h6 {
    font-size: 18px;
  }
  .howworkscontent ul li {
    font-size: 14px;
    margin-bottom: 6px;
  }
}

@media (max-width: 480px) {
  .howworkscontent ul li {
    text-align: inherit;
  }
}

// Close Icon
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .close-icon {
    width: 25px;
  }
}

// Acquire Franchise
.howworkscontent {
  a {
    text-decoration: none;
  }
}

.howworkscontent {
  .blueButton {
    display: block;
    margin: 0 auto;
    padding: 8px 25px;
  }
}
