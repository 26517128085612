.nofranchisesmain{
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 20px;
    img{
        margin-right: 20px;
        width: 310px;
    }
    .franchisescontent{
        h5{
            color: #D1403C;
            font-weight: 700;
            letter-spacing: 0.3px;
            font-size: 25px;
            margin-bottom: 5px;
        }
        h6{
            color: rgba(56,76,108,1);
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.5px;
            margin-bottom: 20px;
        }
        p{
            color: rgba(56,76,108,1);
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
            margin-bottom: 0px;
        }
    }
}

@media(max-width: 767px){
    .nofranchisesmain{
        width: 100%;
    }
}

.border{
    color: #BACCD4;
    opacity: 0.2;
}

.nofranchisesbutton{
    text-align: center;
    padding-top: 20px;
    width: 80%;
    margin: 0 auto;
    h6{
        font-size: 22px;
        font-weight: 700;
        color: #384C6C;
        letter-spacing: 0.3px;
    }
    p{
        color: #384C6C;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 23px;
        a{
            color: #384C6C;
        }
    }
    .blueButton{
        border-radius: 6px;
        font-size: 13px;
        padding: 10px 25px;
        letter-spacing: 0.5px;
        margin-right: 10px;
        font-weight: 500;
        width: 250px;
    }
    .whiteButton{
        border-radius: 6px;
        font-size: 13px;
        padding: 10px 25px;
        letter-spacing: 0.5px;
        font-weight: 600;
        width: 250px;
        background-color: #90aebb;
        color: #fff;
    }
}


@media(max-width: 991px){
    .nofranchisesmain{
        display: block!important;
        text-align: center;
        img{
            width: 130px;
            margin-bottom: 25px;
        }
    }
    .nofranchisesbutton{
        .blueButton{
            margin-bottom: 15px;
        }
    }
}

@media(max-width: 767px){
    .nofranchisesbutton{
        width: 100%;
    }
}

@media(max-width: 575px){
    .nofranchisesmain{
        text-align: left;
    }
    .nofranchisesmain img{
        margin-bottom: 10px;
    }
    .nofranchisesmain .franchisescontent p{
        text-align: justify;
    }
    .nofranchisesbutton{
        text-align: left;
    }
    .nofranchisesbutton .pinkButton{
        margin-top: 0px;
    }
    .nofranchisesbutton p{
        text-align: justify;
    }
    .nofranchisesbutton .blueButton{
        width: 100%;
        margin-bottom: 5px;
    }
    .nofranchisesbutton .whiteButton{
        width: 100%;
        margin-top: 0px;
    }
}

@media(max-width: 767px){
    .nofranchisesmain{
        img{
            width: 100px;
        }
    }
}

@media(max-width: 480px){
    .nofranchisesmain{
        img{
            width: 80px;
        }
    }
}

@media(max-width: 480px){
    .nofranchisesbutton{
        a{
            display: block;
            width: 100%;
            .blueButton{
                width: 100%;
            }
        }
        .pinkButton{
            display: block;
            margin: 10px auto 10px auto;
            width: 100%;
        }
        .blackButton{
            display: block;
            margin: 0 auto;
            width: 100%;
        }
    }
}

@media(max-width: 767px){
    .nofranchise{
        .cardbody{
            padding: 3rem;
        }
    }
}

@media(max-width: 767px){
    .nofranchise{
        .cardbody{
            padding: 1rem 1rem 1rem 1rem;
        }
    }
}

@media(max-width: 576px){
    .nofranchisesmain .franchisescontent h5{
        font-size: 20px;
    }
    .nofranchisesmain .franchisescontent h6{
        margin-bottom: 10px;
    }
    .nofranchisesbutton h6{
        font-size: 20px;
    }
    .nofranchisesmain{
        padding-bottom: 0px;
    }
    .nofranchise{
        .cardbody{
            padding: 1rem 1rem 1rem 1rem;
        }
    }
}

@media(max-width: 480px){
    .nofranchisesmain .franchisescontent h5{
        font-size: 18px;
    }
    .nofranchisesbutton h6{
        font-size: 18px;
    }
    .nofranchisesbutton p{
        font-size: 12px;
    }
}

.create-team-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}