.mint-to-blockchain {
  display: flex;
  align-items: center;
  gap: 10px;


  input[type="radio"]:checked {
    accent-color: #384c6c;
  }
  

  label:first-of-type {
    margin-right: 10px;
  }
}