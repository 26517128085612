.standingTabbarMain{
    .nav-pills{
        display: inline-block;
    }
    .nav-item{
        display: inline-block;
        margin-right: 15px;
        a{
            color: rgba(3,20,36,0.5)!important;
            font-size: 14px;
            font-weight: 700;
            padding-bottom: 0.65rem;
            letter-spacing: 0.5px;
        }
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        background-color: transparent;
        color: #031424!important;
        border-bottom: 1px solid #D1403C;
        border-radius: 0px;
        border-width: 2px;
    }
    .standingTabbar{
        margin-bottom: 15px;
        .cardbody{
            padding: 0.40rem 0.40rem 0rem 0.40rem;
        }
    }
}

//STANDING
.standingmain{
    .title{
        color: #384C6C;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
    }
    thead{
        tr{
            th{
                background-color: #384C6C!important;
                color: #E6ECEE;
                font-weight: 600;
                letter-spacing: 0.5px;
                font-size: 12px;
            }
        }
    }
    tbody{
        tr{
            td{
                border-bottom: 0px;
                background-color: #F3F5F7;
                margin-bottom: 5px;
                font-size: 12px;
                font-weight: 600;
                color: #384C6C;
            }
        }
    }
    .ant-table-wrapper{
        margin-bottom: 10PX;
    }
}