.players-stock-report {

  h5 {
    margin-top: 10px;
    text-wrap: nowrap;
    font-size: 16px;
    // font-weight: 700;
    text-transform: uppercase;
  }
}

.players-stock-report-item {
  font-size: 16px;
  text-transform: uppercase;

  .leader-list-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-order {
      flex-basis: 30px;
    }
    &-name {
      flex-grow: 1;
      margin-right: 10px;
      font-size: 14px;
      & a {
        text-decoration: none;
        color: rgba(3, 20, 36, 0.7);
      }
    }
    &-role {
      flex-basis: 150px;
      margin-right: 10px;
      flex-shrink: 0;
    }
    &-points {
      flex-basis: 30px;
      flex-shrink: 0;
      align-content: center;
    }
  }

}

.leaderlist{
  position: relative;
  margin-bottom: 15px;
  .leaderlistsection{
      border: 1px solid #f0f0f0;
      padding: 0.50rem;
      border-radius: 3px;
      width: 100%;
      display: inline-block;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      a{
          font-size: 16px;
          font-weight: 600;
          color: rgba(3,20,36,0.7);
          letter-spacing: 0.5px;
          margin-bottom: 0px;
          text-decoration: none;
      }
      p{
          font-size: 14px;
          font-weight: 600;
          color: rgba(3,20,36,0.7);
          letter-spacing: 0.5px;
          margin-bottom: 0px;
      }
  }
  img{
      max-height: 80px;
      margin-right: 15px;
  }
  .leaderlistdigit{
      background-color: #D75A57;
      padding: 5px;
      border-radius: 3px;
      position: absolute;
      width: 60px;
      height: 60px;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      div{
          text-align: center;
          h5{
              font-size: 22px;
              font-weight: 700;
              color: #F3F5F7;
              margin: 0px;
          }

      }
  }
}