.group-item {
  width: 100%;
  display: block;
  .image-wrapper {
    height: 120px;
    border-radius: 0.75rem;
    overflow: hidden;
    width: 120px;
  }
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.group-name {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-top: 1rem;
}
