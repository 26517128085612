.connectwalleterror{
    width: 450px!important;
}

.connectwallet{
    img{
        width: 80px;
    }
    p{
        margin-bottom: 0px;
        font-size: 15px;
        letter-spacing: 0.3px;
    }
}

//SELECT FRANCHISE MODAL
.selectFranchise{
    .ant-modal-close-x{
        display: none;
    }
    .ant-modal-footer{
        display: none;
    }
}

//SELECT FRANCHISE CONTENT
.selectfranchisemain{
    h5{
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.2px;
        color: #384C6C;
        text-transform: uppercase;
    }
    .franchisesection{
        img{
            border-radius: 3px;
            width: 100%;
            margin-bottom: 10px;
        }
        .blueButton{
            width: 100%;
            border-radius: 3px;
            font-size: 13px;
        }
    }
}

@media(max-width: 576px){
    .selectfranchisemain{
        .franchisesection{
            margin-bottom: 30px;
        }
    }
}