.customizefranchisemain {
  .purchasefranchisecontent {
    margin-bottom: 25px;
    h6 {
      color: #384c6c;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 5px;
      letter-spacing: 0.5px;
    }
  }

  .submit-button-container {
    display: flex;
    justify-content: flex-end;
  }
}

// FRANCHISE NAME
.createFranchise-form-label {
  margin-bottom: 20px;
  h6 {
    margin-bottom: 8px !important;
  }
  input {
    background-color: #f4f7f7;
    border: 0px;
    padding: 5px 15px;
    &::placeholder {
      color: #819ba2;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}

// PRIMARY COLOR
.colorview {
  margin-right: 10px;
  width: 52px;
  height: 52px;
  border: 1px solid #f1f1f1;
  padding: 5px;
}

.colorhead {
  color: #031424;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.iconpicker {
  border: 1px solid #e3e3e3;
  padding: 6px;
  margin-right: 15px;
}

.color-choose {
  display: flex;
  align-items: center;
}

// choose color modal
.choose-color-main {
  width: 400px !important;
}

.choose-color {
  h5 {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #384c6c;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(186, 204, 212, 0.18);
    padding-bottom: 10px;
  }
  .colorfill {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    margin: 5px;
    border: 1px solid #f1f1f1;
  }
}

// SELECT LOGO MAIN
.selectlogomain {
  .verificationContent {
    h5 {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      color: #384c6c;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(186, 204, 212, 0.18);
      padding-bottom: 10px;
    }
    img {
      object-fit: cover;
      display: block;
      margin: 20px auto;
    }
  }
  .logoList {
    img {
      width: 80px;
      margin: 15px;
    }
  }
}

// PREVIEW MODAL
.PreviewModal {
  h5 {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #384c6c;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(186, 204, 212, 0.18);
    padding-bottom: 10px;
  }
}

.choosehead {
  margin-bottom: 20px;
}

// .logo-select {
//   margin-top: 30px !important;
// }

.logo-select .colorview {
  width: inherit;
  height: inherit;
}

.purchasefranchisemain .purchaseimage img {
  height: auto;
}

.buttons {
  position: absolute;
  left: 90px;
  top: 573px;
  button,
  input {
    margin-top: 10px;
    margin-left: 10px;
  }
}
.Myicon {
  height: 24px;
}

.colorpicker {
  padding-left: 30px;
  position: relative;
  display: inline;
  transition: 0.5s all;
  ul {
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pickercommonbox {
    width: 30px !important;
    height: 30px !important;
    display: inline-block;
    border-radius: 2px;
    margin: 0px 5px;
    cursor: pointer;
  }
  .black {
    background-color: #000000;
  }
  .dark {
    background-color: #939496;
  }
  .lightdark {
    background-color: #d9d9d9;
  }
  .white {
    background-color: #fff;
    border: 1px solid #f1f1f1;
  }
  .brown {
    background-color: #583517;
  }
  .lightbrown {
    background-color: #bb9b6a;
  }
  .blue1 {
    background-color: #0d244e;
  }
}

.colorpicker .iconpicker {
  border: 1px solid #cacaca;
  padding: 10px;
  color: #384c6c;
}

.colorpickercontent {
  background-color: #fff;
  padding: 12px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.06%);
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  position: absolute;
  z-index: 99;
  width: 270px;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s all;
  left: 75px;
  top: -13px;
  h5 {
    text-align: center;
  }
}

.colorpicker:hover > .colorpickercontent {
  visibility: visible !important;
  opacity: 1 !important;
}

.logofranchise {
  .ant-upload {
    .ant-btn {
      min-height: 100px !important;
      max-width: 100px !important;
      border-radius: 150px;
      display: block;
      align-items: center;
      border: 1px solid #f1f1f1;
      background-color: transparent;
      white-space: inherit;
      span {
        &:nth-child(2) {
          margin-left: 0px;
        }
      }
    }
  }
}

.custombtn {
  margin-top: 20px !important;
  .blueButton {
    border-radius: 3px;
    width: 100%;
    font-weight: 500;
  }
  .blackButton {
    border-radius: 3px;
    width: 100%;
    padding: 5px 17px;
    font-weight: 500;
  }
  .pinkButton {
    border-radius: 3px;
    padding: 9px 25px;
    width: 100%;
    font-weight: 500;
  }
}
.createFranchise-form {
  .form-control {
    border: 1px solid #ced4da;
    border-top-color: rgb(206, 212, 218);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(206, 212, 218);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(206, 212, 218);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(206, 212, 218);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}

.captureAvatarDivP {
  top: -1000000px;
  left: -1000000px;
  position: absolute;

  &.preview { 
    position: unset;
    $baseWidth: 271px;
    $baseHeight: 345px;
    height: $baseHeight;
    width: $baseWidth;

    #captureAvatarDiv {
      position: relative;
      width: 100%;
      height: 100%;
      .captureAvatarDiv-items {
        width: $baseWidth;
        height: $baseHeight;
        position: absolute;
        top: 0;
      }
      img {
        width: $baseWidth;
        height: $baseHeight;
      }
      .teamName {
        position: absolute;
        bottom: 50px;
        font-size: 12px;
      }
      .teamRatingWhite,
      .teamRatingBlack {
        bottom: 1px;
        font-size: 24px;
        margin: 0.1em 0;
      }
    }
  }
}

#captureAvatarDiv,
#captureAvatarDivSave {
  position: relative;
  width: 1500px;
  height: 1889px;
  .captureAvatarDiv-items {
    width: 1500px;
    height: 1889px;
    position: absolute;
    top: 0;
  }
  .teamName {
    position: absolute;
    bottom: 250px;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    margin: -0.5rem 0;
    letter-spacing: 1.5px;
    font-family: "Barlow", sans-serif !important;
    font-weight: 700;
  }
  .teamRatingWhite {
    position: absolute;
    color: white;
    bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 120px;
    margin: 1.5rem 0;
    font-family: 'Barlow Condensed', sans-serif!important;
    font-weight: 700;
  }
  .teamRatingBlack {
    position: absolute;
    color: black;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 120px;
    margin: 1.5rem 0;
    font-family: 'Barlow Condensed', sans-serif!important;
    font-weight: 700;
  }
}
.logo-box {
  padding: 0px;
  margin-right: 10px;
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 100px;
  img {
    width: 40px;
    max-height: 40px;
    height: 40px;
  }
  div {
    width: 40px;
    height: 40px;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }
  
  &.square {
    border-radius: 5px;
  }
}

.ant-modal-content {
  width: fit-content;
}

// MODAL PREVIEW
// .previewcontent {
//   display: flex;
//   margin-top: 25px;
// }

.PreviewModal {
  .ant-modal-content {
    width: 100%;
    padding: 20px !important;
  }
  h3 {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #384c6c;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(186, 204, 212, 0.18);
    padding-bottom: 10px;
    font-size: 22px;
  }
}

.previewcontent {
  #imagePreviewDiv {
    canvas,
    img {
      width: 100%;
      border-radius: 3px;
      object-fit: contain;
    }
  }
}

.previewdetail {
  h5 {
    text-align: left;
    font-size: 20px;
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-bottom: 5px;
  }
  h6.subtitle {
    color: #baccd4;
    margin-bottom: 20px;
  }
  .logo-select {
    margin-top: 20px;
  }
}

// APPROVAL MODAL
.approvalmain {
  .startdraftmain {
    P {
      font-size: 16px;
    }
  }
}

.selectlogolist {
  height: 300px;

  overflow-y: scroll;

  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: #fff;
    border: 1 solid #f1f1f1;
    border-radius: 5px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #384c6c;
    border: 1 solid #f1f1f1;
    border-radius: 5px;
  }
}

@media(max-width: 575px){
  .selectlogomain{
    top: 30px!important;
  }
  .choosehead{
    margin-bottom: 30px;
  }
}

@media(max-width: 767px){
  .PreviewModal{
    .previewimage{
      margin-bottom: 15px;
    }
  }
}
