//WHITE BACKGROUND
.signinBackground {
  .signUpMain{
    max-width: 650px!important;
  }
}

//LOGIN OPTION TITLE
.login_option{
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: #384C6C;
}
.walletExtension{
  border-left: 1px solid #E6ECEE;
  height: 100%;
  padding-left: 20px;
  .login_option{
    margin-bottom: 15px;
  }
}

//LOST PASSWORD
.signinForm {
  .terms {
    text-align: end;
    text-decoration: underline;
    cursor: pointer;
  }
}

//WALLET EXTENSION
.walletExtension{
  .signupSocial{
    text-align: left;
  }
  .trustwallet{
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.9px;
    img{
      width: 20px;
      height: 20px;
    }
  }
}

@media(max-width: 991px){
  .walletExtension{
    display: none;
  }
  .login_option{
    display: none;
  }
}

//SIGNIN CHECKBOX
.signinForm{
  .checkbox{
    margin-bottom: 0px;
  }
}

//SIGNIN BLUEBUTTON
.signinForm{
  .blueButton{
    margin-bottom: 20px;
    padding: 10px 35px;
  }
}

//FORGOT PASSWORD MODAL
.forgotpassword {
  .ant-modal-footer {
    border-top: 0px;
    text-align: center;
  }
  .whiteButton {
    margin-right: 8px;
    padding: 8px 30px !important;
  }
  .blueButton {
    padding: 8px 30px !important;
  }
  .ant-modal-close{
    display: none;
  }
  .ant-modal-content{
    padding: 25px;
  }
}

.forgotpassword{
  .ant-modal-footer{
    margin-top: 20px!important;
  }
}

.lostpassword{
  margin-top: 5px!important;
}

button.auth-button {
  border-radius: 10px;
}
