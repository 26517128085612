//PROFILE TAB
.settinghead {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: #384c6c;
    margin: 20px 0;
}

.addnewfunds {
    color: #384C6C !important;
}

.addnewfundsinput {
    margin: 10px -5px !important;
    max-width: 100%;
}

.withdrawalinput {
    margin: 2px 0 10px -5px !important;
}

.pricecard {
    width: 30% !important;
    height: 50px !important;
    background-color: #e6e6e6 !important;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 100% !important;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.paymentbuttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .blueButton {
        width: 100%;
    }
}

.paymentcard {
    width: 45% !important;
    height: 45px !important;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: thin solid !important;

    @media (max-width: 600px) {
        width: 100% !important;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

//PROFILE LIST
.profilehead {
    a {
        text-decoration: none;
        color: #031424;
        font-weight: 500;

        img {
            width: 15px;
            height: 15px;
            margin-right: 3px;
        }
    }
}

.profilemain {
    thead {
        tr {
            display: none;
        }
    }

    tbody {
        tr {
            margin-bottom: 8px;

            td {
                font-weight: 500;
                letter-spacing: 0.2px;
                padding-top: 0px !important;
                padding-right: 0px !important;
                padding-left: 0px !important;
                padding-bottom: 10px !important;
                border-bottom: 0px;
                text-align: left !important;
                font-size: 16px;
                font-weight: 500;

                &:nth-child(1) {
                    color: #384C6C;
                    font-weight: 500;
                }
            }
        }
    }

    tr {
        &:hover {
            background-color: transparent;
        }
    }
}

//UPLOAD AVATAR
.profilemain {
    .profileImage {
        margin-top: 5px;
        margin-bottom: 10px;

        h5 {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.4px;
            color: #384C6C;
        }
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.profilemain {
    .uploadavatar {
        position: relative;
        display: inline-block;

        input {
            background-color: transparent;
            opacity: 1;
            overflow: hidden;
            cursor: pointer;
            z-index: 9;
            padding: 0;
            border-radius: 0px;
            width: 130px;
            height: 130px;
            opacity: 0;
            position: absolute;
        }

        .customimage {
            width: 130px;
            height: 130px;
            border-radius: 100px;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22%);
            position: relative;
            border: 5px solid #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 110px;
                height: 110px;
                border-radius: 100px;
                position: absolute;
            }
        }
        img.uploadavatar__edit-icon  {
            position: absolute;
            width: 15px;
            height: 15px;
            right: 5px;
            bottom: 5px;
            opacity: 0.5;
            border-radius: 0;
            &:hover {
                opacity: 1;
            }
        }
    }
}

//
.settingwalletconnect {
    .blueButton {
        width: 100%;
        border-radius: 2px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        padding: 7px 17px;
    }
}

// SETTING WALLET BALANCE
.notificationmain {
    .settinghead {
        font-weight: 800;
        font-size: 18px;
    }
}

.notificationmain {
    .walletconnectedmain {
        margin-top: 10px;

        h5 {
            color: #384C6C !important;
        }

        .blueButton {
            border-radius: 10px !important;
        }
    }
}

.notificationmain {
    img {
        margin: 0px 0px;
    }
}

.withdrawalamount {
    color: #384C6C !important;
}

.notificationmain {
    .settingwalletbalance {
        margin-top: 30px;

        h5 {
            color: #384C6C !important;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.2px;
            margin-bottom: 10px;
        }

        .blueButton {
            border-radius: 10px !important;
        }

        p {
            margin: 0;
        }

        .balancetable {
            thead {
                tr {
                    display: none;
                }
            }

            tbody {
                tr {
                    margin-bottom: 8px;

                    td {
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: 0.5px;
                        padding-top: 0px !important;
                        padding-right: 0px !important;
                        padding-left: 0px !important;
                        padding-bottom: 8px !important;
                        border-bottom: 0px;
                        text-align: left !important;
                        font-size: 13px;

                        &:nth-child(1) {
                            color: #384C6C;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.notificationmain {
    .walletconnectedmain ul li {
        font-size: 13px;
    }

    .walletconnectedmain .blueButton {
        margin-top: 5px;
    }
}

// WALLET CONNECTED
.settingwalletconnect {
    h5 {
        font-size: 14px;
        margin-bottom: 13px !important;
    }
}

//WITHDRAW WALLET
.notificationmain {
    .withdrawwallet {
        margin-top: 30px;

        p {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin-bottom: 0px;
        }

        .form-label {
            display: none;
        }

        .form-control {
            margin-top: 10px;
            border-radius: 5px !important;
        }

        .blueButton {
            width: 100%;
            border-radius: 10px !important;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin-top: 10px;
            padding: 7px 17px;
        }
    }

    h5 {
        color: #484848;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
    }
}

//SELECT TRANSFER
.selectTransfer {
    background-color: #f4f7f7;
    border-radius: 5px;
    margin-top: 5px;

    .ant-dropdown-trigger {
        line-height: 44px;
        padding-left: 20px;
        color: #031424;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
    }

    .anticon-down {
        float: right;
        line-height: 44px;
        padding-right: 20px;
    }
}


.minimumtransfer {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-top: 10px;
}

//CONNECT NEW WALLET MODAL
.connectnewwallet {
    max-width: 450px !important;

    h5 {
        font-size: 18px;
        font-weight: 600;
        color: #384C6C;
        margin-bottom: 5px;
        margin-top: 15px;
    }

    .ant-modal-footer {
        display: none;
    }

    .startdraftmain {
        p {
            font-size: 15px;
        }
    }

    .trustwallet {
        background-color: #3375BB;
        padding: 8px 10px;
        border: 0px;
        color: #fff;
        margin: 0px 5px;
        width: 160px;
        border-radius: 3px;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.5px;

        img {
            width: 20px;
            height: 20px;
            margin-bottom: 0px;
            margin-right: 5px;
        }
    }

    .metamask {
        background-color: #ff5900;
    }
}

//MODAL BUTTON
.modalbutton {
    .blueButton {
        font-size: 12px;
        font-weight: 500;
        border-radius: 2px;
    }
}

//WITHDRAW AMOUNT
.startdraftmain {
    .withdrawinput {
        h5 {
            font-size: 16px;
            margin-bottom: 10px;
        }

        .form-label {
            display: none;
        }

        .form-control {
            border-radius: 3px !important;
            border: 1px solid #BACCD4 !important;
            height: 40px !important;
        }

        div {
            width: 350px;
            margin: 0 auto;
        }

        .blueButton {
            margin-top: 13px;
            padding: 8px 35px;
            font-size: 12px;
            letter-spacing: 0.9px;
            font-weight: 500;
        }

        p {
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: 0.3px;
            line-height: 20px;
        }
    }
}

//WITHDRAW CURRENCY
.selectedcurrency {
    h5 {
        font-size: 14px;
        color: #29569E;
        letter-spacing: 0.5px;
        margin-bottom: 12px;
        margin-top: 5px;
    }
}

.withdrawcurrency {
    .blueButton {
        font-size: 12px;
        margin-top: 10px;
        padding: 8px 30px;
    }
}

//TRANSACTION TABLE
.transactiontable {
    thead {
        tr {
            th {
                background-color: #384C6C !important;
                padding: 5px 10px;
                color: #fff !important;
                font-weight: 500;
                letter-spacing: 0.5px;
                font-size: 12px;
                user-select: none;

                &:hover {
                    color: #fff !important;
                    background-color: #384C6C !important;
                }

                &:focus {
                    color: #fff !important;
                    background-color: #384C6C !important;
                }

                &:active {
                    color: #fff !important;
                    background-color: #384C6C !important;
                }

                .ant-table-column-has-sorters:hover {
                    color: #fff !important;
                    background-color: #384C6C !important
                }

                .ant-table-column-has-sorters:focus {
                    color: #fff !important;
                    background-color: #384C6C !important
                }

                .ant-table-column-has-sorters:active {
                    color: #fff !important;
                    background-color: #384C6C !important
                }

                .anticon-caret-up {
                    &.active {
                        color: rgb(209, 64, 60);
                    }
                }

                .anticon-caret-down {
                    &.active {
                        color: rgb(209, 64, 60);
                    }
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 5px 10px;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
        }
    }
}

.settingtransaction {
    .settinghead {
        margin-bottom: 15px;
    }

    h6 {
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #384c6c;
    }
}

// TRANSACTION SLIP
.transactionslip {
    .ant-modal-content {
        width: inherit;
    }
}

.transactionslip_main {
    img {
        margin-bottom: 15px;
    }

    .ant-table-thead {
        display: none;
    }

    .ant-table-tbody tr td {
        border-bottom: 0px;

        &:nth-child(1) {
            color: #031424;
            font-weight: 500;
            letter-spacing: 0.5px;
            opacity: 0.5;
            padding-left: 0px !important;
        }

        &:nth-child(2) {
            opacity: 1;
            text-align: right !important;
            color: #031424;
            font-weight: 500;
        }
    }

    .account_info h5 {
        font-size: 16px;
    }
}

.transactionslip_main {
    .blueButton {
        width: 100%;
        border-radius: 5px;
        margin-top: 10px;
    }
}

// EDIT SETTING
.editsetting_form .form-control {
    padding-left: 0px !important;
    display: flex;
}

.editsetting_form .form-control label {
    margin-right: 5px;
}

.editsetting_form .form-control input {
    border: 1px solid #BACCD4;
    height: 35px;
    padding: 5px 10px;
    border-radius: 5px;
    width: 100%;
}

.editsetting_form select {
    border: 1px solid #BACCD4;
    height: 35px;
    padding: 5px 10px;
    border-radius: 5px;
    width: 100%;
}

.editsetting_form button {
    padding: 10px 25px;
}

.editsetting_form .error {
    height: 21px;
}