.marketplace-teams {

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;

    &-search {
      margin-bottom: 20px;
    }

    &__team {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f3f5f7;
      padding: 5px;
      border-radius: 10px;
      transition: transform 0.3s, box-shadow 0.3s ease-in-out;
      cursor: pointer;
  
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
  
      &__image {
        border-radius: 5px 5px 0 0;
        width: 200px;
        height: 250px;
      }
    }
  }

  &-pagination {
    margin-top: 20px;
  }  
}