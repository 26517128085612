//LEAGUE LEADER
.leaderlist{
    position: relative;
    margin-bottom: 15px;
    .leaderlistsection{
        border: 1px solid #f0f0f0;
        padding: 0.50rem;
        border-radius: 3px;
        width: 100%;
        display: inline-block;
        display: flex;
        align-items: center;
        h5{
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.5px;
            color: #031424;
            margin-bottom: 5px;
        }
        a{
            font-size: 14px;
            font-weight: 600;
            color: rgba(3,20,36,0.7);
            letter-spacing: 0.5px;
            margin-bottom: 0px;
            text-decoration: none;
        }
        p{
            font-size: 14px;
            font-weight: 600;
            color: rgba(3,20,36,0.7);
            letter-spacing: 0.5px;
            margin-bottom: 0px;
        }
    }
    img{
        max-height: 80px;
        margin-right: 15px;
    }
    .leaderlistdigit{
        background-color: #D75A57;
        padding: 5px;
        border-radius: 3px;
        position: absolute;
        width: 60px;
        height: 60px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        div{
            text-align: center;
            h5{
                font-size: 22px;
                font-weight: 700;
                color: #F3F5F7;
                margin-bottom: 0px;
            }
            h6{
                font-size: 14px;
                color: #fff;
                font-weight: 600;
                letter-spacing: 0.5px;
            }
        }
    }
}

//RESULTS
.resulttitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h5{
        margin-bottom: 0px;
    }
    a{
        color: #D1403C;
        text-decoration: underline;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
    }
}

.resultTable{
    .ant-table-thead{
        display: none;
    }
    .blueButton{
        border-radius: 3px;
        padding: 5px 20px;
        font-size: 10px;
    }
    .resultFranchise{
        span{
            &:nth-child(1){
                color: #989898;
                font-weight: 600;
                letter-spacing: 0.5px;
                margin-right: 8px;
            }
            &:nth-child(2){
                color: #031424;
                font-weight: 600;
                letter-spacing: 0.3px;
            }
            &:nth-child(3){
                color: #989898;
                font-weight: 600;
                letter-spacing: 0.5px;
                margin-left: 8px;
            }
        }
    }
    .ant-table-cell{
        &:nth-child(2){
            text-align: center;
        }
        &:nth-child(3){
            text-align: end;
        }
    }
    td{
        color: #031424;
        font-weight: 600;
        letter-spacing: 0.3px;
        font-size: 12px;
        padding: 6px!important;
    }
}

//STANDINGS & POWER RANKINGS
.rankStandTable{
    th{
        background-color: #384C6C!important;
        color: #E6ECEE!important;
        font-weight: 600!important;
        letter-spacing: 0.5px;
        font-size: 12px;
    }
    td{
        border-bottom: 0px!important;
        color: rgba(3,20,36,0.7);
        font-weight: 600;
        letter-spacing: 0.5px;
        letter-spacing: 0.5px;
        font-size: 12px;
        text-transform: uppercase;
    }
}

.rankingTable{
    td{
        &:nth-child(2){
            text-align: center;
        }
        &:nth-child(3){
            text-align: center;
        }
        &:nth-child(4){
            text-align: center;
        }
    }
}

.standings{
    font-weight: 600;
    color: #D1403C;
    letter-spacing: 0.5px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: 0.5s all;
    &:hover{
        color: #D1403C;
        text-decoration: underline;
    }
}

//ELIMINATION RESULTS
.resultsmain{
    h6{
        margin-bottom: 3px;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-size: 14px;
    }
    p{
        font-size: 14px;
        font-weight: 500;
        color: rgba(3,20,36,0.7);
        letter-spacing: 0.5px;
    }
}

span.elimination-overview-game-detail {
    display: inline-block;
    font-size: 16px;
    text-align: center;
    overflow: visible;
    width: 100%;
    white-space: pre-line;
    line-height: 16px;
}

button.elimination-detail-button {
    box-sizing: border-box;
    font-size: 16x;
    height: 50px;
    border: 1px solid #3B4D6B;
    transition: all 0.5s;
    max-width: 100px;

    &:hover {
        background-color: #fff;
        color: #3B4D6B;
    }
}

.game-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
