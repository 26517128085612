.mvp-crowns-pricecard {
  .row {
    margin: 5px;
    border-radius: 10px;
    border: 5px solid #d1403c;
    cursor: pointer;
  }

  img {
    width: 100%;
  }

  &__crowns {
    color: #d1403c;
    font-size: 4em;
  }

  &__usd {
    padding: 10px;
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    font-size: 2em;
  }

  .col-4 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
