.group-invite {
  &__modal-wrapper {
    .ant-modal-content {
      width: 100%;
    }
    @media (max-width: 576px) {
      width: 100% !important;
    }

    @media (min-width: 576px) {
      width: 420px !important;
    }

    @media (min-width: 992px) {
      width: 660px !important;
    }
  }
}
