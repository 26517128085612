.trade-proposal-modal-title {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.trade-proposal-player {
  display: flex;
  max-width: fit-content;
  gap: 10px;

  div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    span {
      text-transform: uppercase;
      word-wrap: break-word;
      
      &:first-of-type {
        font-weight: bold;
      }
    }
  }
}
