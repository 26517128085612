.group-header {
  text-transform: uppercase;
  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    color: #d1403c;
    @media screen and (max-width: 768px) {
      margin-top: 0.5rem;
    }
  }
  &__details {
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  &__actions-row {
    button {
      width: 100%;
      border-radius: 0.25rem;
    }
    .whiteButton {
      @media screen and (max-width: 991px) {
        margin-top: 0;
      }
    }
  }
}
