.franchise-helmet {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;

  .helmet {
    position: relative;
    width: 105px;
    height: 80px;

    &__item {
      position: absolute;
      left: -24%;
      top: -44%;

      &--left {
        transform: scaleX(-1);
      }
      &--right {
        transform: scaleX(1);
      }
    }

    &__logo {
      top: 6%;
      width: 40%;
      position: absolute;
      &--left {
        left: 20%;
        transform: scaleX(-1);
      }
      &--right {
        left: 42%;
      }
    }
  }

  img {
    width: 152%;
    height: auto;
  }

  .franchise-name-container {
    position: relative;

    .franchise-name-text {
      text-align: center;
      margin: 0;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }
}