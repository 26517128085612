.slider_main{
    position: relative;
    margin-top: 30px;
    h5{
        font-size: 13px;
        font-weight: 600;
        color: #384C6C;
        letter-spacing: 0.5px;
        
    }
}

.slider_main{
    span{
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: rgba(56,76,108,0.5);
        &:nth-child(4){
            position: absolute;
            right: 0;
        }
    }
}

.slider_main{
    .ant-slider{
        margin: 10px 0px 0px 0px;
    }
    .ant-slider-rail{
        height: 10px;
        background-color: rgba(56,76,108,0.2);
        border-radius: 10px;
    }
    .ant-slider-track{
        height: 10px;
        border-radius: 10px;
        background-color: #384C6C;
    }
    .ant-slider:hover .ant-slider-track{
        background-color: #384C6C;
    }
    .ant-slider-handle{
        width: 25px;
        height: 25px;
        margin-top: -9px;
        background-color: #384C6C;
        border: solid 4px #fff;
        box-shadow: 1px 0px 5px 0px rgba(0,0,0,0.2);
    }
    .ant-slider-handle.ant-tooltip-open{
        border-color: #fff;
    }
}

.ant-tooltip-inner{
    background-color: #D1403C!important;
    font-weight: 500;
    cursor: pointer;
    font-size: 12PX;
    padding: 6px 6px!important;
}

.ant-tooltip-arrow-content{
    background-color: #D1403C!important;
}

.ant-tooltip{
    z-index: 99999!important;
}

.dropdown_gameplan{
    margin-top: 30px;
    h5{
        font-size: 14px;
        color: #384C6C;
        font-weight: 600;
        letter-spacing: 0.4px;
    }
    .specialteams{
        margin-top: 10px;
    }
}

.specialteam_main{
    margin-top: 70px;
}

.gameplan_main_section{
    width: 90%;
}

@media(max-width: 991px){
    .gameplan_main_section{
        width: 100%;
    }
    .defensive_plan_main{
        margin-top: 40px;
    }
}