.loadingContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  align-items: center;
  background-color: rgba(3,20,36,0.6);
  top: 0;
  .loadingImg{
    width: 100px;
  }
}

