.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 1px;
  margin: 20px 0;

  .animated {
    animation: buttonScale 2s infinite alternate;

    @keyframes buttonScale {
      0% {
      transform: scale(1);
      }
      50% {
      transform: scale(1.15);
      }
      100% {
      transform: scale(1);
      }
    }
  }
}