.lobby_list_item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    border: 2px solid #000;
    z-index: 1;
    object-fit: contain;
    cursor: pointer;
  }

  img.square-avatar {
    border-radius: 10px;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 100%;
    border-radius: 20px;
    margin-left: -40px;
    padding-left: 45px;
    padding-right: 10px;
    height: 60px;

    & > span {
      color: #D75A57;
      font-weight: bold;
    }
  }
}

div.lobby_list {
  height: 200px;
  min-height: unset;
  overflow: auto;
}