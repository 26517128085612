.season-schedule-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &-item {
    min-height: 65px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0.4rem 0.4rem 1rem rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    &-name {
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        font-size: 36px;
      }
    }

    &-more {
      cursor: pointer;
      transition: transform 0.5s ease-in-out;

      &__expanded {
        transform: rotate(90deg);
      }
    }
  }
}

.season-schedule-list-item-more-data {
  margin-top: -40px;
  box-sizing: border-box;
  padding-top: 40px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;

  transition: max-height 0.5s ease-in-out;
  max-height: 0;

  &__expanded {
    max-height: 2000px; /* Adjust the value as needed */
  }
}

.day {
  margin-top: 20px;

  &-number, &-start-at {
    font-size: 20px;
  }

  &-games {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 20px;

    &-item {
      display: flex;
      border: 1px solid #E6ECEE;
      border-radius: 10px;
      padding: 5px;
      
      &-center {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &-score {
          font-size: 20px;
        }

        button {
          padding: 4px 2px 4px 2px;
          border-radius: 4px;
        }
      }

      &-team {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        span {
          width: 90px;
        }
      }

      &-team-right {
        span {
          text-align: right;
        }
      }
    }
  }
}
