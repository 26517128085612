.nav-menu-container {
  display: flex;
  justify-content: space-between;

  .nav-menu-share-button {
    color: black;
    border: 1px solid black;
    background-color: transparent;
    margin-bottom: 6px;
  }
}