.container {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
  z-index: 1000;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .get-app-text {
    font-size: 16px;
  }

  .link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }

  .close {
    background: none;
    border: none;
    font-size: 16px;
    color: #007bff;
    cursor: pointer;
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}
