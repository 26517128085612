.property-selector-image-container {
  max-width: 300px;
  max-height: 256px;
  overflow-y: scroll;
  overflow-x: hidden;

  h5.title {
    color: #384c6c;
    text-align: center;
  }

  .property-selector-image {
    width: 64px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    margin: 4px;

    &:hover {
      transform: scale(1.15);
    }
  }

  .property-selector-color {
    display: inline-block;
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    margin: 5px;
    border: 1px solid #f1f1f1;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.15);
    }
  }

  .corner-container {
    display: inline-block;
    overflow: hidden;
    height: 64px;
    width: 64px;
    margin: 4px;
    cursor: pointer;

    .property-selector-corner-image {
      width: 64px;
      transform: scale(2);
      transform-origin: right top;
    }

    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.15);
    }
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    border: 1 solid #f1f1f1;
    border-radius: 5px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #384c6c;
    border: 1 solid #f1f1f1;
    border-radius: 5px;
  }
}
