.user-trophies {
  .section__body {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    img {
      width: 150px;
      min-width: 150px;
      height: fit-content;
    }
  }
}