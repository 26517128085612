.section {
  .section__body {
    .group-passed-games__table {
      thead {
        tr {
          th {
            background-color: #384c6c !important;
            color: #e6ecee;
            font-weight: 600;
            letter-spacing: 0.5px;
            font-size: 12px;
          }
        }
      }
      tr {
        td {
          border-bottom: 0px;
          background-color: #f3f5f7;
          margin-bottom: 5px;
          font-size: 13px;
          font-weight: 600;
          color: #031424;
          border-bottom: 5px solid #fff;
        }
      }
    }
  }
}
