// Career Stat Header
.carrerstat_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.carrerstat_header h5{
    margin-bottom: 0px;
}

.carrerstat_header .pinkButton{
    border-radius: 0px;
    padding: 5px 15px;
}

// carrerstat_table
.carrerstat_table{
    table{
        border: 1px solid #D9DBDE;
    }
}

.carrerstat_table{
    .ant-table-thead tr th{
        background-color: #384C6C!important;
        color: #e6ecee;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-size: 12px;
    }
}

.carrerstat_table{
    .ant-table-tbody tr td{
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.6px;
    }
}

.recent_game{
    .pinkButton{
        opacity: 0;
    }
}

//
.awards_main{
    margin-top: 20px;
}

.awards_main .carrerstat_header{
    margin-bottom: 10px;
}

//
.playerrating_main{
    margin-top: 20px;
}