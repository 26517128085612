//BACKGROUND IMAGE
.signUpBackground {
  background-image: url(../../assets/images/signUp/backgroundSignup.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  padding-top: 100px;
  padding-bottom: 100px;
}

//BACKGROUND IMAGE RESPONSIVE
@media (min-width: 1400px) {
  .signUpBackground {
    height: 100%;
  }

  .signUpBackground {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media (max-width: 991px) {
  .signUpBackground {
    padding-bottom: 90px;
    height: auto;
  }
}

@media (max-width: 576px) {
  .signUpBackground {
    padding-top: 100px;
    padding-bottom: 30px;
  }
}

//WHITE BACKGROUND
.signUpMain {
  max-width: 600px;
  padding: 50px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}

//WHITE BACKGROUND RESPONSIVE
@media (min-width: 1400px) {
  .signUpMain {
    max-width: 700px;
  }
}

@media (max-width: 767px) {
  .signUpMain {
    border-radius: 0px;
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .signUpMain {
    max-width: 90%;
    padding: 20px 30px !important;
  }
}

//SIGNUP HEAD
.signUpMain {
  .signupHead {
    text-align: center;
    margin-bottom: 15px;

    h4 {
      font-size: 22px;
      font-weight: 800;
      letter-spacing: 0.5px;
      color: #031424;
      margin-bottom: 3px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: rgba(3, 20, 36, 0.8);
      margin-bottom: 10px;

      &:nth-child(2) {
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
      }

      a {
        text-decoration: underline;
        color: #031424;
      }
    }
  }
}

//SOCIAL MEDIA 
.signupSocial {
  text-align: center;

  .trustwallet {
    width: 200px;
    background: #3375BB;
    border: 0px;
    padding: 8px 30px;
    border-radius: 3px;
    color: #e6ecee;
    font-weight: 600;
    letter-spacing: 0.9px;
    margin-bottom: 10px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    img {
      margin-right: 10px;
    }
  }

  .metamask {
    background: #ff6700;
    margin-right: 0px;
  }
}

//SOCIAL MEDIA RESPONSIVE
@media (max-width: 403px) {
  .signupSocial {
    .discord {
      margin-bottom: 10px;
      width: 175px;
      margin-right: 0px;
    }
  }
}

// @media (max-width: 480px){
//   .signupSocial {
//     .trustwallet {
//       padding: 8px 15px;
//       width: auto;
//     }
//   }
// }

@media (max-width: 380px) {
  .signupSocial {
    .trustwallet {
      margin: 1px 1px 10px 1px;
    }
  }
}

//DIVIDER START
.signUpMain {
  .ant-divider-horizontal.ant-divider-with-text {
    justify-content: center;
    margin: 10px 0px;
  }

  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    width: 25%;
  }

  .ant-divider-inner-text {
    color: #384c6c;
    font-weight: 600;
    font-size: 14px;
  }
}

//SIGNUP DESCRIPTION
.signupDescription {
  text-align: center;
  color: rgba(3, 20, 36, 0.8);
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 13px;
}

// TERMS & PRIVACY
.signupForm {
  .terms {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    margin-top: 15px;

    a {
      color: #031424;
    }
  }
}

@media(max-width: 991px) {
  .signupForm {
    .whiteButton {
      margin-top: 0px;
    }
  }
}

//SUBMIT BUTTON
.signupForm {
  .signupsubmit {
    .blueButton {
      padding: 12px 25px;
    }

    .whiteButton {
      padding: 12px 25px;
    }
  }
}

//EMAIL VERIFICATION MODAL
.verification {
  .ant-modal-body {
    border-radius: 10px;
  }

  .ant-modal-footer {
    border-top: 0px;

    button {
      display: block;
      margin: 0px auto !important;
      text-align: center;
    }
  }

  .didNot {
    border: 0px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    color: #384c6c;
    font-size: 14px;
    padding-bottom: 35px;

    span {
      text-decoration: underline;
    }
  }

  .resend {
    background-color: #384c6c;
    width: 100%;
    border: 1px solid #384c6c;
    color: #fff;
    padding: 9px 25px;
    height: auto;
    border-radius: 50px;
    font-weight: 500;
    letter-spacing: 0.9px;
    margin-top: 15px !important;

    &:hover {
      border: 1px solid #384c6c;
      background-color: transparent;
      color: #384c6c;
    }
  }
}

.verificationContent {
  img {
    margin-bottom: 15px;
  }

  h5 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
  }

  p {
    color: rgba(3, 20, 36, 0.8);
    font-weight: 500;
    text-align: justify;
    line-height: 25px;
  }
}

.password_input_container {
  margin-bottom: 24px;
}

.password-label-status {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}