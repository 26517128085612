.nft-info-container {
  padding: 20px;

  button {
    max-width: fit-content;
  }
}

.nft-info-image {
  img {
    width: 100%;
    border-radius: 10px;
  }
}

.nft-info-details {
  display: flex;
  flex-direction: column;
  flex: 2;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.nft-info-details h2 {
  margin-bottom: 10px;
}

.nft-info-details p {
  margin: 5px 0;
}

.nft-attributes {
  padding: 10px;
  flex-wrap: wrap;
  gap: 10px;
  .nft-attribute-item {
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9; // Light grey background
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow
    padding: 10px; // Add some padding
    border-radius: 8px; // Rounded corners
    margin-bottom: 10px; // Space between rows
  }
}

.nft-events-table {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  .ant-table {
    border-radius: 10px;
  }
  .ant-table-content {
    border-radius: 0 0 10px 10px;
    padding-bottom: 20px;
  }
}
