.trade-history {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__trade {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .anticon-swap svg {
      width: 32px;
      height: 32px;
    }

    &__side {
      display: flex;
      width: 40%;
      flex-direction: column;
      background-color: #f3f5f7;
      border-radius: 10px;
      align-items: center;
      padding: 10px;

      &__players {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;

        &__player {
          display: flex;
          flex-direction: column;
          gap: 5px;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            width: 100px;
          }
        }
      }
    }
  }
}