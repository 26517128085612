.importfranchisecontent{
    h5{
        margin-bottom: 7px!important;
    }
    .franchisenft{
        font-size: 14px!important;
        font-weight: 600!important;
        margin-bottom: 5px!important;
    }
}

// CONNECT ERROR MODAL 
.connecterror{
    width: 450px;
    .ant-modal-content{
        padding: 1.5rem!important;
    }
    .blueButton{
        border-radius: 50px;
        margin-top: 10px;
    }
}

//IMPORT FRANCHISE OPENSEA BUTTON
.importopensea{
    margin-top: 15px;
    .blackButton{
        width: 100%;
        border-radius: 3px;
        padding: 6px 0px;
        font-size: 13px;
        font-weight: 600;
    }
}

//RESPONSIVE
@media(min-width: 1199px){
    .purchasefranchisemain{
        .purchaseimage{
            img{
                width: 100%!important;
            }
        }
    }
}

@media(max-width: 1199px){ 
    .importfranchisecontent{
        margin-top: 20px;
    }
}

@media(max-width: 991px){
    .purchasefranchisemain{
        .selectcity{
            .blueButton{
                margin-bottom: 0px;
                &:nth-child(3){
                    margin-bottom: 10px;
                }
            }
        }
    }
    .selectfranchisemain{
        .franchisesection{
            img{
                width: 100%;
            }
        }
    }  
}