.contact-main{
    margin-top: 120px;
    margin-bottom: 60px;
}

.contact-main .cardbody{
    padding: 30px 50px 50px 50px;
}

@media(max-width: 991px){
    .contact-main .cardbody{
        padding: 30px;
    }
}

@media(max-width: 480px){
    .contact-main .cardbody{
        padding: 20px;
    }
}

// Contact Head
.contact_head{
    text-align: center;
    margin-bottom: 30px;
}

.contact_head h5{
    font-size: 25px;
    font-weight: 700;
    color: #384C6C;
    margin-bottom: 5px;
}

.contact_head p{
    color: #384C6C;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
}

@media(max-width: 767px){
    .contact_head h5{
        font-size: 20px;
    }
}

@media(max-width: 480px){
    .contact_head p{
        font-size: 14px;
    }
}

// Contact Detail
.contact_detail{
    border-right: 1px solid #BACCD4;
    height: 100%;
}

.contact_info{
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
}

.contact_info img{
    margin-right: 15px;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.contact_subinfo h5{
    font-size: 18px;
    font-weight: 600;
    color: #384C6C;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.contact_subinfo a{
    margin-bottom: 5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #031424;
}

.contact_subinfo p{
    margin-bottom: 5px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #031424;
}

@media(max-width: 767px){
    .contact_detail{
        border-right: 0px;
    }
}

// Contact Form
.contact_form{
    .form-control{
        border-radius: 5px!important;
    }
    .form-select{
        border-radius: 5px!important;
    }
}

textarea.form-control{
    height: auto!important;
}

.submitbtn{
    display: inline-block;
    background-color: #031424;
    padding: 7px 25px;
    border: 1px solid #031424;
    color: #fff;
    border-radius: 5px;
    transition: 0.5s all linear;
    &:hover{
        background-color: transparent;
        color: #031424;
    }
}