$baseHeight: 345px;
$baseWidth: 271px;

.trophy-preview{
  position: relative;
  width: $baseWidth;
  height: $baseHeight;
  margin-bottom: 40px;
  
  img {
    width: $baseWidth;
    height: $baseHeight;
    position: absolute;
  }
  .trophy-and-group-name-container {
    margin-top: 260px;
    position: absolute;
    width: $baseWidth;
    height: $baseHeight;
    span {
      display: block;
      width: 100%;
      text-align: center;
      color: #fff;
    }
    span.trophy-name {
      font-family: "CopperplateGothic", sans-serif!important;
      font-size: 19px;
      height: 29px;
    }
    span.group-name {
      margin: 0;
      font-family: "Oswald", sans-serif!important;
      line-height: 12px;
      font-size: 12px;
    }
  }
  div.prestige-points {
    position: absolute;
    font-family: "Oswald", sans-serif!important;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    bottom: 5px;
    right: 15px;
  }
 
}