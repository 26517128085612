.group-avatar__image-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;
  margin: 0 auto;
  & > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
}
