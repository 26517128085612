//CONNECTED WALLET
.walletconnectedmain{
    h5{
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0px;
        letter-spacing: 0.5px;
        color: #384C6C;
    }
    img{
        margin: 20px 0px;
    }
}

@media(max-width: 480px){
    .walletconnectedmain h5{
        font-size: 16px;
    }
}

//WALLET ID & BALANCE
.walletconnectedmain{
    ul{
        list-style: none;
        padding-left: 0px;
        margin-bottom: 4px;
        li{
            display: inline-flex;
            letter-spacing: 0.5px;
            font-size: 14px;
            text-transform: uppercase;
            &:nth-child(1){
                color: #384C6C;
                font-weight: 600;
            }
            &:nth-child(2){
                float: right;
                color: rgba(56,76,108,0.5);
                font-weight: 600;
            }
        }
    }
}

//FRANCHISE CITY NAME
.franchisecity{
    margin: 20px 0px;
    h5{
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.5px;
        margin-bottom: 3px;
        color: #031424;
    }
    h6{
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
    a{
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: underline;
    }
}

@media(max-width: 480px){
    .franchisecity h5{
        font-size: 16px;
        margin-bottom: 8px;
    }
}

.franchisecity button{
    background-color: transparent;
    border: 0px;
    text-decoration: underline;
}

// PAYMENT HORIZENTALLINE
.pricelinehori{
    margin: 0 auto!important;
}

// PAYMENT AMOUNT
.paymentprice{
    .ant-table-thead{
        tr{
            th{
                background-color: transparent!important;
                border: 0px;
                font-weight: 700;
                padding-top: 0px!important;
                padding-left: 0px!important;
                padding-right: 0px!important;
                letter-spacing: 0.5px;
            }
        }
    }
    .ant-table-tbody{
        tr{
            td{
                border-bottom: 0px!important;
                padding-top: 0px!important;
                padding-left: 0px!important;
                padding-right: 0px!important;
                font-weight: 500;
                color: #384C6C;
                letter-spacing: 0.5px;
            }
        }
    }
    .ant-table-cell{
        &::before{
            border: 0px!important;
            background-color: transparent!important;
        }
    }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    background-color: transparent!important;    
}

// SELECT PAYMENT OPTION
.paymentoption{
    h5{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        color: #031424;
    }
    .buttonsection{
        position: relative;
    }
    // button{
    //     background-color: #E6ECEE;
    //     border: 1px solid #E6ECEE;
    //     width: 100%;
    //     display: inline-block;
    //     position: relative;
    // }
    .redtick{
        position: absolute;
        top: -5px;
        right: -5px;
    }
}

.paymentoption li {
    display: inline-block;
}

@media(max-width: 991px){
    .paymentoption li {
        display: block!important;
        text-align: center;
    }
}
  
.paymentoption  input[type="radio"][id^="cb"],
.paymentoption  input[type="radio"][id^="crowns"] {
    display: none;
  }
  
.paymentoption  label {
    background-color: #E6ECEE;
    display: block;
    position: relative;
    margin: 5px 0px;
    padding: 3px;
    cursor: pointer;
    border-radius: 3px;
}
  
  .paymentoption  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
}

@media(min-width: 992px){
    .paymentoption .partnercode label:before{
        top: 0px;
        right: -30px;
    }
}
  
.paymentoption  label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}
  
.paymentoption  :checked + label {
    border-color: #ddd;
}
  
.paymentoption  :checked + label img {
    transform: scale(0.9);
    z-index: -1;
}

// PARTNER CODE
.partnercode{
    color: #384C6C;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 12px;
    margin-bottom: 0px;
    &:hover{
        color: #384C6C;
        text-decoration: underline;
    }
}

.partnercode{
    label{
        background-color: transparent;
    }
}

@media(max-width: 991px){
    .partnercode{
        label{
            background-color: #E6ECEE;
            padding: 10px 0px;
        }
    }
}

// PARTNER CODE INPUT
.paymentoption{
    .form-label{
        display: none;
    }
    .form-control{
        border-radius: 3px!important;
        margin-top: 5px;
    }
}

// PROCEED BUTTON
.proceed{
    .blueButton{
        width: 100%;
        border-radius: 3px;
        padding: 6px 0px;
        font-size: 13px;
        font-weight: 600;
    }
}

// RESPONSIVE
@media(max-width: 991px){
    .walletconnectedmain{
        text-align: left;
        padding-top: 15px;
        border-top: 1px solid #f1f1f1;
        p{
            text-align: left!important;
        }
        .selectmain{
            margin-top: 15px;
        }
    }
    .paymentoption{
        .buttonsection{
            margin-bottom: 15px;
        }
    } 
}

@media(max-width: 375px){
    .selectwallet{
        .metamask{
            margin-bottom: 15px;
        }
        .trustwallet{
            margin-left: 0px!important;
        }
    } 
}

@media(max-width: 576px){
    .purchasefranchisecontent{
        margin-top: 15px;
    }
}

.blackButton {
    border-radius: 3px;
    padding: 5px 17px;
    font-weight: 500;
}

.form-check {
    .form-check-input:checked {
        background-color: #384C6C;
        border-color: #384C6C;
    }

    .form-check-label {
        background-color: #fff;
        width: fit-content;
    }
}

.promo-balance-buttons {
    display: flex;
    justify-content: space-between;
    button {
        border-radius: 3px;
    }
}

.wallet-balances-info {
    margin-bottom: 20px;
    
    &__balance {
        display: flex;
        justify-content: space-between;
    }
}