.prospect_playerdetail{
    .prospect_title{
       text-align: center;
       color: #D75A57;
       font-weight: 700;
       font-size: 18px;
       letter-spacing: 0.5px;
       margin-bottom: 15px;
    }
    .prospect_subtitle{
        display: flex;
        justify-content: center;
        border-top: 1px solid #E6ECEE;
        border-bottom: 1px solid #E6ECEE;
        margin-bottom: 20px;
        .prospect_subsection{
            margin-right: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
            span{
                font-size: 14px;
                &:nth-child(1){
                    color: #031424;
                    font-weight: 600;
                    margin-right: 6px;
                }
                &:nth-child(2){
                    color: rgba(3,20,36,0.5);
                    font-weight: 600;
                }
            }
        }
    }
    .player_detailmain{
        display: flex;
        border-right: 1px solid rgba(56,76,108,0.2);
        img{
            width: 120px;
            height: 140px;
            object-fit: cover;
            margin-right: 15px;
        }
        .player_detail{
            h5{
                font-weight: 800;
                font-size: 18px;
                letter-spacing: 0.5px;
            }
            p{
                font-size: 14px;
                margin-bottom: 5px;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
            .blueButton{
                border-radius: 0px;
                padding: 3px 25px;
                margin-top: 5px;
            }
        }
    }
    .player_info{
        .player_infosection{
            text-align: center;
        }
        ul{
            padding-left: 0px;
            margin-bottom: 0px;
            margin-top: 10px;
            margin-bottom: 5px;
            li{
                list-style: none;
                text-align: center;
                display: inline-block;
                margin-bottom: 15px;
                h6{
                    font-size: 14px;
                    font-weight: 700;
                    color: #031424;
                    letter-spacing: 0.5px;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                }
                p{
                    color: rgba(3,20,36,0.5);
                    font-weight: 600;
                    margin-bottom: 0px;
                    font-size: 14px;
                }
            }
        }
    }
    .player_grade{
        width: 110px;
        height: 110px;
        border: 2px solid #031424;
        background-color: #fff;
        border-radius: 100px;
        margin: 15px auto 15px auto;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        div{
            margin: 25px 0px;
        }
        h6{
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 0px;
        }
        p{
            margin-bottom: 0px;
            color: #031424;
            font-weight: 600;
            letter-spacing: 0.5px;
        }
    }
    .prospect_playersection{
        background-color: #E6ECEE;
        padding: 10px;
    }
}

.player_infosection1{
    li{
        margin-top: 15px;
        margin-bottom: 0px!important;
    }
}

.border{
    border: 1px solid rgba(56,76,108,0.2);
}

//DRAFT LOG
.draftlogmain{
    .ant-table-thead{
        display: none;
    }
    .logpoint{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0px;
    }
    .draftplayer{
        h6{
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.3px;
            margin-bottom: 5px;
        }
        span{
            letter-spacing: 0.2px;
            color: #031424;
            font-size: 12px;
            &:nth-child(1){

            }
            &:nth-child(2){
                margin-right: 10px;
            }
        }
    }
    .draftposititon{
        margin-bottom: 0px;
        background-color: #384C6C;
        text-align: center;
        color: #E6ECEE;
        padding: 12px 3px;
        border-radius: 50px;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-size: 15px;
    }
    .ant-table-wrapper{
        height: 315px;
        overflow-y: scroll;
        &::-webkit-scrollbar{
            width: 1px;
        }
        &::-webkit-scrollbar-thumb {
            background: #384C6C; 
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
    }
}

// MODAL
.draftcommon{
    .ant-modal-header{
        display: none;
    }
    .ant-modal-footer{
        border-top: 0px;
        text-align: center;
    }
    .blueButton{
        padding: 10px 30px;
    }
}

.startdraftmain{
    text-align: center;
    img{
        margin-bottom: 12px;
        width: 90px;
    }
    p{
        color: #384C6C;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 25px;
        text-transform: initial;
    }
}

@media(max-width: 1200px){
    .prospect_playerdetail{
        .player_detailmain{
            margin-bottom: 15px;
        }
        .player_grade{
            width: 100px;
            height: 100px;
        }
    } 
}