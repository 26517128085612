.report-problem-modal .ant-modal-content {
  .ant-modal-body {
    margin-bottom: 20px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;

    .ant-btn {
      border-radius: 5px;
      color: white;
      font-weight: bold;
      font-size: 16px;
      padding: 10px 20px;
      height: unset;
      width: 160px;
    }

    .ant-btn-default {
      background-color: #c00000;
      border: 1px solid #c00000;
      &:hover {
        background-color: transparent;
        color: #c00000;
      }
    }

    .ant-btn-primary {
      background-color: #384c6c;
      border: 1px solid #384c6c;
      &:hover {
        background-color: transparent;
        color: #384c6c;
      }
    }
  }
}