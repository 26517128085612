// franchisethankyou
.franchisethankyou {
    img {
        width: unset;
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    h5 {
        font-size: 24px;
        font-weight: 500;
        color: #031424;
        margin-bottom: 5px;
    }
    p {
        text-align: justify;
        margin-bottom: 10px;
        font-weight: 400;
        color: #2b2b2b;
        line-height: 22px;
    }
    a {
        color: #d75a57;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        
        .blueButton {
            width: 25em;
            padding: 5px 10px;
        }

        @media (max-width: 600px) {
            flex-direction: column;

            .blueButton {
                width: 100%;
            }
        }
    }
}

// Email Verification & Not Verification
.email_verification{
    margin-top: 25px;
    h6{
        font-size: 16px;
        font-weight: 500;
        color: #031424;
        margin-bottom: 5px;
        font-family: "Bahnschrift";
    }
}

.email_verification{
    .blueButton{
        margin-top: 20px;
        padding: 10px 50px;
    }
}   

.PreviewModal{
    width: 700px!important;
    top: 20px!important;
}

.teamName{
    font-family: "Barlow", sans-serif !important;
}