.create-group {
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase;
    width: auto;
    min-width: auto;
  }
  &__form {
    & .blueButton {
      width: 100%;
      border-radius: 0.5rem;
    }
  }
  &__form__settings {
    margin-bottom: 4rem;
    .title {
      margin: 2rem 0;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    &-switch {
      text-transform: uppercase;
      font-size: 1rem !important;
      font-weight: 400;
      margin-bottom: 1rem !important;
      label {
        font-size: 1rem !important;
        font-weight: 400 !important;
        &::after {
          content: "" !important;
        }
      }
      .ant-switch-checked {
        background-color: #384c6c;
      }
    }
  }
}
.avatar-upload {
  position: relative;
  background-color: #f3f5f7;
  margin-top: 0.5rem;
  padding: 1rem;
  text-transform: uppercase;
  &__text-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-bottom: 0;
      font-size: 1.25rem;
      font-weight: 500;
      text-align: center;
    }
    button {
      text-transform: uppercase;
      border-radius: 0.25rem;
    }
    @media screen and (max-width: 768px) {
      padding: 2rem 0 1rem 0;
    }
  }
  &::before {
    content: " ";
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
    transition: 0.3s background ease-in-out;
  }
}
.avatar-upload--ondrag {
  &::after {
    background-color: rgba(0, 0, 0, 0.25);
    display: block;
  }
}
