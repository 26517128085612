.banner-corner {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0 5%;
}

.banner-corner::after {
    content: "";
    position: absolute;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.9;
    z-index: -1;
}

.banner-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;

    &::before {
        border-left: 0 solid transparent;
        border-right: 1em solid transparent;
        border-bottom: 1em solid #F4F5F7;
        left: 0;
        height: 0;
        top: -1em;
        width: 1em;
        position: absolute;
        content: "";
        display: block;
        font-size: 5rem;
        z-index: 2;
    }
}

// BANNER
.learnMoreBannerMain {
    width: 100%;

    .banner {
        background: #384C6C;

        .slider {
            position: relative;
            width: 100%;
            height: 60vh;

            .imageWrapper {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 100vw;
                width: 80%;
                height: 100%;
                object-fit: cover;
                object-position: 46% center;
                z-index: 0;

                img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    filter: brightness(0.3);
                }

                .gradientOverlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 25%;
                    height: 100%;
                    background: linear-gradient(to left, transparent, #384C6C);
                }
            }
        }

        .bannerInfoMain {
            top: 0;
            height: 60vh;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            position: relative;
            /* Ensure text is positioned relative to .bannerInfoMain */
            z-index: 1;
            /* Ensure text is above the video and overlay */
        }
    }

    .bannerInfo {
        width: 100%;
        align-items: center;
        line-height: normal;

        img {
            margin: 0 auto;
        }

        h2 {
            font-size: 70px;
            font-weight: 600;
            font-style: italic;
            letter-spacing: 0.9px;
            line-height: 1;
            font-family: 'Barlow Condensed', sans-serif;
            color: #fff;
            margin-bottom: 0;
            margin-left: 10px;
        }

        p {
            font-size: 30px;
            font-family: 'Barlow', sans-serif;
            color: #fff;
            text-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
            font-weight: 400;
            margin-bottom: 0px;
            margin-left: 10px;
        }

        .whiteButton {
            margin-top: 10px;
            padding: 4px 35px;
            font-weight: 630;
            font-size: 20px;
        }

        .pinkButton {
            box-shadow: 0px 5px 30px rgba(230, 236, 238, 0.5);
            padding: 7px 50px;
            background-color: #000;
            box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.251);
            border: 1px solid #000;
            margin-left: 10px;

            &:hover {
                background-color: #fff !important;
                border: 1px solid #fff !important;
            }
        }
    }
}

.learnMoreContentContainer {
    padding-top: 50px;
    padding-bottom: 70px;
    width: 100%;
    height: 100%;
    background: #F4F5F7;

    .learnMoreContent {
        padding: 70px;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 20px;
        background: #ffffff;

        h2 {
            width: 70%;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.9px;
            line-height: 1;
            font-family: 'Barlow Condensed', sans-serif;
            color: black;
            margin-bottom: 0;
            margin-left: 10px;
        }

        p {
            margin: unset;
            margin-top: 20px;
            margin-bottom: 20px;
            width: 50%;
            text-align: center;
            font-size: 14px;
            font-family: 'Barlow', sans-serif;
            color: black;
            font-weight: 400;
            margin-left: 10px;
        }

        .animatedImageContainer {
            position: relative;
            display: flex;
            // align-items: center;
            // justify-content: center;
            width: 50%;
            height: 50%;
        
            img {
                position: relative;
                width: fit-content;
                width: 100%;
                height: 100%;
                transform: translateY(100%);
                transition-delay: 0s;
                transition: transform .3s ease-out, -webkit-transform .3s ease-out;
            }
        
            img.active {
                transform: translateY(0);
            }
        }

        .learnMoreDecoration {
            position: absolute;
            background-color: #f04c37;
            width: 5px;
            transform: translateX(100%);
            transition-delay: 0s;
            transition: transform .6s ease-out, -webkit-transform .6s ease-out;

            &.long {
                height: 70%;
            }

            &.short {
                height: 50%;
            }

            &.tiny {
                height: 25%;
            }

            &.top {
                top: 0;
            }

            &.bottom {
                bottom: 0;
            }

            &.right {
                right: 0;
            }

            &.left {
                left: 0;
            }

            &.active {
                transform: translateX(0);
            }

            &.long.horizontal {
                height: 5px;
                width: 70%;
            }

            &.short.horizontal {
                height: 5px;
                width: 50%;
            }

            &.tiny.horizontal {
                height: 5px;
                width: 25%;
            }
        }
    }

    @media (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 70px;

        .learnMoreContent {
            padding: 30px;

            .animatedImageContainer {
                width: 80%;
                height: 80%;
            }
            h2 {
                width: 90%;
                font-size: 16px;
            }

            p {
                width: 80%;
                font-size: 10px;
            }
        }
    }
}