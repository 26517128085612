.invitation_card {
  border-radius: 8px;
  border: 2px solid gray;
  padding: 0.5rem;

  .action_button {
    &__reject {
      color: black;
      border: 1px solid gray;
      border-radius: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem;
      cursor: pointer;
      width: 75px;
    }

    &__approve {
      color: white;
      background: blue;
      border-radius: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem;
      cursor: pointer;
      width: 90px;
    }
  }
}

.invitation_card {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .img__container {
    width: 100%;
    height: 100%;

    & .group-avatar__image-wrapper,
      .group-avatar__image-wrapper > *{
      width: 4rem;
      height: 4rem;
    }
  }

  .btn {
    width: 100%;
    margin-top: 0.25rem;
  }
  &__accept-btn {
    background-color: #384c6c;
    border: 1px solid #505050;
    color: #fff;
    text-transform: uppercase;
    padding: 0rem 1rem;
    border-radius: 5%;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    @media screen and (max-width: 992px) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
  &__reject-btn {
    background-color: #fff;
    font-weight: 500;
    border: 1px solid #505050;
    color: #000;
    text-transform: uppercase;
    padding: 0rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
}

.group-label {
  margin: 0.5rem 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
