.selected-formations-container {
  border: 1px solid #E6ECEE;
  border-radius: 10px;
  padding: 10px;

  .selected-formations-row {
    max-width: 75%;

    @media (max-width: 780px) {
      max-width: 100%;

      .col-2 {
        width: 100%;
      }
    }
  }

  .selected-formations-data {
    line-height: 80px;
    height: 80px;
  }
}
