#rendered-trophy {
  margin: 10px;
}

.trophy-preview-modal-content {
  div.trophy_container {
    img {
      width: 100%;
    }
  }
}