.view-popover-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.view-popover-loader {
  height: 30px;
  width: 100%;
}
