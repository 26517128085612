.notfound_main{
    height: 100vh;
    background-color: #f3f5f7;
    display: flex;
    align-items: center;
}

.notfound_content{
    background-color: #fff;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    padding: 30px;
}

.notfound_content{
    h5{
        font-size: 40px;
        font-weight: 800;
    }
    p{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.2px;
    }
    a{
        background-color: #384c6c;
        border: 1px solid #384c6c;
        padding: 10px 20px;
        display: inline-block;
        border-radius: 50px;
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        letter-spacing: 0.3px;
        transition: 0.5s all linear;
        margin: 10px 0px 0px 0px;
        &:hover{
            background-color: transparent;
            color: #384c6c;
        }
    }
}

.notfound_content{
    img{
        margin-bottom: 25px;
    }
}