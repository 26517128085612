.chartFormation {
  .whiteButton, .pinkButton, .blueButton {
    padding: 8px 24px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    height: 40px;
    margin-top: 15px;
  }
}
.title .ant-divider-vertical {
  height: 1rem;
  border-left: 1px solid #384c6c;
}
.badgeRoman {
  background-color: #299E63 !important;
  margin-right: 3px;
  width: 35px;
  text-align: center;
}

.badgeSpindle {
  background-color: #baccd4 !important;
  margin-right: 3px;
  width: 35px;
  text-align: center;
}
.badgeRomanLight {
  background-color: #299E63;
  opacity: 0.7;
  margin-right: 3px;
  width: 35px;
  text-align: center;
}

//DROPDOWN
.specialteams{
  background-color: #F4F7F7;
  border-radius: 50px;
  margin-top: 15px;
  max-width: 350px;
  .ant-dropdown-trigger{
    display: flex;
    justify-content: space-between;
    line-height: 44px;
    padding-left: 20px;
    color: #031424;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
  }
  .anticon-down{
    float: right;
    line-height: 44px;
    padding-right: 20px;
  }
  .ant-dropdown{
    min-width: 340px;
    top: 405px;
  }
}

.specialdrop{
  width: 340px;
  top: 15px;
  .ant-dropdown-menu-item{
    padding: 6px 12px;
  }
  .ant-dropdown-menu-title-content{
    a{
      font-size: 13px;
      color: rgba(3,20,36,0.7)!important;
      text-decoration: none;
      font-weight: 600;
    }
  }
}

.depthcharttable{

  .ant-table-measure-row {
    display: none;
  }

  th{
    text-align: center!important;
    padding: 6px 8px!important;
  }
  td{
    text-align: center!important;
    padding: 6px 8px!important;
    &:nth-child(1){
      text-align: left!important;
    }
  }

  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff !important;
  }
  
  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff !important;
  }
}

.badgemar{
  margin-right: 10px;
}

.depthtable{
  .title{
    font-size: 13px;
    font-weight: 600;
  }

  .resetButton {
    color: #d1403c;
    & :disabled,
    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.a_color{
  color: #031424;
  text-decoration: none;
}

.franchiseoverview-selected-formations-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  position: absolute;
  right: 0;
  z-index: 2;
  margin: 0 20px;
}

@media (max-width: 720px) {
  .franchiseoverview-selected-formations-placeholder {
    height: 60px;
  }
  .franchiseoverview-selected-formations-container {
    top: 20px;
  }
}

.depth-chart-view-formation-modal {
  scale: 1.2;

  @media (max-width: 1000px) {
    scale: 1.0;
  }

  @media (max-width: 800px) {
    transform: translateX(-50px);
    scale: 0.8;
  }

  @media (max-width: 600px) {
    transform: translateX(-250px);
    scale: 0.5;
  }
  .ant-modal-header {
    .ant-modal-title {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      width: 100%;
    }
  }
}

.formation-field {
  background-size: contain;
  width: 730px; 
  height: 400px;
  max-width: 100%;
}

.formation-container {
  position: relative;

  .formation-row {
    width: 52px;
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 100%;

    &:first-of-type {
      top: 40px;
    }

    &:nth-of-type(2) {
      top: 160px;
    }

    &:nth-of-type(3) {
      top: 280px;
    }
    &:nth-of-type(4) {
      top: 240px;
    }

    .formation-row-item {
      .formation-row-header {
        height: 52px;
        width: 52px;
        text-align: center;
        line-height: 52px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 8px;
      }
  
      .formation-row-player {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        max-width: 52px;
        overflow: visible;

        .name-container {
          width: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: translateX(-24px);
          .full-name {
            white-space: nowrap;
            font-size: 12px;
          }
        }
      }
  
      .formation-row-header-black {
        background-color: #000;
        color: #fff;
      }
  
      .formation-row-header-grey {
        background-color: #384c6c;
        color: #fff;
        border-radius: 50%;
      }

      .formation-row-header-white {
        background-color: #f3f5f7;
        color: #000;
        border-radius: 50%;
      }
    }
  }
}