.circleGrey {
  background-color: #888;
  border: 1px solid #888;

  &:hover {
    background-color: #aaa;
    color: #000;
  }
  &:disabled {
    opacity: 0.5;

    &:hover {
      background-color: #ccc;
      color: #fff;
    }
  }
}
