.franchiseProfile {
  height: 100%;
  display: flex;
  align-items: center;

  button {
    height: 100%;
    width: 100%;
  }
  img {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .imageContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    img {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ratingValue {
      position: absolute;
      bottom: 0;
      color: white;
      font-size: 8px;
      font-weight: bold;
    }
  }
}

.franchiseMain {
  background-image: url(../../../assets/images/league/back.jpg);
  width: 100%;
  border-radius: 5px;
  background-size: cover;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 80px;
  .franchiseDetail {
    h5 {
      color: #d75a57;
      font-weight: 700;
      letter-spacing: 0.9px;
      margin-bottom: 8px;
    }
  }
}

.franchiseDetailContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  span {
    color: #fff;
    &:nth-child(1) {
      font-size: 12px;
      color: rgba(230, 236, 238, 0.7);
      letter-spacing: 0.5px;
    }
    &:nth-child(2) {
      float: right;
      font-weight: 500;
      letter-spacing: 0.5px;
      font-size: 13px;
    }
  }
  .franchiseDetailEmail {
    color: #d1403c !important;
  }
}

.franchiseBoxBackMain {
  padding: 22px 0px;
  .row {
    align-items: flex-end;
    height: 100%;
  }
}

.franchiseBoxBack {
  background-color: #fff;
  border-radius: 2px;
  padding: 10px;
  text-align: center;
  p {
    margin-bottom: 3px;
    color: #384c6c;
    font-weight: 600;
    font-size: 12px;
  }
  h5 {
    margin-bottom: 0px;
    color: #384c6c;
    font-weight: 900;
    letter-spacing: 0.3px;
    font-size: 22px;
    white-space: nowrap;
  }
}

//RESPONSIVE
@media (max-width: 1200px) {
  .franchiseDetail {
    margin-top: 0px;
    h5 {
      margin-bottom: 15px;
    }
    .row {
      justify-content: center;
    }
  }
}

@media (max-width: 991px) {
  .franchiseDetail {
    margin: 15px 0px;
    h5 {
      text-align: center;
    }
  }
}
