:root {
  --gamePlayBall-left-0: 46%;
  --gamePlayBall-left-30: 40%;
  --gamePlayBall-left-60: 34%;
  --gamePlayBall-left-100: 26%;

  --gamePlayBall-right-0: 46%;
  --gamePlayBall-right-30: 40%;
  --gamePlayBall-right-60: 34%;
  --gamePlayBall-right-100: 26%;
}

.groundmain {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    &:nth-child(1) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:nth-child(2) {
      position: absolute;
      width: 60px;
    }
  }
}

.gameplay-ball-animation-left {
  -webkit-animation: moveX-left 2s linear 0s infinite alternate;
  -moz-animation: moveX-left 2s linear 0s infinite alternate;
  -o-animation: moveX-left 2s linear 0s infinite alternate;
  animation: moveX-left 1.5s linear 0s alternate;
}

.gameplay-ball-animation-right {
  -webkit-animation: moveX-right 2s linear 0s infinite alternate;
  -moz-animation: moveX-right 2s linear 0s infinite alternate;
  -o-animation: moveX-right 2s linear 0s infinite alternate;
  animation: moveX-right 1.5s linear 0s alternate;
}

@keyframes moveX-left {
  0% {
    left: var(--gamePlayBall-left-0);
  }
  30% {
    left: var(--gamePlayBall-left-30);
  }
  60% {
    left: var(--gamePlayBall-left-60);
  }
  100% {
    left: var(--gamePlayBall-left-100);
  }
}

@keyframes moveX-right {
  0% {
    right: var(--gamePlayBall-right-0);
  }
  30% {
    right: var(--gamePlayBall-right-30);
  }
  60% {
    right: var(--gamePlayBall-right-60);
  }
  100% {
    right: var(--gamePlayBall-right-100);
  }
}

@-webkit-keyframes moveX-left {
  0% {
    left: var(--gamePlayBall-left-0);
  }
  30% {
    left: var(--gamePlayBall-left-30);
  }
  60% {
    left: var(--gamePlayBall-left-60);
  }
  100% {
    left: var(--gamePlayBall-left-100);
  }
}

@-webkit-keyframes moveX-right {
  0% {
    right: var(--gamePlayBall-right-0);
  }
  30% {
    right: var(--gamePlayBall-right-30);
  }
  60% {
    right: var(--gamePlayBall-right-60);
  }
  100% {
    right: var(--gamePlayBall-right-100);
  }
}

@-moz-keyframes moveX-left {
  0% {
    left: var(--gamePlayBall-left-0);
    transform: rotate(90deg);
  }
  30% {
    left: var(--gamePlayBall-left-30);
    transform: rotate(-180deg);
  }
  60% {
    left: var(--gamePlayBall-left-60);
    transform: rotate(-270deg);
  }
  100% {
    left: var(--gamePlayBall-left-100);
    transform: rotate(360deg);
  }
}

@-moz-keyframes moveX-right {
  0% {
    right: var(--gamePlayBall-right-0);
    transform: rotate(90deg);
  }
  30% {
    right: var(--gamePlayBall-right-30);
    transform: rotate(-180deg);
  }
  60% {
    right: var(--gamePlayBall-right-60);
    transform: rotate(-270deg);
  }
  100% {
    right: var(--gamePlayBall-right-100);
    transform: rotate(360deg);
  }
}

// QUATER MAIN
.quater_chat {
  margin-top: 20px;
}

.quatermain {
  border: 1px solid #f2f2f2;
  padding: 10px 5px;

  h3 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 24px;
  }
}

.quaterSection {
  padding: 10px;
}

.quaterSectionMain {
  height: 350px;
  overflow-y: scroll;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #384c6c;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
}

.quaterSection {
  margin-bottom: 15px;
  p {
    margin-bottom: 0px;
  }
  h6 {
    font-weight: 600;
    letter-spacing: 0.2px;
  }
}

// CHAT SECTION
.chatMain {
  margin-top: 20px;
  h3 {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0.3px;
  }
}

.chatSectionMain {
  height: 300px;
  overflow-y: scroll;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #384c6c;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
}

.chatSection {
  display: flex;
  align-items: center;
}

.chatSection {
  margin-bottom: 15px;
  h5 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-bottom: 3px;
  }
}

.chatSection {
  p {
    margin-bottom: 0px;
  }
}

.chatSection {
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.chatinput {
  input {
    width: 90%;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    &:focus {
      outline: none;
    }
  }
}

.trial-game-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    text-align: center;
    font-size: 18px;
  }
}

.share-gameplay {
  .share-gameplay-header {
    text-align: center;
  }
  @media screen and (min-width: 990px) {
    margin-top: 30px;
  }

  .share-options-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // flex-direction: column;
    align-items: center;
    gap: 10px;

    .anticon {
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }

      svg {
        color: #384c6c;
        width: 48px;
        height: 48px;
      }
    }
  }
  &__button {
    width: 20px;
    height: 20px;
    span {
      width: 20px;
      height: 20px;
    }
  }
}

.pbp-comment {
  position: absolute;
  display: flex;
  align-items: center;
  height: 21%;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  
  .pbp-comment-image {
    margin: 0 10px;
    height: 70%;
    width: 10%;
    img {
      height: 100%;
    }
  }
  
  .pbp-comment-message {
    font-weight: bold;
    font-style: italic;
    font-size: 1.8vw;
    line-height: normal;
    color: #D1403C;
    max-width: 77%;
    min-width: 76%;
    overflow: hidden;
    max-height: 42%;
    padding-right: 6%;

    @media (min-width: 992px) {
      font-size: 9pt;
    }
  }

  .pbp-comment-message-background {
    position: absolute;
    z-index: -1;
    width: 80%;
    height: 45%;
    border-radius: 0.4rem;
    background-color: #f1f1f1;
  }
}

.gameplay__score-helmets-container {
  padding-top: 0px;
  position: relative;

  @media screen and (min-width: 600px) {
    padding-top: 90px;
  }
}

.gameplay__ground-wrapper {
  position: relative;
}

.gameplay__score-wrapper {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media screen and (min-width: 600px) {
    bottom: -25px;
  }
}

.gameplay__helmets-wrapper {
  position: relative;
  padding-bottom: 115px;
  z-index: 1;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  
  @media screen and (min-width: 600px) {
    position: absolute;
    gap: 16rem;
    padding-bottom: 0;
  }
}