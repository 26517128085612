.changepassword{
    .blueButton{
        padding: 10px 25px!important;
    }
}

//RESPONSIVE BUTTON
@media(max-width : 576px){
    .changepassword{
        .blueButton{
            margin-bottom: 20px;
            width: 100%;
        }  
    }
}