.elimination-customization-container {

  .form-label {
    font-weight: bold;
  }

  padding: 0 40px;
  @media(max-width: 860px) {
    padding: 0 0;
  }

  .elimination-customization-header {
    text-align: center;
  }

  .elimination-customization-title {
    font-size: 14px;
    font-weight: bold;
  }

  .elimination-customization-container-footer {
    display: flex;
    justify-content: center;

    .blueButton {
      font-size: 20px;
    }
  }
}

.contest-name-label {
  font-weight: bold;
}

.username-or-email {
  text-decoration: underline;
  cursor: pointer;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  font-size: 12px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #c00000;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0.9;
  white-space: nowrap;
  z-index: 1;
}

.advanced-options {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 10px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 500ms ease, opacity 500ms ease;
  margin-bottom: 20px;

  &.elimination-options {
    border: none;
    padding: 0;
  }

  &-open {
    max-height: 1000px;
    opacity: 1;
    transition: max-height 500ms ease, opacity 500ms ease;
  }

  &-toggler {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    
    p {
      margin: 0;
    }
  }
}

.tournament-start-date-picker {
  border-radius: 50px !important;
  height: 45px !important;
  padding: 6px 36px 6px 20px !important;
  background:#f4f7f7 !important;
  border-width: 0 !important;
  color: #000 !important;

  div {
    input {
      color: #000 !important;
      &::placeholder {
        color: #000 !important;
      }
    }
  }
}

