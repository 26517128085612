.trade-proposal {
  h6 {
    color: #d75a57;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .trade-for-player {
    background-color: #e6ecee;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 0.125rem;
    margin-right: 0.125rem;

    .col-xl-3 {
      padding-right: 0;
    }

    .col-xl-1 {
      padding-left: 0;
    }

    .player-basic-info {
      display: flex;
      flex-direction: column;
      border-right: 2px solid #000;
      height: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;

      span {
        text-transform: uppercase;
        font-size: 1rem;

        &:first-of-type {
          font-weight: bold;
        }

        // &:nth-last-of-type(2) {
        //   margin-bottom: 1rem;
        // }
      }

      &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;

        .row {
          height: 100%;

          &:first-of-type {
            border-bottom: 2px solid #000;
            align-items: flex-end;
          }
        }

        &__trait {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.25rem;
          padding: 0.25rem;

          span {
            text-align: center;

            &:first-of-type {
              font-weight: bold;
            }
          }
        }
      }
    }

    .ovr-container {
      display: flex;
      align-items: center;

      div {
        border: 2px solid #000;
        border-radius: 50%;
        font-size: 2rem;
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        text-align: center;
        background-color: #fff;
        color: #d75a57;
        font-weight: bold;

        &::after {
          content: 'OVERALL';
          font-size: 0.85rem;
          color: #000;
          display: block;
          font-weight: 300;
        }
      }
    }
  }
}

.propose-button-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.players-to-trade-container {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 20px;
}
