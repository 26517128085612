.play-free-main {
  margin-top: 120px;

  .play-free-header {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .play-now-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .pinkButton {
      font-size: 28px;
    }
  }
}

.play-free-main-column-content-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.play-free-team-selector-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
  gap: 20px;
  max-width: 640px;

  .team-image-container {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ebebeb;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .team-image-container-selected {
    background-color: #384c6c;
  }

  .team-image-container-opponent {
    background-color: #D1403C;
  }
}

.selected-team-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  
  @media (max-width: 1200px) {
    margin-top: 80px;
  }

  .selected-team-article {
    border: 1px solid #ebebeb;
    padding: 10px;
    width: 100%;

    .selectet-team-article-stat {
      display: flex;
      justify-content: space-between;
    }
  }
}
