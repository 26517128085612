.circlePink {
  background-color: #d1403c;
  border: 1px solid #d1403c;

  & img {
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: #ee4943;
    color: #000;
  }
  &:disabled {
    opacity: 0.5;

    &:hover {
      background-color: #dfb1b0;
      color: #fff;
    }
  }
}

.notRotated {
  img {
    transform: rotate(0deg);
  }
}
