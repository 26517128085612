.leagueProfile{
    img{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}

.leagueMain{
    background-image: url(../../../assets/images/league/back.jpg);
    width: 100%;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 80px;
    .leagueDetail{
        h5{
            color: #D75A57;
            font-weight: 700;
            letter-spacing: 0.9px;
            margin-bottom: 8px;
        }
    }
}

.leagueDetailContent{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    span{
        color: #fff;
        &:nth-child(1){
            font-size: 12px;
            color: rgba(230,236,238,0.7);
            letter-spacing: 0.5px;
        }
        &:nth-child(2){
            float: right;
            font-weight: 500;
            letter-spacing: 0.5px;
            font-size: 13px;
        }
    }
    .leagueDetailEmail{
        color: #D1403C!important;
    }
}

.leagueBoxBackMain{
    padding: 10px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    .row{
        align-items: flex-end;
        height: 100%;
    }
}

.leagueBoxBack{
    background-color: #fff;
    border-radius: 2px;
    padding: 10px;
    text-align: center;
    p{
        margin-bottom: 3px;
        color: #384C6C;
        font-weight: 600;
        font-size: 12px;
    }
    h5{
        margin-bottom: 0px;
        color: #384C6C;
        font-weight: 900;
        letter-spacing: 0.3px;
        font-size: 22px;
    }
}

.leaguerating{
    width: 33.33%;
    margin: 0px 5px;
    
    .leagueBoxBack{
        background-color: #D75A57;
        p{
            color: #fff;
        }
        h5{
            color: #fff;
        }
    }
}

.leagueBoxBackSection{
    .rating{
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            color: #fff;
            font-size: 20px;
        }
    }
    .black{
        span{
            color: #384C6C;
        }
    }
}

//MINTED PLAYER
.specialskill{
    .minticon{
        background-color: #384C6C;
        padding: 7px 12px;
        border-radius: 20px;
        color: #fff;
        box-shadow: 3px 1px 0px 0px #D75A57;
        font-weight: 700;
    }
    span{
        &:nth-child(2){
            margin-left: 10px;
            color: #fff;
            font-weight: 700;
            letter-spacing: 0.5px;
            font-size: 16px;
        }
    }
}

//
.franchiseBoxBackMain{
    .col{
        padding-left: 0px;
    }
}

@media(max-width: 1199px){
    .franchiseBoxBackMain{
        .col{
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

@media(min-width: 1200px){
    .leagueBoxBackMain{
        .leagueBoxBackSection{
            padding-left: 0px!important;
        }
    } 
}

@media(max-width: 1200px){
    .leagueMain{
        .leagueDetail{
            h5{
                text-align: center;
                margin-bottom: 15px;
                margin-top: 15px;
            }
            .row{
                justify-content: center;
            }
        }
    }  
}

@media(max-width: 991px){
    .franchiseBoxBackMain{
        .col{
            padding: 5px;
        }
    }
}

.franchiseBoxSection{
    height: 100%;
    display: flex;
    align-items: center;
}

.franchiseBoxSection .franchiseBoxBack {
    min-width: fit-content;
    width: 33.33%;
    margin: 0px 5px;
}

@media(max-width: 350px){
    .franchiseBoxSection{
        display: block;
        .franchiseBoxBack{
            width: 100%;
            margin-bottom: 10px;
            margin-left: 0px;
        }
    }
}

// RATING
.rating{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    h5 {
        margin-right: 5px;
    }
}
