.footer{
    display: flex;
    align-items: center;
    position: relative;
    background: #031424;
    padding: 10px 5px;
    width: 100%;
    height: 20vh;
    font-family: 'Barlow Condensed', sans-serif;
    &::before {
        border-left: 0 solid transparent;
        border-right: 1em solid transparent;
        border-bottom: 1em solid #031424;
        left: 0;
        height: 0;
        top: -1em;
        width: 1em;
        position: absolute;
        content: "";
        display: block;
        font-size: 5rem;
        z-index: 2;
    }
    .footerLeft {
        display: flex;
        align-items: center;
        img{
            border-right: 1px solid #E6ECEE;
            padding-right: 10px;
            margin-right: 10px;
        }
    }
    .footerlink{
        color: #e6ecee;
        .link{
            font-weight: 600;
            letter-spacing: 0.5px;
            text-decoration: none;
        }
        span{
            font-size: 14px;
            letter-spacing: 0.5px;
        }
        a{
            color: #fff;
            text-decoration: none;
        }
    }
}

.socialIcon{
    margin-bottom: 0px;
    padding-left: 0px;
    text-align: end;
    li{
        list-style: none;
        display: inline-block;
        margin-left: 20px;
    }
    img{
        width: 35px;
        height: 35px;
    }
}

// RESPONSIVE
@media(max-width: 991px){
    .socialIcon{
        text-align: left;
        margin-top: 15px;
    }
    .socialIcon li{
        margin-left: 0px;
        margin-right: 10px;
    }
}

@media(max-width: 480px){
    .footer{
        .footerLeft{
            display: block;
            img{
                border-right: 0px;
                margin-bottom: -5px;
            }
        }
    }
    .socialIcon{
        li{
            margin-left: 0px;
            margin-right: 10px;
        }
    }
}
