//PURCHASE FRANCHISE IMAGE
.purchasefranchisemain,
.purfranchisemain .card .cardbody {
    h4 {
        text-align: center;
        color: #384c6c;
        font-weight: bold;
        font-size: 28px;

        span {
            cursor: pointer;
            svg {
                width: 1em;
                height: 1em;
                scale: 0.8;
            }
        }
    }
    .purchaseimage{
        text-align: center;
        img{
            width: 100%;
            border: 1px solid #031424;
            padding: 8px;
            border-radius: 5px;
        }
        p{
            font-weight: 500;
            letter-spacing: 0.3px;
            color: #384C6C;
            margin-bottom: 3px;
        }
        h3{
            color: #384C6C;
            font-weight: 700;
            font-size: 30px;
        }
    }
}

@media(max-width: 991px){
    .purchasefranchisemain{
        margin-bottom: 5px;
    }
}

@media(min-width: 768px){
    .purchasefranchisemain .purchaseimage img{
        width: 100%;
    }
}

@media(max-width: 767px){
    .purchasefranchisemain .purchaseimage img{
        object-fit: contain;
    }
    .purchasefranchisemain .purchaseimage{
        img{
            margin-bottom: 8px!important;
        }
    }
    .purchasefranchisemain .purchaseimage h3{
        font-size: 25px;
    }
}

@media(max-width: 575px){
    .purchasefranchisemain .purchaseimage{
        text-align: left;
    }
    .purchasefranchisemain .purchaseimage img{
        width: auto;
        height: 200px;
    }
}

//PURCHASE FRANCHISE CONTENT
.purchasefranchisemain{
    .purchasefranchisecontent{
        h5{
            color: #384C6C;
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 5px;
            letter-spacing: 0.5px;
        }
        .timecontent{
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
        }
        p{
            font-size: 13px;
            color: rgba(56, 76, 108, 0.7);
            font-weight: 600;
            letter-spacing: 0.1px;
            line-height: 22px;
            margin-bottom: 0px;
            text-align: justify;
            text-transform: uppercase;
        }
        ul{
            padding-left: 20px;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            li{
                font-size: 14px;
                color: rgba(56,76,108,0.7);
                font-weight: 500;
                letter-spacing: 0.3px;
                line-height: 22px;
                margin-bottom: 5px;
            }
        }
        small{
            font-size: 13px;
            color: rgba(56, 76, 108, 0.7);
            font-weight: 600;
            letter-spacing: 0.1px;
            line-height: 22px;
            margin-bottom: 0px;
            text-align: justify;
            text-transform: uppercase;
        }
    }
}

//FRANCHISE PURCHASE TIME
.purchasefranchisemain{
    .openfranchise{
        background-color: #BACCD4;
        display: inline-flex;
        padding: 10px 15px; 
        border-radius: 3px;
        margin: 1rem 0rem;
        img{
            margin-right: 15px;
            width: 40px;
        }
        .franchisetime{
            span{
                color: #031424;
                font-weight: 700;
                letter-spacing: 0.5px;
                font-size: 15px;
            }
        }
    }
}

//SELECT FRANCHISE'S CITY & RANDOM CITY BUTTON
.purchasefranchisemain{
    .selectcity{
        .blueButton{
            width: 100%;
            border-radius: 3px;
            padding: 6px 0px;
            font-size: 13px;
            font-weight: 600;
        }
        .ant-divider-horizontal.ant-divider-with-text{
            margin: 5px 0px;
        }
    }
}

//VERTICAL DIVIDER
.verticaldivider{
    text-align: center;
    .ant-divider-vertical{
        height: 100%;
    }
}   

@media(max-width: 375px){
    .purchasefranchisemain{
        .openfranchise{
            display: block;
            img{
                margin-bottom: 10px;
            }
        }
    }
}

.next-button-container {
    display: flex;
    justify-content: flex-end;
}


@media (max-width: 768px) {
    .purchase-preview-container {
        display: flex;
        justify-content: center;
    }
}
