.walletConnected {
  width: 150px;
  margin-bottom: 17px;
  margin-top: 10px;
}
.customize-main{
  .cardbody{
    padding: 2rem;
  }
}

// Customize Wallet Main
.connectwalletmain{
  h5{
    color: #384C6C;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
  }
}


.customize-main{
  .franchisecity{
    margin-top: 30px;
    h5{
      font-size: 16px;
    }
  }
}

.customizefranchisemain{
  .custombtn{
    display: flex;
    // .blueButton{
    //   width: auto;
    // }
  }
}

//
@media(max-width: 991px){
  .customize-main .franchisecity{
    margin-bottom: 0px;
  }
}

@media(max-width: 767px){
  .customize-main{
    .purchasefranchisemain .purchaseimage{
      text-align: left;
    }
  }
  .customize-main .cardbody{
    padding: 1rem;
  }
}

