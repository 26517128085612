.navbarMain{
    transition: 0.5s all;
    padding: 20px 20px 0px 20px;
    .navbar{
       padding: 15px 15px 15px 15px;
    }
}

.navbarMain.navbar{
    padding-top: 25px;
}

.nav-link{
    color: #fff!important;
    font-weight: 500;
    letter-spacing: 0.9px;
    font-size: 15px;
    height: 40px;
}

.navbarMain.active{
    background: #384C6C;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 5px 5px 0px rgba(5, 15, 32, 0.52);
}

//BANNER RESPONSIVE
@media(max-width: 991px){
    .navbarMain{
        padding: 20px 20px 0px 20px;
    }
}

@media(max-width: 575px){
    .navbarMain{
        padding: 20px 0px 0px 0px;
    }
    .navbarMain.navbar{
        padding-top: 15px;
    }
    .navbar-toggler{
        padding: 0px;
    }
}

.play-free-button {
    margin-right: 10px
}

@media(max-width: 991px){
    .navbar-collapse{
        background: #384C6C;
        padding: 10px 15px;
        border-radius: 2px;
    }

    div.responsive-navbar-nav-without-login {
        background: transparent;
        padding: 0;
        border-radius: unset;

        .play-free-button {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}

.navbar-light .navbar-toggler{
    color: #fff;
    border: 0px;
    &:focus{
        outline: 0!important;
        box-shadow: none;
    }
}

.navbar-nav{
    .pinkButton{
        padding: 10px 30px;
        letter-spacing: 0.5px;
        background-color: #CF6766;
        border: 1px solid #CF6766;
        &:hover{
            color: #CF6766;
        }
    }
}
