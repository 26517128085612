.player-creator-container {
  width: 100%;
  height: 100%;

  svg {

  }
}

.logo-selector-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;

  img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      scale: 1.1;
    }
  }
}
