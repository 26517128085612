.youtubeembed {
  width: 50vw;
  height: 28.125vw;
  max-width: 100%;

  @media (max-width: 1000px) {
    width: 75vw;
    height: 42.1875vw;
    max-width: 100%;
  }
}