.game-results-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;

  .blueButton {
    width: 140px;
    border-radius: 3px;
    font-size: 12px;
    margin-bottom: 5px;
    padding: 7px 30px;
  }
}