.scoremain{
    border: 1px solid #EBEBEB;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 10px;

    .row{
        align-items: center;
    }
}

.leaguescoredrop{
    margin-bottom: 20px;
    .form-label{
        display: none;
    }
    .form-select{
        width: 25%;
        border-radius: 3px!important;
        border: 1px solid #EBEBEB!important;
    } 
    .blueButton {
        width: 260px !important;
        border-radius: 0 !important;
        margin-left: 5px !important;
    }
}

.scorefinal{
    border-right: 1px solid #EBEBEB;
    th{
        font-size: 12px;
        font-weight: 700!important;
        letter-spacing: 0.5px;
        background-color: transparent!important;
        border-bottom: 0px!important;
        color: #031424;
        &::before{
            background-color: transparent!important;
        }
        &:nth-child(2){
            text-align: center;
        }
        &:nth-child(3){
            text-align: center;
        }
        &:nth-child(4){
            text-align: center;
        }
        &:nth-child(5){
            text-align: center;
        }
        &:nth-child(6){
            text-align: center;
        }
        &:nth-child(7){
            text-align: center;
        }
    }
    td{
        border-bottom: 0px!important;
        &:nth-child(2){
            text-align: center;
        }
        &:nth-child(3){
            text-align: center;
        }
        &:nth-child(4){
            text-align: center;
        }
        &:nth-child(5){
            text-align: center;
        }
        &:nth-child(6){
            text-align: center;
        }
    }
    .score{
        background-color: #384C6C;
        padding: 9px 12px;
        border-radius: 20px;
        color: #fff;
        font-weight: 700;
        font-size: 11px;
    }
    .scorered{
        background-color: #D1403C;
    }
}

.leagueHeadMain{
    display: flex;
    align-items: center;

    .helmet {
        height: 90px;
    }
    h6{
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 3px;
    }
    span{
        font-size: 12px;
        font-weight: 600;
        color: rgba(3,20,36,0.5);
        &:nth-child(2){
            margin-right: 15px;
        }
    }
}

.leadersscore{
    border-right: 1px solid #ebebeb;
    h5{
        font-size: 12px;
        font-weight: 700 !important;
        letter-spacing: 0.5px;
        background-color: transparent !important;
        border-bottom: 0px !important;
        color: #031424;
        padding: 8px 8px;
        margin-bottom: 20px;
    }
    ul{
        padding-left: 8px;
        list-style: none;
        li{
            display: inline-block;
            font-size: 12px;
            font-weight: 700 !important;
            letter-spacing: 0.5px;
            &:nth-child(1){
                margin-right: 20px;
            }
            span{
                font-weight: 600;
                color: rgba(3,20,36,0.5);
            }
        }
    }
}

.scorebutton{
    text-align: end;
    h5{
        color: #D1403C;
        font-size: 12px;
        font-weight: 700 !important;
        letter-spacing: 0.5px;
        padding: 8px 8px;
    }
    .blueButton{
        border-radius: 3px;
        font-size: 12px;
        margin-bottom: 5px;
        padding: 7px 30px;
        width: 140px;
    }
}

@media(max-width: 991px){
    .leadersscore{
        margin-top: 10px;
        border-right: 0px;
        h5{
            margin-bottom: 5px;
        }
        ul{
            margin-bottom: 5px;
        }
    }
    .scorebutton{
        text-align: left;
        margin-top: 10px;
        h5{
            margin-bottom: 0px;
        }
        .standscorebtn{
            padding-left: 8px;
            .blueButton{
                margin-right: 8px;
            }
        }
    }
    .scorefinal{
        border-right: 0px;
    }
}

@media(max-width: 767px){
    .standscorebtn{
        display: flex;
        .blueButton{
            width: auto;
            margin-right: 10px;
        }
    }
    .scorebutton{
        text-align: left;
    }
    .leadersscore{
        margin-top: 20px;
        h5{
            margin-bottom: 10px;
        }
    }
}

.pagination{
    justify-content: end;
    margin-bottom: 0px;
    .page-link{
        color: #384C6C;
        font-weight: 600;
    }
    .page-item.active .page-link{
        background-color: #384C6C;
        border-color: #384c6c;
    }
}

@media(max-width: 480px){
    .standscorebtn{
        display: inline-block;
    }
}