.team1main{
    .title{
        font-size: 16px;
        letter-spacing: 0.5px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

//TABLE
.scorecommontable{
    .title{
        font-size: 14px;
        letter-spacing: 0.5px;
        font-weight: 700;
        margin-bottom: 5px;
    }
    th{
        text-align: center!important;
        padding: 5px 8px!important;
    } 
    td{
        text-align: center!important;
        padding: 5px 8px!important;
        &:nth-child(1){
            text-align: left!important;
        }
    }
    .lightcolor{
        color: rgba(3,20,36,0.6);
        background-color: transparent;
        border: 0px;
    }
}