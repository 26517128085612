.tableTitleRowStart {
  display: flex;
  align-items: center;
  h6{
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-bottom: 0px;
    color: rgba(3,20,36,0.6);
    &:nth-child(2){
      cursor: pointer;
    }
  }
}
.tableTitleRowEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.frontofficesub{
  h6{
    font-size: 12px!important;
    font-weight: 600!important;
    letter-spacing: 0.2px!important;
    margin-bottom: 0px;
    color: rgba(3,20,36,1)!important;
  }
}

.frontOfficeBtn {
  flex-direction: row;
  gap: 15px;
}

//RESPONSIVE
@media (max-width: 426px) {
  .standingmain {
    .row {
      .tableTitleRowEnd.col {
        margin-top: 20px;
      }
    }
  }
}

@media(max-width: 575px){
  .tableTitleRowEnd{
    justify-content: flex-start;
  }
  .tableTitleRowStart h6{
    margin-bottom: 10px;
  }
  .frontofficesub h6{
    margin-bottom: 5px;
  }
}
