.select-login-component {
    text-align: center;
    width: 700px;

    h5 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0.3px;
        color: #CF6766;
        font-style: italic;
        text-align: center;
        margin-bottom: 5px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        margin-bottom: 0;
    }

    max-width: 100%;

}

@media(max-width: 800px) {
    .select-login-component {
        text-align: center;
        width: 90% !important;
    }
}

.login-option-footer {
    display: flex;
    flex-direction: column;

    .login-with-button {
        background-color: #384c6c;
        padding: 12px 75px;
        border-radius: 50px;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: 1.5px;
        font-family: 'Barlow Condensed', sans-serif;
        margin-bottom: 10px;
        display: inline-block;
        width: 100%;
    }

    p {
        font-weight: 500;
        letter-spacing: 0.1px;

        a {
            text-decoration: underline;
            color: #384c6c;
        }
    }
}

.login-options {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.login-option-component {
    width: 45%;
    margin: 16px;

    #metamask-login {
        height: 50px;
    }

    .metamask-login-label {
        margin-left: 5px;
    }

    #metamask-login,
    .metamask-login-label {
        cursor: pointer;
    }
}

.login-option-image {
    height: 1rem;
    margin-right: 0.25rem;
}

@media(max-width: 800px) {
    .login-options {
        // background-color: #CF6766;
        flex-direction: column;
        width: 100%;
        // align-items: center;
    }

    .login-option-component {
        width: 100%;
        margin: 15px;
    }
}

@media(max-width: 575px) {
    .login-option-footer {
        .login-with-button {
            padding: 8px 16px;
            width: 100%;
            font-size: 12px;
        }

        p {
            font-size: 12px;
        }
    }

    .login-option-component {
        margin: 8px;

        img {
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
}

@media(max-width: 500px) {
    .login-option-footer {
        .login-with-button {
            padding: 4px 8px;
        }
    }
}

@media(max-width: 480px) {
    .metamask_connect .ant-modal-content {
        padding: 20px;
    }

    .select-login-component h5 {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

@media(max-width: 400px) {
    .login-option-footer {
        .login-with-button {
            height: 50px;
            padding: 4px 8px;
        }
    }
}