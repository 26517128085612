.circleBlack {
  background-color: #000;
  border: 1px solid #000;

  &:hover {
    background-color: #444;
    color: #000;
  }
  &:disabled {
    opacity: 0.5;

    &:hover {
      background-color: #888;
      color: #fff;
    }
  }
}
