.privacy-main{
    margin-top: 120px;
    margin-bottom: 60px;
}

.privacy-main{
    .cardbody{
        padding: 40px;
    }
}

.privacy_head{
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #384C6C;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
}

// Privacy Tab
.privacy-main{
    .nav{
        display: flex;
        flex-direction: inherit!important;
        margin-bottom: 20px;
    }
    .nav-pills .nav-link{
        border: 1px solid #384C6C;
        color: #384C6C!important;
        display: inline-block;
        margin-right: 15px;
        padding: 8px 25px;
        border-radius: 0px;
    }
    .nav-link.active{
        background-color: #384C6C!important;
        color: #fff!important;
    }
    .nav-item{
        display: flex;
    }
}

@media(max-width: 480px){
    .privacy-main{
        .nav{
            display: block;
        }
        .nav-item{
            margin-bottom: 10px;
        }
        .nav-link{
            width: 100%;
            margin-right: 0px!important;
        }
    } 
}

// Privacy Content
.privacy_content{
    h5{
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.5px;
        color: #384C6C;
        margin-bottom: 20px;
    }
}

.privacy_subcontent{
    margin-bottom: 20px;
    h6{
        font-size: 18px;
        color: #031424;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
    }
    p{
        font-size: 16px;
        font-weight: 500;
        text-align: justify;
        line-height: 22px;
        margin-bottom: 10px;
        opacity: 0.75;
    }
}