@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.modal-close-button-section {
    text-align: right;
    margin-top: 20px;
}

.close-modal {
    color: #333333;
    cursor: pointer;
    border: none;
    background: none;
}

.hidden {
    display: none;
}

.modals {
    min-height: 40vh;
    padding: 2rem;
    border-radius: 5px;
    overflow: auto;
}

.avatar-confirm-modal {
    .ant-modal-title {
        font-size: 20px;
        padding-bottom: 20px;
    }

    .ant-modal-header {
        border: 0;
        text-align: center;
    }

    .ant-modal-body {
        text-align: center;
    }

    .ant-modal-footer {
        .ant-btn {
            border-radius: 5px;
            color: white;
            font-weight: bold;
            font-size: 16px;
            padding: 10px 20px;
            height: unset;
          }
      
          .ant-btn-primary {
            background-color: #c00000;
            border: 1px solid #c00000;
            &:hover {
              background-color: transparent;
              color: #c00000;
            }
          }
      
          .ant-btn-default {
            background-color: #384c6c;
            border: 1px solid #384c6c;
            &:hover {
              background-color: transparent;
              color: #384c6c;
            }
          }
    }
}
