label{
    font-size: 14px!important;
    font-weight: 500;
    color: #031424!important;
    letter-spacing: 0.1px;
}

input{
    border-radius: 50px;
    height: 45px;
    background: #F4F7F7;
    border: 0px;
    padding-left: 20px;
    font-size: 14px;
    color: #031424;
    font-weight: 500;
    letter-spacing: 0.5px;
    &::placeholder{
        font-size: 14px;
        color: #819BA2;
        letter-spacing: 0.3px;
    }
    &:focus{
        box-shadow: none;
        background: #F4F7F7;
    }
}