.quarter-stats {
  padding: 3rem 0rem;
  // display: flex;
  &__wrapper {
    border-radius: 0.5rem;
    border: 2px solid #ebebeb;
    padding: 1rem 0.75rem;
    text-align: center;
    height: 100%;
  }
  &__title {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  &__row {
    &:nth-child(even) {
      background-color: #f8f9fa;
    }
    text-align: left;
    border-bottom: 1px solid #ebebeb;
    padding: 0.25rem 0;
    padding-right: 0.5rem;
    padding-left: 1rem;
    &.main {
      font-weight: 600;
      padding-left: 0.5rem;
    }
  }
  &__logo-img {
    width: 30px;
    height: 30px;
    &.hidden {
      display: none;
    }
  }
  &__logos-row {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 0.5rem 0.5rem;
  }
}
.leaders-rows-wrapper {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  height: 100%;
}
.row__t-stat-cell {
  text-align: right;
}
.row__v-divider {
  height: 50px;
}
.row__cell {
  //   width: 50%;
  &-left {
    text-align: right;
  }
  &-right {
    text-align: left;
  }
  &__text {
    margin-bottom: 0;
  }
  &__title {
    letter-spacing: 0.025rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  &__sub-title {
    color: #212529;
  }
  // &__empty {
  //   width: 160px;
  // }
}

.row__title {
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}
