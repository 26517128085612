@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow', sans-serif;
    src: url('./assets/fonts/Barlow-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// Barlow Condensed
@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-Thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-ExtraLight.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('./assets/fonts/BarlowCondensed-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Bahnschrift";
    src: url('./assets/fonts/Bahnschrift.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "CopperplateGothic";
    src: local('CopperplateGothic'), url('./assets/fonts/CopperplateGothicStd-32AB.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segment7';
    src: local('Segment7'), url('./assets/fonts/G7-Segment-7a.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
