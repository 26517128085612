.login-form-component {
  margin-top: 10px;
  margin-bottom: 20px;

  .checkbox {
    margin-bottom: 0px;
  }

  .login-button {
    padding: 10px 35px;

    @media(max-width: 500px) {
      margin-bottom: 10px;
    }
  }

  .login-button-pink {
    text-align: center;
    padding: 10px 35px;
  }

  .email-login-form {
    text-align: left;
  }

  .lost-password-button {
    text-align: end;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
  }

  @media (max-width: 767px) {
    .lost-password-button {
      margin-top: 2vh;
      margin-bottom: 0;
      text-align: center;
    }
  }

  .loginButton {
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 576px) {
      font-size: 14px;
      text-align: left;
      margin-top: 10px;
    }
  }
}