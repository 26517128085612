.purchase-trophy {

  &__header {
    text-align: center;
    color: #384c6c;
    font-weight: bold;
    font-size: 28px;
  }
}

