.search_user__list_container {
  display: flex;
  justify-content: center;
}

.search_user__list {
  text-align: center;
  padding-right: 20px;
  max-height: 200px;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity .3s ease, transform .3s ease;
  &--active {
    opacity: 1;
    transform: translateY(0);
  }
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #384c6c;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

}

.search_user__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
  padding-right: 0.4rem;
  border-radius: 50px;

  &:hover {
    background-color: #f4f7f7;
  }

  & .pinkButton {
    min-width: 90px;
  }
}

.search_user__profile {
  display: flex;
  align-items: baseline;
  cursor: pointer;

  & .img__container {
    margin-right: 0.5rem;
  }

  & img {
    width: 2.5rem;
    height: 2.5rem;
  }

  & p {
    margin: 0;
  }
}

.search_users_list__label_wrapper {
  margin-bottom: 1rem;
}

.search_users_list__label {
  text-transform: uppercase;
  font-size: 1rem;
  text-align: left;
}

// .search_users_list__items_wrapper{
//   opacity: 0;
//   transform: translateY(-100px);
//   transition: opacity 2s ease, transform 2s ease;
//   &--active{
//     opacity: 1;
//     transform: translateY(0);
//   }
// }