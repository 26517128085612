.standingTabbarMain {
  .nav-pills {
    display: inline-block;
  }
  .nav-item {
    display: inline-block;
    margin-right: 15px;
    a {
      color: rgba(3, 20, 36, 0.5) !important;
      font-size: 0.75rem;
      font-weight: 700;
      padding-bottom: 0.65rem;
      letter-spacing: 0.5px;
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: transparent;
    color: #031424;
    border-bottom: 1px solid #d1403c;
    border-radius: 0px;
    border-width: 2px;
  }
  .standingTabbar {
    margin-bottom: 15px;
    .cardbody {
      padding: 0.4rem 0.4rem 0rem 0.4rem;
    }
  }
}

//STANDING
.standingmain {
  .title {
    color: #384c6c;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  thead {
    tr {
      th {
        background-color: #384c6c !important;
        color: #e6ecee;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-size: 12px;
      }
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 0px;
        background-color: #f3f5f7;
        margin-bottom: 5px;
        font-size: 13px;
        font-weight: 600;
        color: #031424;
        border-bottom: 5px solid #fff;
      }
    }
  }
  .ant-table-wrapper {
    margin-bottom: 10px;
  }
}

.blueButton,
.pinkButton {
  padding: 5px 17px;
}
.pinkButton {
  padding: 5px 17px;
}

.whiteButton {
  padding: 5px 17px;
}
button.whiteButton:hover {
  background-color: #384c6c;
  color: #fff;
}

.seasonLink {
  color: rgb(209, 64, 60);
  :hover {
    color: rgb(209, 64, 60);
  }
}

@media (max-width: 768px) {
  .ant-table-wrapper {
    overflow: scroll;
  }
  .whiteButton {
    padding: 5px 6px;
    margin-top: 10px;
  }
  .standingTabbarMain {
    .nav-pills {
      display: block;
    }
  }
  .standingTabbarMain {
    .nav-item {
      display: block;
      &:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 991px) {
  .whiteButton {
    padding: 5px 6px;
    margin-top: 10px;
  }

  a {
    .whiteButton {
      margin-top: 0;
    }
  }
}
