.league-team-main-container {
  display: flex;
  gap: 20px;
  align-items: center;

  div.row {
    width: 100%;
  }
}

.league-team {
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-controls {
    border-radius: 50%;
    background-color: #828b9a;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
      background-color: #5f6b7a;
    }

    &:disabled {
      background-color: #d0d0d0;
    }
  }

  .row {
    width: 100%;

    .col-2 {
      padding: 0;
    }
  }

  &-helmet-container {
    width: 100px;
    height: 100px;
    position: relative;
  }

  &-text {
    display: flex;
    flex-direction: column;
  }

  &-ovr-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;

    &-ovr {
      background-color: #d75a57;
      color: #fff;
      border-radius: 50%;
      font-weight: bold;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      width: 40px;
      height: 40px;
    }
  }
}
