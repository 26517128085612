.presite-points-selector-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .slider_main {
    width: 100%;
    margin: 40px 0;

    .value-selector {
      .ant-slider-step {
        display: none;
      }
      .ant-slider-mark {
        &-text {
          &, & > span {
            font-size: 16px;
            font-weight: bold;
            color: #000;
          }

          span.no-style {
            font-size: 12px;
            font-weight: 500;
            color: rgba(56, 76, 108, 0.5);
          }
        }
      }
    }
  }
  h4, h6 {
    text-align: center;
  }

  .price-calucalation {
    color: #384c6c;
    font-weight: bold;
    border: 1px solid #384c6c;
    padding: 5px 10px;
  }

  .blueButton {
    border-radius: 5px;
  }
}
