.bold {
  font-weight: 700;
}

.red-text {
  color: #d1403c;
}

.resulttitle {
  .pinkButton {
    border-radius: 0;
    background-color: #d1403c;
    font-size: 12px;
    padding: 3px 12px;
  }
}

.teamstat {
  td {
    font-weight: 600 !important;
    letter-spacing: 0.3px;
  }
}

.mb-4 {
  .titleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 10px;

    .pinkButton {
      border-radius: 0;
      margin: 0;
    }

    .title {
      margin: 0;
    }
  }
}

.playerDetailTab {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .tab__section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_heading {
      color: #031424;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.2px;
      // margin-bottom: 10px;
      text-transform: uppercase;
    }

    .achievement {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 30px;
      font-weight: 400;

      .title {
        font-weight: 700;
        width: 120px;
        margin-bottom: 0;
      }

      .achievent_title {
        font-weight: 700;
        width: 240px;
        margin-bottom: 0;
      }

      .value {
        margin-bottom: 0;

        span {
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }

    .two_columns {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}

.player_ownership__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    margin-bottom: 0;
    color: #031424;
    font-size: 16px;
    font-weight: 400;
  }

  .title {
    font-weight: 600;
  }
}

.player_ownership_buttons__container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 8px;
}
.rating-range__selector {
  margin-bottom: 0;
}
.player_ownership__button {
  background-color: #384c6c;
  border: 1px solid #384c6c;
  padding: 10px 20px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.9px;
  transition: 0.5s all;
  text-align: center;

  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: #384c6c;
  }

  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #575757;
  }
}

.physical_traits_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.physical_trait__container {
  display: flex;
  min-width: 140px;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;

  .title {
    color: #031424;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.2px;
    // margin-bottom: 10px;
    text-transform: uppercase;
  }

  .circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #031424;
    font-size: 32px;
    font-weight: 600;
  }

  .subtitle {
    margin-bottom: 0;
  }
}

.special_feature_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 20px;

  img {
    max-height: 150px;
    max-width: 150px;
    border-radius: 50%;
  }
}

thead.career-stats-table {
  tr {
    th.career-stats-table {
      line-height: 12px;
      padding-top: 12px !important;
      padding-bottom: 4px !important;
    }
  }
}

.tab__section_heading {
  &-centered {
    text-align: center;
  }
}


.franchiseHeaderFeatureContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .special_feature_container {
    margin-top: 24px;

      img {
          width: 75px;
          height: 75px;
      }
  }
}