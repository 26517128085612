.carousel_main .player-image {
  box-shadow: inset 6px 6px 20px 0 rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
    rgba(0, 0, 0, 0.05) 0px 16px 45px;
  border-radius: 3px;
  margin: 0 auto;
  filter: blur(1.5px);
  width: 230px;
  padding: 15px;
}

.carousel_main .slick-slide.slick-active.slick-center .player-image {
  animation: 2s linear infinite flipY;
  filter: blur(0px);
}

.carousel_main .slick-slide.slick-active.slick-center .player-image2 {
  animation: none;
  filter: blur(0px);
}

.carousel_main .slick-list {
  height: 100%;
  // display: flex;
  align-items: center;
}

.carousel_image {
  position: relative;
  img {
    width: 250px;
    margin: 0px 0px 40px 0px;
  }
}

.flip-container {
  width: 250px;
  height: 320px;
  position: absolute;
  right: 0px;
  top: 45px;
  z-index: -1;
}

.front,
.back {
  width: 250px;
  height: 320px;
  border-radius: 3px;
  backface-visibility: hidden;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  animation: flipY 2s infinite linear;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
  background-color: #d75a57;
  position: absolute;
  padding-bottom: 1px;
  align-items: flex-end;
  justify-content: center;
  top: -1px;
  left: 0;
  text-align: center;
  display: inline-grid;
  box-shadow: inset 6px 6px 20px 0 rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
    rgba(0, 0, 0, 0.05) 0px 16px 45px;
}

.front {
  h2 {
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.5px;
    &:nth-child(1) {
      font-size: 22px;
    }
    margin-bottom: 6px;
  }
}

.front-playername {
  animation: blinker 1s infinite linear;
  text-transform: uppercase;
}
.front-playername-none {
  animation: none;
  text-transform: uppercase;
}

.front-skill {
  display: initial;
}

.front-skill-none {
  display: none;
}

@keyframes blinker {
  0% {
    text-shadow: 0px 0px 10px 0px #fff, 0px 0px 20px 0px #fff,
      0px 0px 50px 0px #fff, inset 0px 0px 30px #fff;
    color: #fff;
  }
  50% {
    text-shadow: 0px 0px 0px #272727, 0px 0px 0px #272727, 0px 0px 0px #272727;
    color: #272727;
  }
  100% {
    text-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff, 0px 0px 0px #fff;
    color: #fff;
  }
}

.back {
  transform: rotateY(180deg);
  background-color: #d75a57;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 25px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.4px;
  box-shadow: inset 6px 6px 20px 0 rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
    rgba(0, 0, 0, 0.05) 0px 16px 45px;
  padding-bottom: 1px;
  animation: blinker 1s infinite linear;
  text-shadow: 0px 0px 60px, 0 0 22px, 0 0 1em #f00, 0 0 0.5em #f00,
    0 0 0.1em #f00, 0 4px 3px #000;
  text-transform: uppercase;
}

@keyframes flipY {
  from {
    -webkit-transform: rotateY(180deg);
  }
  to {
    -webkit-transform: rotateY(-180deg);
  }
}

@keyframes flipY1 {
  from {
    -webkit-transform: rotateY(180deg);
  }
  to {
    -webkit-transform: rotateY(-180deg);
  }
}

.carousel_main .flip-container {
  display: none;
}

.carousel_main .slick-slide.slick-active.slick-center .flip-container {
  display: block;
}

.carousel_main .slick-slide.slick-active.slick-center .player-image {
  width: 250px;
  padding: 0px;
}

.carousel_main
  .slick-slide.slick-active.slick-center
  .player_image_animation
  span {
  display: none;
}

.player_image_animation {
  position: relative;
  margin: 15px;
  span {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 20, 36, 0.5);
    top: 0;
    left: 0;
    border-radius: 3px;
    padding: 15px;
  }
}

.fireworksStyle {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

.fireworksNone {
  display: none;
}

// RESPONSIVE
@media(max-width: 991px){
  .carousel_main .slick-slide.slick-active.slick-center .player-image{
    width: 250px;
    height: 300px;
    object-fit: contain;
  }
  .carousel_main .player-image{
    width: 250px;
  }
  .player_image_animation span{
    width: 100%;
    transform: translate(0);
    padding: 25px;
    display: none;
  }
  .flip-container{
    right: 0;
    width: 250px;
    height: 300px;
    top: 32px;
  }
  .front, .back{
    width: 100%;
  }
  .player_image_animation{
    padding: 25px;
  }
  .slick-slide.slick-active.slick-center .player_image_animation{
    padding: 0px;
  }
  .playerreveal-main{
    height: 700px;
  }
  .playerreveal-slider{
    margin-top: 20px!important;
  }
  .slick-track {
    align-items: center;
  }
}

@media(max-width: 767px){
  .player_image_animation{
    padding: 0px;
  }
}

@media(max-width: 480px){
  .playerreveal-slider{
    margin-top: 40px!important;
  }
}


.carousel_main .slick-center .player-reveal-name{
    position: absolute;
    bottom: 1.3rem;
    z-index: 1;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 0px;
    bottom: 30px;
    letter-spacing: 1px;
    height: auto!important;
}

// .carousel_main .slick-slide.slick-active.slick-center .player-reveal-name {
//   animation: flipY 3s infinite linear;
//   filter: blur(0px);
// }

// .carousel_main .slick-slide.slick-active.slick-center .player-reveal-name1 {
//   animation: none;
//   filter: blur(0px);
// }