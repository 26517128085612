.walletconnectedmain{
    p{
        font-size: 14px;
        color: rgba(56, 76, 108, 0.7);
        font-weight: 500;
        letter-spacing: 0.3px;
        line-height: 22px;
        margin-bottom: 0px;
        text-align: justify;
        text-transform: uppercase;
    }
    a{
        color: #D1403C;
        &:hover{
            color: #D1403C;
            text-decoration: underline;
        }
    }
}

//METAMASK TRUSTWALLET BUTTON
.selectmain{
    margin-top: 25px;
    p{
        color: #384C6C;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.5px;
        margin-bottom: 5px;
    }
}
.selectwallet{
    margin-top: 8px;
    .metamask{
        background-color: #ff5900;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        object-fit: contain;
    }
}