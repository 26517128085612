@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  height: inherit !important;
}
p {
  padding: 0;
}

button {
  background: none;
  border: none;
  display: inline-block;
}

body {
  margin: 0 auto;
  font-family: "Barlow", sans-serif !important;
  background-color: #f3f5f7 !important;
  overflow-x: hidden;
}

// CONTAINER
@media (min-width: 992px) {
  .container {
    max-width: 1100px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1100px !important;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1240px !important;
  }
}
@media (min-width: 1360px) {
  .container {
    max-width: 1200px !important;
  }
}

//COLOR
.pink {
  color: #d1403c !important;
}

.blue {
  color: #384c6c !important;
}

.blue-light {
  color: rgba(56, 76, 108, 0.5);
}

.dark {
  color: #031424;
}

.Jet {
  color: #baccd4;
}

.light {
  color: #e6ecee;
}
//COLOR END

//FONT-SIZE
.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font18 {
  font-size: 18px !important;
}

.font22 {
  font-size: 22px !important;
}
//FONT-SIZE END

//FONT WEIGHT
.fontweight100 {
  font-weight: 100;
}

.fontweight300 {
  font-weight: 300;
}

.fontweight400 {
  font-weight: 400;
}

.fontweight500 {
  font-weight: 500;
}

.fontweight600 {
  font-weight: 600;
}

.fontweight700 {
  font-weight: 700;
}

.fontweight800 {
  font-weight: 800;
}
//FONT WEIGHT END

//LETTER SPACING
.letter5 {
  letter-spacing: 0.5px !important;
}

.pagelayout {
  padding-top: 0px;
  min-height: 100vh;
}

.ant-modal-content {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px !important;
  box-shadow: 0px -7px 40px 5px rgba(230, 236, 238, 0.6%) !important;
}

.ant-modal-mask {
  background-color: rgba(3, 20, 36, 0.7) !important;
}

.ant-modal-content {
  padding: 40px;
}

.ant-modal-body {
  padding: 0px !important;
}

.ant-modal-close-x {
  width: inherit;
  height: inherit;
}

.ant-modal-footer {
  padding: 0px !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  text-align: center !important;
}

.fixed-top {
  z-index: 150 !important;
}

label {
  font-size: 13px;
  font-weight: 500;
  color: #031424;
  letter-spacing: 0.3px;
}

.form-control {
  border-radius: 50px !important;
  height: 45px !important;
  background: #f4f7f7 !important;
  border: 0px !important;
  padding-left: 20px !important;
  font-size: 14px !important;
  color: #031424 !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  &::placeholder {
    font-size: 14px !important;
    color: #819ba2 !important;
    letter-spacing: 0.3px !important;
  }
  &:focus {
    box-shadow: none !important;
    background: #f4f7f7 !important;
  }
}

.form-select {
  border-radius: 50px !important;
  height: 45px !important;
  background: #f4f7f7 !important;
  border: 0px !important;
  padding-left: 20px !important;
  font-size: 14px !important;
  color: #031424 !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  &::placeholder {
    font-size: 14px !important;
    color: #819ba2 !important;
    letter-spacing: 0.3px !important;
  }
  &:focus {
    box-shadow: none !important;
    background: #f4f7f7 !important;
  }
}

.form-select {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.error {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}
.success {
  color: #00a600;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .container {
    width: 100% !important;
    max-width: fit-content !important;
  }
}

.ant-modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ant-picker {
  width: 100%;
}

.ant-space.ant-space-vertical {
  width: 100% !important;
}

.ant-select-selection-placeholder {
  color: #819ba2 !important;
}

.ant-select-selection-placeholder {
  color: #819ba2 !important;
}

.ant-picker-input input {
  &::placeholder {
    color: #819ba2 !important;
  }
}

.ant-modal-close-icon {
  border: 2px solid #384c6c;
  padding: 5px;
  border-radius: 50px;
  svg {
    color: #384c6c;
    font-size: 10px;
  }
}

.ant-pagination-item-link .anticon {
  vertical-align: text-top;
}

//CUSTOM RADIO BTN
.radiomain {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radiomain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radiomain .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.radiomain:hover input ~ .checkmark {
  background-color: #ccc;
}

.radiomain input:checked ~ .checkmark {
  background-color: #2196f3;
}

.radiomain .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radiomain input:checked ~ .checkmark:after {
  display: block;
}

.radiomain .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.radiomain .checkmark ~ .city {
  color: #00a600;
}

.ant-table-column-sorters {
  flex-direction: row-reverse;
}
.ant-table-column-sorter {
  margin-left: 0px;
  margin-right: 5px;
}

.close-button svg {
  color: white;
  width: 75%;
  display: block;
}

.ant-notification .report-problem {
  width: auto;
  max-width: fit-content;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  padding: 0;

  .ant-notification-notice-message {
    margin: 0;
    color: #d1403c;
    padding: 0;

    span.anticon-warning svg {
      width: 40px;
      height: 40px;
    }
  }
}

.report-problem-button {
  z-index: 99999;
  padding: 4px 12px;

  span svg {
    color: white;
    width: 30px;
    height: 30px;
  }
}

.ant-modal-wrap .report-problem-modal {
  display: flex;
  justify-content: center;
}

.link-style {
  color: #384c6c;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-decoration: underline;
  cursor: pointer;
}

#badge-with-no-styling {
  --bs-badge-padding-x: unset !important;
  --bs-badge-padding-y: unset !important;
  --bs-badge-font-size: unset !important;
  --bs-badge-font-weight: unset !important;
  --bs-badge-color: unset !important;
  --bs-badge-border-radius: unset !important;
  background-color: unset !important;

  .anticon-edit {
    margin: 0;
  }
}

.red-heart {
  svg {
    color: #d1403c;
  }
}

.default-team-heart {
  margin-left: 4px;
  width: 20px;
  height: 20px;

  svg,
  path {
    width: 20px;
    height: 20px;
  }
}

.green-anticon {
  svg {
    color: green;
  }
}

.red-anticon {
  svg {
    color: #d1403c;
  }
}

.red-spin {
  span i {
    background-color: #d1403c;
  }
}

.w-75-vw {
  width: 75vw !important;
}

.link-like {
  display: inline-block;
  color: #384c6c;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.link {
  display: inline-block;
  color: #384c6c;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-decoration: underline;
  cursor: pointer;
}

.c-pointer {
  cursor: pointer;
}

.ant-picker {
  &-ok button{
    background-color: #072f5f !important;
    border-color: #072f5f !important;
    span {
    color: #fff !important;
    }
  }
  &-cell {
    &-selected div {
      background-color: #072f5f !important;
    }
    &-today div::before {
      border-color: #072f5f !important;
    }
  }
}

.banner-active {
  margin-top: 50px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #384c6c !important;
}

.ant-switch-checked {
  background-color: #384c6c !important;
}
