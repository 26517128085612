@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.modal-invite-title {
    font-size: 25px;
    font-weight: 580;
    margin-bottom: 30px;
    text-align: center;
}

///////////////////

.modal-game-info-container {
    width: 100%;
    background-color: #E6ECEE;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
    display:flex;
    // margin-left: 150px;
    border-radius: 10px;
}

.modal-game-prize-pool {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.modal-game-prize-pool > p {
    font-weight: 600;
    font-size: 50px;
    height: 50px;
}

.modal-game-prize-pool > span {
    font-weight: 600;
    font-size: 30px;
    height: 30px;
    margin-top: 12px;
}


.modal-game-info {
    font-size: 20px;
    margin: 20px 40px;
    display: flex;
    flex-direction: column;
}

.modal-game-info-type {
    margin-bottom: 10px;
}

.modal-game-info-type > p {
    font-weight: 600;
}

.modal-game-info-detail {
    display: flex;
    gap: 10px;

    @media (max-width: 1300px) {
        font-size: 16px;
    }

    @media (max-width: 1000) {
        font-size: 12px;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.modal-game-info-value {
    font-weight: 500;
}

.modal-game-id {
    font-weight: 400;
}

// Invite section

.modal-invite-section {
    text-align: left;
    margin-top: 40px;
}

.modal-invite-section-title {
    font-size: 25px;
    font-weight: 580;
    margin-bottom: 10px;
}

.modal-invite-input {
    margin-bottom: 10px;
    font-size: 18px;
}

.modal-invite-input > input {
    border-radius: 6px;
    width: 100%;
}

.modal-invite-button {
    margin-bottom: 10px;
}

.modal-invite-button > button {
    width: 100%;
    height: 30px;
}

.modal-close-button-section {
    text-align: right;
    margin-top: 20px;
}

.close-modal {
    color: #333333;
    cursor: pointer;
    border: none;
    background: none;
}

.hidden {
    display: none;
}

.modals {
    min-height: 40vh;
    padding: 2rem;
    border-radius: 5px;
    overflow: auto;
}

.leave-game-modal {
    .ant-modal-header {
        border: 0;
        text-align: center;
    }

    .ant-modal-body {
        text-align: center;
    }

    .ant-modal-footer {
        .ant-btn {
            border-radius: 5px;
            color: white;
            font-weight: bold;
            font-size: 16px;
            padding: 10px 20px;
            height: unset;
          }
      
          .ant-btn-primary {
            background-color: #c00000;
            border: 1px solid #c00000;
            &:hover {
              background-color: transparent;
              color: #c00000;
            }
          }
      
          .ant-btn-default {
            background-color: #384c6c;
            border: 1px solid #384c6c;
            &:hover {
              background-color: transparent;
              color: #384c6c;
            }
          }
    }
}
