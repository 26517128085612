$ball-default-filter: drop-shadow(0px 8px 5px rgba(0, 0, 0, 0.6));

.animationViewer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.closeAnimationViewer {
  position: absolute !important;
  top: 1.5rem;
  right: 1.5rem;
}

.football-ball{
  position: absolute;
  top: 38%;
  filter: $ball-default-filter;
  transition: all linear;
  transition-duration: var(--duration);
}

.football-ball-animation {
  animation: throw-ball var(--duration, 1.3s) linear
}

@keyframes throw-ball {
  0% {
    transform:scale(1);
    filter: $ball-default-filter;
  }
  50% {
    transform:scale(var(--maxSize));
    filter: drop-shadow(0px var(--shadowOffset) 8px rgba(0, 0, 0, 0.3));
  }
  100% {
    transform:scale(1);
    filter: $ball-default-filter;
  }
}