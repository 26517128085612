.purfranchisemain {
  .cardbody {
    padding: 2rem;
  }
}

@media(max-width: 767px){
  .purfranchisemain {
    .cardbody {
      padding: 1rem;
    }
  }
}

// SELECT CITY MODAL START
.selectcitymodal {
  padding-top: 24px !important;
  .blueButton {
    border-radius: 3px;
    padding: 7px 30px;
    margin: 20px 0px 0px 0px;
    font-weight: 500;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    padding: 2rem !important;
  }
}
.citymodalmain {
  .citymodalheader {
    margin-bottom: 20px;
    text-align: center;
    h5 {
      font-weight: 700;
      letter-spacing: 0.3px;
      color: #384c6c;
      font-size: 18px;
      margin-bottom: 3px;
    }
    p {
      color: rgba(56, 76, 108, 0.7);
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 23px;
      text-transform: initial;
      margin-bottom: 0px;
    }
  }
  .citymodalcontent {
    p {
      color: rgba(56, 76, 108, 0.7);
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 23px;
      text-transform: initial;
      text-align: center;
    }
    .cityname {
      background-color: #e6ecee;
      padding: 8px;
      border-radius: 3px;
      cursor: pointer;
      margin-bottom: 10px;

      .city-price-container {
        display: flex;
        flex-wrap: nowrap;
        text-wrap: nowrap;
        gap: 2px;
      }
      
      &.random {
        display: flex;
        justify-content: center;
      }
      span {
        font-weight: 600;
        color: #031424;
        letter-spacing: 0.3px;
        font-size: 12px;
        &:nth-child(2) {
          float: right;
        }
      }
      .lineThrough {
        text-decoration: line-through;
        color: rgba(56, 76, 108, 0.5);
      }
    }
    .citymodalcontent {
      p {
        color: rgba(56, 76, 108, 0.7);
        font-weight: 600;
        letter-spacing: 0.3px;
        line-height: 23px;
        text-transform: initial;
        text-align: center;
      }
      .cityname {
        background-color: #e6ecee;
        padding: 8px;
        border-radius: 3px;
        cursor: pointer;
        margin-bottom: 10px;
        position: relative;
        span {
          font-weight: 600;
          color: #031424;
          letter-spacing: 0.3px;
          font-size: 12px;
          &:nth-child(2) {
            float: right;
          }
        }
        .lineThrough {
          text-decoration: line-through;
          color: rgba(56, 76, 108, 0.5);
        }
      }
    }
  }

.selectcity input:checked ~ .city {
  color: #d1403c !important;
}

.selectcity {
  margin-bottom: 0px !important;
  padding-left: 0px;
  .radiomain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .checkmark {
    opacity: 0;
  }
  .cityprice {
    float: right;
  }
}

  //RANDOM CITY MODAL
  .randommodalmain {
    .citymodalheader {
      margin-bottom: 25px;
    }
    .randomcitycontent {
      width: 80%;
      margin: 0 auto;
      p {
        color: #384c6c !important;
        font-weight: 700 !important;
      }
      .cityname{
        background-color: transparent;
      }
    }
    .randomcityname {
      background-color: transparent !important;
      padding: 0px !important;
    }
  }
}

.randomcityname{
  background-color: transparent!important;
  padding: 3px!important;
}

@media(max-width: 991px){
  .purfranchisemain{
    margin-bottom: 10px;
    .verticaldivider{
      display: none;
    }
  }
}

// FRANCHISE SALES
.franchisesales{
  width: 600px!important;
  h5 {
    font-size: 24px;
    font-weight: 600;
  }
  h4 {
    color: #D1403C;
  }

  .ant-modal-footer {
    display: none;
  }

  p {
    font-size: 16px;
    text-align: justify;
    line-height: 24px;
    margin-bottom: 0px;
    color: rgb(89,89,89);
  }
  
  .message-and-image-container {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      height: 220px;
      width: 175px;
    }

    @media (max-width: 600px) {
      flex-direction: column;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
