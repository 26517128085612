.puntbodymain{
    margin-left: 50px;
    padding-top: 30px;
    .phase{
        margin-bottom: 18px;
        h6{
            color: #384C6C;
            font-weight: 600;
            letter-spacing: 0.3px;
            margin-bottom: 5px;
            font-size: 15px;
        }  
        p{
            font-weight: 500;
            color: rgba(3,20,36,0.7);
            margin-bottom: 0px;
            font-size: 14px;
            letter-spacing: 0.3px;
                :nth-child(2){
                    margin-bottom: 0px;
                }
        }
    }
}