.trending_main{
    width: 650px!important;
    top: 50px!important;
    padding-bottom: 24px!important;
    .ant-modal-header{
        display: none;
    }
    .ant-modal-content{
        width: 100%;
    }
    .ant-modal-footer{
        display: none;
    }
}

.trending_header{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img{
        width: 40px;
        margin-right: 10px;
    }
    h5{
        color: #90AEBB;
        font-weight: 600;
        letter-spacing: 0.4px;
    }
}

.trending_body{
    h6{
        color: rgba(3,20,36,0.5);
        font-weight: 600;
        letter-spacing: 0.3px;
    }
    p{
        color: #384C6C;
        font-weight: 400;
        text-align: justify;
    }
}

// UnderConstruction Modal
.trending_main{
    h5{
        font-size: 24px;
        font-weight: 500;
        color: #031424;
        margin-bottom: 12px;
        text-align: center;
    }
}

.trending_main{
    p{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px;
    }
}

.trending_main{
    ul{
        padding-left: 15px;
        margin: 10px 0px;
    }
    li{
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
    }
}

// Tranding Des
.tranding_des{
    display: inline-flex;
    width: 100%;
    align-items: center;
    p{
        margin-bottom: 0px!important;
        display: inline-block!important;
    }
}

// Uunder_construction
.under_construction{
    .ant-modal-footer{
        display: block;
    }
    .blueButton{
        padding: 8px 30px;
        margin-top: 15px;
    }
}

// Tranding
.trending_body{
    li{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px;
        color: #384C6C;
        ul{
            margin-top: 0px;
        }
    }
}

@media(max-width: 575px){
    .tranding_des{
        margin-bottom: 10px;
    }
}