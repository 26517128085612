.newsmain{
    margin-bottom: 50px;
}

.newscontent{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.newscontent img{
    width: 140px;
    margin-right: 15px;
}

.newssubcontent{
    h6{
        color: #D75A57;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 10px;
    }
}

.newssubcontent{
    p{
        color: #fff;
        margin-bottom: 5px;
        font-weight: 500;
        letter-spacing: 0.4px;
    }
}

// Accordian Header
.accordian_mainsection{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.accordian_date{
    background-color: #BACCD4;
    padding: 15px 0px;
    border-radius: 5px;
    text-align: center;
    margin-right: 15px;
    width: 75px;
    h6{
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-size: 18px;
        &:nth-child(1){
            margin-bottom: 5px;
        }
        &:nth-child(2){
            margin-bottom: 0px;
        }
    }
}

.accordian_header{
    h5{
        font-size: 18px;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        color: #384C6C;
        text-transform: uppercase;
        font-weight: 700;
    }
    p{
        font-size: 16px;
        color: #031424;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 0px;
        letter-spacing: 0.2px;
        opacity: 0.8;
        text-transform: capitalize;
    }
}

.accordian_section{
    display: flex;
    align-items: center;
}

.accordian_logo{
    text-align: center;
    img{
        width: 60px;
        margin-left: 20px;
        text-align: right;
    }
}

.accordian_main{
    .accordion{
        
    }
    .accordion-item{
       margin-bottom: 15px;
       border: 1px solid #f1f1f1!important;
       border-left: 1px solid #f1f1f1!important;
       border-right: 1px solid #f1f1f1!important;
       border-top: 1px solid #f1f1f1!important;
       border-bottom: 1px solidm #f1f1f1!important;
    }
    .accordion-header{
        
    }
    .accordion-button{
        
        box-shadow: none!important;
    }
    .accordion-button:not(.collapsed){
        background-color: transparent;
    }
}

.accordion-flush .accordion-item:last-child{
    border-bottom: 1px solidm #f1f1f1!important; 
}

.accordion-body{
    padding-top: 0px!important;
    p{
        font-size: 14px;
        color: #031424;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 5px;
        letter-spacing: 0.4px;
        opacity: 0.8;
        text-align: justify;
        line-height: 22px;
    }
}

.newsmain .accordion-button::after{
    display: none;
}

@media(max-width: 767px){
    .accordian_section{
        display: block;
    }
    .accordian_date{
        margin-bottom: 15px;
    }
}

@media(max-width: 575px){
    .accordian_mainsection{
        display: block;
    }
    .accordian_logo{
        display: none;
    }
    .accordian_header h5{
        line-height: 25px;
    }
}

@media(max-width: 480px){
    .newscontent{
        display: block;
        text-align: center;
    }
    .newscontent img{
        margin-bottom: 30px;
        margin-right: 0px;
        width: 100px;
    }
}