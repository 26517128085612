.navbarLoginMain {
  position: relative;
  margin-bottom: 50px;
  .navbar {
    background-color: #384c6c !important;
    z-index: 10 !important;
  }
  .navbarMenu {
    width: 100%;
    .navbar-brand {
      margin-right: 0px;
    }
  }
  .leftSideMenu {
    justify-content: flex-end;
    .nav-link {
      margin-left: 10px;
    }
  }
  .rightSideManu {
    .nav-link {
      margin-right: 10px;
    }
  }
  .logo {
    background: -webkit-linear-gradient(110deg, #d1403c, #d75a57);
    position: absolute;
    width: 110px;
    left: 49%;
    padding: 15px;
    padding-bottom: 20px;
    padding-top: 15px;
    z-index: 55;
    border-radius: 0 0 100px 100px;
    transform: translateX(-50%);
    box-shadow: 0 0 10px 1px rgba(78, 78, 78, 0.4);
    top: 0;
    text-align: center;
    img {
      width: 50px;
    }
  }
}

//LOGIN WITH NAVBAR
.userconnected {
  display: flex;
  div {
    display: flex;
    align-items: center;
    margin-right: 15px;
    img {
      margin-right: 5px;
      width: 25px;
    }
    span {
      color: #fff;
      font-weight: 600;
      letter-spacing: 0.3px;
    }
  }
  .user {
    margin-right: 10px;
    width: 25px;
  }
}

.withloginmain {
  .navbar {
    background-color: #384c6c !important;
    border: 0px;
    box-shadow: 10px 0px 50px 0px rgba(0, 0, 0, 0.09%);
  }
  .logoheader {
    img {
      background-color: #d1403c;
      padding: 20px;
      border-radius: 0px 0px 50px 50px;
      width: 90px;

      position: relative;
      z-index: 85;
    }
  }
  .navbar-brand {
    padding: 0px !important;
  }
  .leftmenu {
    justify-content: end;
    margin-right: 140px;
    z-index: 75;
  }
  .rightmenu {
    justify-content: space-between;
    z-index: 75;
    .wallet {
      margin-right: 20px;
      img {
        margin: 0px 8px;
        &:nth-child(2) {
          border-right: 1px solid #f1f1f1;
          padding-right: 10px;
        }
      }
    }
    .leftmenu {
      justify-content: end;
      margin-right: 140px;
      z-index: 75;
    }
    .rightmenu {
      justify-content: space-between;
      z-index: 75;
      .wallet {
        margin-right: 20px;
        img {
          margin: 0px 8px;
          &:nth-child(2) {
            border-right: 1px solid #f1f1f1;
            padding-right: 10px;
          }
        }
      }
    }
    .navbar-collapse {
      width: 100%;
    }

    .navbar-brand {
      position: absolute;
      width: 100%;
      left: 0;
      top: -1px;
      text-align: center;
      margin: auto;
      padding: 0px;
    }
    .navbar-toggler {
      z-index: 65;
    }
  }
  .navbar-collapse {
    width: 100%;
  }

  .navbar-brand {
    position: absolute;
    width: 100%;
    left: 0;
    top: -1px;
    text-align: center;
    margin: auto;
    padding: 0px;
  }
  .navbar-toggler {
    z-index: 65;
  }
}

//WALLET ICON CONNECTED
.wallet {
  display: flex;
  align-items: center;
}

.ant-popover {
  position: fixed !important;
}

//TOGGLE ICON
.menubartoggle {
  z-index: 65;
  .navbar-toggler-icon {
    background-color: #fff;
  }
}

//RESPONSIVE
@media (max-width: 991px) {
  .withloginmain {
    .leftmenu {
      padding-bottom: 0px;
    }
    .rightmenu {
      padding-top: 0px;
      .wallet {
        margin-top: 10px;
        img {
          &:nth-child(1) {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
}

// Wallet Amount
.wallet_amount {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.4px;
  margin-right: 5px;
  text-align: center;
}

.ant-badge.navbar-badge {
  font-size: unset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  a.nav-link {
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-badge-count {
    @media (max-width: 991px) {
      right: -10px;
    }
  }
}
