.connect-new-wallet-modal {
  text-align: center;
  padding: 0;

  p {
    color: #384C6C;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 25px;
    text-transform: initial;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.min-amount-error-container {
   color: red;
   height: 24px;
}