.profile-teams {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-bottom: 10px;
    }
    .pinkButton {
      height: 40px;
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__image {
    width: 150px;
    height: fit-content;
  }
}
