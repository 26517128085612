.statsTable.standingmain {
  table {
    border: 1px solid #D9DBDE;
    border-radius: 0px;
    thead {
      tr {
        th {
          font-size: 14px;
          border-radius: 0px!important;
          letter-spacing: 0.5px;
        }
      }
    }
    tbody {
      tr {
        td {
          background-color: #fff;
          font-size: 13px;
          border-bottom: 1px solid #D9DBDE;
          height: auto!important;
        }
      }
      tr :first-child {
        opacity: 0.7;
      }
    }
  }
}
