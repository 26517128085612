.divider {
  margin: 0 0.5rem;
  height: 0.75rem;
  width: 1px;
  background-color: #000;
}
.week-players-row {
  margin-top: 1rem;
}
.player-item {
  border: 1px solid #ebebeb;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.75rem;

  &__right-content {
    padding-left: 1rem;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__subtext-row {
    display: flex;
    align-items: flex-end;
    line-height: 0.875rem;
  }
}

.name-link {
  background: none;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  color: #212529;
  opacity: 1;
  cursor: pointer;
}
