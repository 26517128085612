.circleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  background-color: #384c6c;
  border: 1px solid #384c6c;
  width: 34px;
  height: 34px;
  
  & img {
    width: 26px;
    height: 26px;
  }

  &:hover {
    background-color: #476493;
    color: #384c6c;
  }
  
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #575757;
  }    
}