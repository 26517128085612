.group-avatar-empty {
  background-color: #031424;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .group-icon {
    width: 80%;
    height: 80%;
    path {
      fill: #fff !important;
    }
  }
}
