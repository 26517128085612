.blackButton{
    background-color: #000;
    border: 1px solid #000;
    padding: 9px 25px;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.9px;
    transition: 0.5s all;
    border-radius: 50px;
    &:hover{
        background-color: transparent;
        color: #000;
    }
    &:disabled {
        opacity: 0.5;

        &:hover {
            background-color: #000;
            color: #fff;
        }
    }
}