//HELMET LEFT SIDE
.franchise {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;

  .helmet {
    position: relative;
    margin-top: 20px;
    &__item {
      object-position: 50% 50%;
      &--left {
        transform: scale(2) scaleX(-1);
      }
      &--right {
        transform: scale(2);
      }
    }

    &__logo {
      top: -10px;
      width: 50%;
      position: absolute;
      &--left {
        left: 10%;
        transform: scaleX(-1);
      }
      &--right {
        left: 45%;
      }
    }
  }

  .ready-btn {
    min-width: 160px;
  }

  img {
    width: 80px;
    height: auto;
  }

  .name-container {
    position: relative;

    .franchise-name,
    .own-football {
      text-align: center;
      margin: -20px 0 0 0;
      margin-bottom: 0px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    .own-football {
      position: absolute;
      z-index: 20;
      width: 30px;
      margin: 0 auto;
      height: auto;
      top: 15px;
      transform: translate(-50%, -50%);
      left: 50%;
      right: 50%;
    }
  }
}

//TEAM RATING
.teamratingmain {
  background-color: #e6ecee;
  padding: 8px 25px;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;

  .leftsiderating {
    text-align: center;

    h4 {
      font-size: 32px;
      margin-bottom: 0px;
      font-weight: 700;
    }

    p {
      margin-bottom: 0px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
    }
  }
}

.boxscoremain {
  .row {
    align-items: center;
  }
  & .franchise::nth-first-child {
    margin-bottom: 1.5rem;
    @media screen and (min-width: 990px) {
      margin-bottom: 0;
    }
  }
}

//RATING TABLE
.teamratingmain {
  .ratingtable {
    table {
      th {
        background-color: #e6ecee !important;
        border-bottom: 1px solid #384c6c;
        padding: 5px 15px !important;
        text-align: center !important;
        color: #384c6c;
        font-weight: 600;
      }

      td {
        background-color: #e6ecee;
        padding: 5px 15px !important;
        text-align: center !important;
        color: #384c6c;
        font-weight: 600;
        border-bottom: 0px;
      }

      .ant-table-tbody {
        tr {
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

//SCHEDULE MAIN
.schedulemain {
  margin-top: 15px;

  h5 {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.3px;
    font-weight: 600;
    color: #384c6c;
  }

  .schedulesection {
    border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
    margin-top: 10px;

    .scheduletimemain {
      text-align: center;
      display: flex;

      .schedulechart {
        margin: 5px 0px;

        span {
          color: #031424;
          font-weight: 500;
          display: block;
          float: left;
          font-size: 14px;
          letter-spacing: 0.3px;
          min-width: 100px;
          text-align: left;
        }
      }

      .scheduleplan {
        margin: 5px 0px;

        span {
          float: right;
          color: #384c6c;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.3px;
        }
      }
    }

    .blueButton {
      font-size: 13px;
      border-radius: 3px;
    }
  }
}

//TEAM STAT
.schedulemain1 {
  h5 {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 700;
    color: #031424;
  }
}

.teamstadate {
  text-align: center;

  span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #384c6c;
  }
}

//GAME TIME
.gametime {
  text-align: center;

  h4 {
    color: #d75a57;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    color: #d75a57;
    margin-bottom: 0px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
}

.linkbutton {
  color: #384c6c;
  width: 180px;
  border-bottom: 1px solid #818991;
}

@media (max-width: 1199px) {
  .leftFranchise {
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .rightFranchise {
    margin-top: 10px;
  }

  .schedulemain {
    .schedulesection {
      text-align: center;
      padding: 15px;

      .blueButton {
        margin-bottom: 10px;

        &:nth-child(2) {
          margin-bottom: 0px;
        }
      }

      .scheduletimemain {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 991px) {
  .leftFranchise {
    justify-content: left;
    margin-bottom: 10px;

    img {
      margin-right: 5px;
    }
  }

  .rightFranchise {
    justify-content: right;
    margin-top: 10px;

    img {
      margin-left: 5px;
      margin-right: 0px;
    }
  }

  .schedulemain {
    .schedulesection {
      .blueButton {
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: 575px) {
  .leftFranchise {
    justify-content: center;
    margin-bottom: 15px;
  }

  .rightFranchise {
    justify-content: center;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .teamratingmain {
    .leftsiderating {
      margin-bottom: 15px;

      h4 {
        font-size: 25px;
      }
    }
  }

  .gametime {
    h4 {
      font-size: 25px;
      margin-bottom: 0px;
    }

    p {
      font-size: 14px;
    }
  }

  .schedulemain {
    h5 {
      font-size: 14px;
    }
  }

  .leftFranchise {
    p {
      font-size: 14px;
    }

    img {
      margin-right: 10px;
    }
  }

  .rightFranchise {
    p {
      font-size: 14px;
    }

    img {
      margin-right: 0px;
      margin-left: 10px;
    }
  }

  .teamratingmain {
    display: block;
  }

  .ratingtable {
    margin-bottom: 15px;
  }
}

.game-info-row {
  display: flex;
  flex-direction: row;
}

.ready-section {
  margin-top: 10px;
}

.ready-buttons {
  width: 130px;
  text-align: center;
  button {
    border: none;
    width: 130px;
    background-color: #c5403c;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.9px;
    transition: 0.5s all;
    cursor: pointer;
  }
}

.navigation-button {
  width: 130px;
  box-sizing: border-box;

  .nav-link .blueButton {
    width: 130px;
  }
}

.navigation-button > * {
  width: 100%;
}

#Ready {
  button {
    display: block;
    margin: 0 auto;
    border-radius: 12px;
    min-width: 160px;
    font-size: 16px;
    text-align: center;
    background-color: #384c6c;
    border: none;
    color: white;
    font-weight: 600;
    padding: 12px 32px;
  }
}

// NFT image

.nftImage {
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;
  object-position: 50% 50%;
  transform: scale(1.4);
}

// watch button

.watching-text {
  height: 15px;
  line-height: 15px;
  text-align: center;
}

.watching-text > p {
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  font-weight: 600;
  color: #cb1354;
}

.watching-link {
  color: #cb1354;
}

.plan-name {
  width: 170px;
  text-align: end;
}
