.averagepergame{
    th{
        display: revert;
        text-align: center!important;
    }
}

.teamstat{
    td{
        font-weight: 600!important;
        letter-spacing: 0.3px;
    }
}
