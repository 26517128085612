
$digit-color: #f5b400;
$digit-color-off: #323232;

.scoreboard__container {
  width: 260px;
  height: 120px;
  background-color: #4d4d4d;
  border: solid #282828 6px;
  border-radius: 15px;
  padding: 6px 3px;
  display: flex;
  flex-direction: column;
}

.scoreboard__line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 50%;

  & p {
    margin: 0;
    padding: 0;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
}

.scoreboard__time {
  border: solid #f5f5f5 2px;
  border-radius: 5px;
  background-color: #1a1a1a;
  padding: 4px 3px;
  display: flex;

  & .colon {
    color: $digit-color;
    font-size: 30px;
    display: inline-block;
    line-height: 24px;

    &.off {
      color: $digit-color-off;
    }
  }
}

.scoreboard__own-football {
  width: 10px;
  height: 10px;
  background-color: black;
  color: $digit-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.scoreboard__score {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.small p {
    font-size: 10px;
  }

  &.bottom-title p {
    line-height: 8px;
    padding-top: 4px;
  }
}

.scoreboard__numbers {
  display: flex;
  gap: 0.1rem;
  flex-direction: row;
}

.scoreboard__digit_container {
  position: relative;
  background-color: black;
  align-items: flex-end;
  font-family: "Segment7", sans-serif;
  font-weight: normal;
  font-style: normal;

  &.scoreboard__numbers_little {
    font-size: 28px;
    line-height: 25px;
    width: 16px;
    height: 23px;
  }
  
  &.scoreboard__numbers_small {
    font-size: 36px;
    line-height: 33px;
    width: 20px;
    height: 30px;
  }
  
  &.scoreboard__numbers_middle {
    font-size: 38px;
    line-height: 36px;
    width: 20px;
    height: 33px;
  }

  &.scoreboard__numbers_large{
    font-size: 42px;
    line-height: 37px;
    width: 22px;
    height: 34px;
  }

  & .scoreboard__digit {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 2px;
    color: $digit-color;
  }

  & .scoreboard__digit_blank {
    z-index: 0;
    color: #323232;
  }
}

.dottedball{
  width: 8px;
  height: 5px;
  border-radius: 50%;
  border: 1.4px dotted $digit-color;
}

