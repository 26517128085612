.cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
 
}



.cover-spin::after {
  content:'Metamask transaction in progress...';
  display:block;
  position:absolute;
  left:40%;top:40%;
  text-align: center;
  font-size: 20px;
  padding: 10px 32px;
  border: 2px solid #6c6969;
  font-weight: 600;
}
