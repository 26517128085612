.leagueListMain {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  margin-bottom: 15px;
  .leagueImage {
    img {
      height: 110px;
      object-fit: contain;
      width: 90px;
      margin-right: 10px;
    }
  }
  .leagueDetailLeft {
    background-color: #f3f5f7;
    padding: 1rem 1rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .leagueDetail {
    h5 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.5px;
      margin-bottom: 10px;
      max-width: 170px;
    }
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      span {
        &:nth-child(1) {
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.3px;
          color: rgba(3, 20, 36, 0.8);
        }
        &:nth-child(2) {
          float: right;
          font-weight: 600;
          letter-spacing: 0.5px;
          font-size: 13px;
          text-transform: uppercase;
          color: #031424;
        }
      }
    }
  }
  .leagueDetailRight {
    padding: 1.5rem 0px;
    h5 {
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.5px;
    }
    span {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.5px;
      color: #384c6c;
    }
    .row {
      margin-bottom: 5px;
    }
  }
}

.selectLeague {
  font-size: 13px !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.filterResults {
  color: rgba(56, 76, 108, 0.7);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.franchiseDropdown {
  margin-bottom: 25px;
  .form-label {
    font-size: 13px !important;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }
  option {
    font-size: 14px;
  }
}

.filterdropdown {
  label {
    display: none;
  }
}

//LEAGUE AVAILABLE
.leagueAvailableMain {
  border: 1px solid #ebebeb;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 10px;
  .leaguePrice {
    background-color: #384c6c;
    padding: 7px 0px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    h3 {
      color: #e6ecee;
      margin-bottom: 0px;
      font-weight: 800;
      sup {
        font-size: 14px;
        margin-right: 3px;
        font-weight: 400;
      }
    }
  }
  .leagueDetail {
    h6 {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
      color: #031424;
      margin-bottom: 3px;
    }
    .leagueDetailSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 30px;
      .cupContainer {
        position: relative;
        display: inline-block;
        height: 1px;
        width: 40px;
        overflow: visible;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -65%);  
        }
      }
      span {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #757678;
      }
      p {
        margin-bottom: 0px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #757678;
        font-size: 13px;
        display: inline;
      }
    }
  }

  .franchiseLink {
    margin-bottom: 0px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #757678;
    font-size: 13px;
    display: inline;
  }

  .joinButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #ebebeb;
    padding-left: 15px;
    gap: 10px;
    .blueButton,
    .pinkButton {
      display: flex;
      align-items: center;
      margin: 0 auto;
      font-size: 12px;
    }
  }
}

.leagueInvite {
  position: relative;
  label {
    position: absolute;
    background: #d1403c;
    height: 45px;
    border-radius: 50px;
    width: 170px;
    text-align: center;
    align-items: center;
    vertical-align: -webkit-baseline-middle;
    line-height: 45px;
    color: #fff !important;
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  input {
    padding-left: 190px !important;
    width: 100%;
  }
}

.leagueNewForm {
  .ant-divider-horizontal {
    margin: 15px 0px;
  }
}

//BELOW TEAM RATING
.leaguerating {
  .whiteButton {
    margin-right: 10px !important;
  }
  .whiteButton {
    padding: 8px 30px;
  }
  .blueButton,
  .pinkButton {
    padding: 8px 30px;
  }
}

//RESPONSIVE START
@media (max-width: 991px) {
  .leagueMain {
    .leagueDetail {
      h5 {
        margin-top: 10px;
        text-align: center;
      }
      .leagueDetailContent {
        margin-bottom: 10px;
      }
    }
  }
  .leagueAvailableMain {
    padding: 15px;
    .leagueDetail {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .joinButton {
      border-left: 0px;
      padding-left: 10px;
      margin-top: 10px;
      justify-content: center;
      border-top: 1px solid #f1f1f1;
      .blueButton,
      .pinkButton {
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;
        display: inherit;
        margin: inherit;
        padding: 10px 25px;
        width: 100px;
      }
    }
  }
}

@media (max-width: 767px) {
  .leagueListMain {
    .leagueImage {
      img {
        margin-bottom: 10px;
      }
    }
    .leagueDetailRight {
      .row {
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: 575px) {
  .leagueAvailableMain {
    .leaguePrice {
      margin-bottom: 10px;
    }
    .leagueDetail {
      .leagueDetailSection {
        display: block;
      }
    }
  }
}

// New
// .leagueDetailLeft{
//   .leagueImage{
//     width: 40%;
//   }
// }

.leagueDetailLeft {
  .leagueDetail {
    width: 100%;
  }
}

.leagueNewForm {
  .leagueInvite {
    position: relative;
    label {
      position: absolute;
      left: 10px;
    }
  }
}

@media (max-width: 480px) {
  .leagueNewForm {
    .leagueInvite {
      input {
        padding-left: 20px !important;
      }
      label {
        position: inherit;
        width: 100%;
        left: 0px;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 991px) {
  .leagueListMain {
    .leagueDetailRight {
      .row {
        margin-bottom: 10px;
      }
    }
  }
  .leagueListMain {
    .leagueDetailRight {
      padding-left: 15px;
    }
  }
}

@media (max-width: 575px) {
  .leagueListMain {
    .leagueDetailRight {
      .row {
        margin-bottom: 20px;
      }
    }
  }
  .leagueListMain {
    .leagueImage {
      img {
        margin-bottom: 0px;
      }
    }
  }
  .leagueNewForm {
    .filterdropdown {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 480px) {
  .leagueListMain {
    .leagueDetailLeft {
      display: block;
      .leagueImage {
        margin-right: 0px;
        margin-bottom: 15px;
      }
      .leagueDetail {
        h5 {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.startdraftmain {
  .modal_wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 20px !important;
    margin-top: 20px !important;

    .cancel_button {
      width: 220px;
      border-radius: 4px;
      background-color: #384c6c;
      border: 1px solid #384c6c;
      padding: 9px 30px;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.9px;
      transition: 0.5s all;
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        background-color: transparent;
        color: #384c6c;
      }
    }

    .success_button {
      width: 220px;
      border-radius: 4px;
      background-color: red;
      border: 1px solid red;
      padding: 9px 30px;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.9px;
      transition: 0.5s all;
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        background-color: white;
        color: red;
      }
    }
  }
}

.join-game-button {
  justify-content: center;
  width: 82px;
  height: 30px;
}

.leagueAvailableTeamNames {
  margin-bottom: 0px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #757678;
  font-size: 13px;
  display: inline;
}
