.ant-modal-content {
  max-width: 100%;
}

.play-now-wrapper {
  max-width: 100vw;
  h5 {
    margin: 0;
    text-wrap: nowrap;
    font-size: 25px;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    color: #CF6766;
  }
  .play-now-login-type-wrapper {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .play-now-login-type {
      display: flex;
      flex-direction: column;
      align-items: center;
        p {
          text-transform: uppercase;
          text-align: center;
        }
      .whiteButton, .blueButton {
        margin: 0;
        max-width: 200px;
        width: 150px;
      }
    }
  }

  @media(max-width: 768px) {
    h5 {
      font-size: 14px;
      text-wrap: pretty;
    }
  }

  @media(max-width: 480px) {
    h5 {
      font-size: 12px;
      text-wrap: pretty;
    }
  }
}

@media(max-width: 768px) {
  .ant-modal-content {
    max-width: 90%;
  }
}
