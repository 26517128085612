.ownfranchisemain {
  .cardbody {
    padding: 3rem 6rem;
  }
}

.ownfranchiselogo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin: 0 auto 25px auto;
  img {
    margin-right: 25px;
    width: 70px;
  }

  div {
    h5 {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 0.5px;
      color: #d1403c;
    }

    img {
      width: 15px;
      margin-right: 10px;
    }

    p {
      margin-bottom: 5px;
      color: #031424;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.3px;
      display: flex;
      align-items: center;
    }

    a {
      color: #d1403c;
    }
  }
}

@media (max-width: 767px) {
  .ownfranchiselogo {
    display: block;
    width: 100%;

    .logo {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    div {
      img {
        display: block;
        // margin: 0 auto 15px auto;
      }
    }

    div {
      h5 {
        text-align: center;
      }
    }
  }
}

@media (max-width: 575px) {
  .ownfranchiselogo {
    div {
      h5 {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 480px) {
  .ownfranchiselogo {
    div {
      p {
        font-size: 12px;
      }
    }
  }
}

.franchiselist {
  .leagueDetailRight {
    padding: 8px 0px;

    span {
      font-size: 10px;
    }
  }

  .detailbtn {
    padding-right: 15px;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;

    button {
      width: 100%;
      border-radius: 3px;
    }
  }
}

//BUTTON CSS
.ownfranchisemain {
  .blueButton {
    border-radius: 6px;
    font-size: 12px;
    padding: 10px 20px;
    letter-spacing: 0.5px;
    margin-right: 10px;
  }
}

.red-button {
  color: white !important;
  background: #d1403c !important;
  border: none !important;
  cursor: default;
}

@media (max-width: 991px) {
  .ownfranchisemain {
    .detailbtn {
      padding-bottom: 15px;
      padding-top: 10px;
    }

    .blueButton {
      padding: 10px 25px !important;
      font-size: 13px;
    }
  }

  .nofranchisesbutton {
    width: 90%;
    padding-top: 0px;
  }

  .franchiselist .leagueDetailRight span {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .nofranchisesbutton {
    width: 100%;
    p {
      text-align: justify;
    }
  }
}

@media (max-width: 768px) {
  .ownfranchisemain {
    .cardbody {
      padding: 1rem;
    }
  }
}

@media (max-width: 575px) {
  .ownfranchisemain {
    .ownfranchiselogo {
      display: block;
      margin-bottom: 15px;

      img {
        margin-bottom: 15px;
      }

      div {
        p {
          display: block;
        }

        img {
          margin-bottom: 0px;
        }
      }
    }
  }

  .ownfranchisemain {
    .detailbtn {
      padding-bottom: 15px;
      padding-top: 0px;
    }
  }
}

.statusApprovalNo {
  font-weight: 600;
  color: #d1403c;
  font-size: 12px;
  margin-bottom: 0px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.statusApprovalYes {
  font-weight: 600;
  color: #00a600;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.approvalmain {
  .ant-modal-footer {
    display: none;
  }
}

.leaguedetail_head {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;

  h5 {
    margin-right: 10px;
    margin-bottom: 0px;
  }

  p {
    margin-right: 15px;
  }

  span {
    font-size: 16px;

    p {
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 480px) {
  .leaguedetail_head {
    display: block;
  }

  .ownfranchisemain .cardbody {
    padding: 1rem !important;
  }
}

.detailbtn {
  .blueButton {
    font-size: 11px;
    margin-right: 0px;
    padding: 7px 11px;
    border-radius: 3px;
  }
}

.previewimage {
  position: relative;
  display: flex;
  justify-content: center;

  .imagePreviewDivRating {
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    color: white;
    font-size: 32px;
    font-weight: bold;
  }
}

.leagueDetailLeft .leagueImage .imgWithRatingContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-right: 10px;

  .imgFluid {
    margin: 0;
  }

  .ratingValue {
    position: absolute;
    bottom: 0;
    color: white;
    font-size: 8px;
    font-weight: bold;
  }
}

@media (max-width: 991px) {
  .leaguedetail_head {
    margin: 15px 15px 15px 15px;
  }

  .leaguedetail_head p {
    margin-right: 0px;
  }

  .franchiselist .leagueDetailRight {
    padding: 0px 15px 0px 15px;
  }

  .leagueDetailLeft .leagueImage {
    width: auto;
    margin-right: 15px;
  }

  .leagueDetailLeft .leagueDetail {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .ownfranchisemain .cardbody {
    padding: 2rem;
  }
}

@media (max-width: 600px) {
  .leagueDetail {
    width: 100% !important;
  }
}
