.playoff-info {
  display: flex;
  align-items: center;

  &__super-bowl {
    border-radius: 10px;
    border: 1px solid #c0c0c0;
    padding: 10px;

    &__top-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h6 {
        width: 100px;
        text-align: center;
      }

      img, div {
        width: 50px;
      }
    }

    &__teams {
      display: flex;
      width: 100%;
      justify-content: space-between;

      span {
        margin-top: 40px;
      }
    }

    &__bottom-info {
      display: flex;
      justify-content: space-between;

      h6 {
        width: 100px;
        text-align: center;
      }
    }
  }
}
