.playerreveal-main {
  margin-bottom: 30px;
  position: relative;
  background-image: url(../../assets/images/franchiseTeamCreation/background.jpg);
  width: 100%;
  height: 600px;
  border-radius: 5px;
  background-repeat: no-repeat;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(3, 20, 36, 0.9);
    border-radius: 5px;
  }
}

@media (max-width: 767px) {
  .playerreveal-main {
    height: 600px;
  }
}

@media (max-width: 575px) {
  .playerreveal-main {
    height: 580px;
  }
}

@media (max-width: 480px) {
  .playerreveal-main {
    height: 650px;
  }
  .player_image_animation {
    margin: 0px;
  }
  .flip-container {
    top: 20px;
  }
}

@media (max-width: 350px) {
  .playerreveal-main {
    height: 640px;
  }
}

.playerreveal-main .background-image {
  width: 100%;
  border-radius: 5px;
}

.playerreveal-main {
  .playerreveal-detail {
    position: absolute;
    top: 0;
    margin: 0 auto;
    width: 100%;
    transform: translateX(0%);
    text-align: center;
    overflow: hidden;
    padding: 30px;
  }
}

.playerreveal-detail {
  h4 {
    font-size: 35px;
    font-family: "barlow";
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
  }
}

.playerreveal-detail {
  p {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
}

.playerreveal-slider {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .playerreveal-slider {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .playerreveal-detail {
    h4 {
      font-size: 25px;
    }
  }
}

@media (max-width: 480px) {
  .playerreveal-detail {
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
}

// CAROUSEL CUSTOM SPEED INDICATOR
.customplayer_speed {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0px 25px;
  align-items: center;
}

.reveal_speed {
  h6 {
    color: #fff;
  }
}

.customplayer_speed {
  a {
    background-color: transparent;
    border: 1px solid #fff;
    padding: 3px 25px;
    border-radius: 3px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-decoration: none !important;
    color: #fff !important;
    font-size: 12px;
  }
}

// PLAYER REVEAL COMPLETE POPUP
.playerreveal_complete {
  text-align: center !important;
  .ant-modal-footer {
    display: none;
  }
  &__actions-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

.playerreveal_complete {
  h5 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #384c6c;
  }
}

.playerreveal_complete {
  p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
  }
}

.playerreveal_complete {
  span {
    font-size: 18px;
    font-weight: 800;
  }
}

.carousel_main .slick-track .slick-slide.slick-active {
  &:nth-child(1) {
    .player-image {
      filter: blur(0px);
    }
  }
}

.reveal_speed {
  .ant-slider-track {
    background-color: #fff;
  }
  .ant-slider-rail {
    background-color: #91d5ff;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #fff;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #91d5ff;
  }
}

@media (max-width: 480px) {
  .customplayer_speed {
    display: block;
    padding: 0px 15px;
    .reveal_speed {
      margin-bottom: 20px;
      .ant-slider {
        margin: 10px 0px;
      }
    }
  }
}
