.leaguepaymentmain{
    .cardbody{
        padding: 2rem;
    }
}

//LEAGUE IMAGE
.leagueimage{
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    img{
        width: 100%;
        border: 1px solid #031424;
        padding: 8px;
        border-radius: 5px;
    }
    .ratingValue {
        position: absolute;
        bottom: 10px;
        color: white;
        font-size: 10px;
        font-weight: bold;
    }
    h3{
        color: #384C6C;
        font-weight: 700;
        font-size: 30px;
    }
    p{
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #384C6C;
        margin-bottom: 3px;
    }
}

//LEAGUE PAYMENT CONTENT
.leaguecontent{
    h5{
        color: #384C6C;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
        letter-spacing: 0.5px;
    }
    .leaguedetail{
        td{
            font-size: 14px;
            padding-bottom: 8px;
            &:nth-child(1){
                color: rgba(56,76,108,0.7);
                font-weight: 600;
                padding-right: 25px;
            }
            &:nth-child(2){
                color: rgba(3,20,36,0.7);
                font-weight: 600;
            }
        }
    }
}

//PERFORMANCE PAY-OUT TABLE
.performancepay{
    margin-top: 20px;
    table{
        border: 1px solid #f1f1f1;
    }
    td{
        border: 0px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1!important;
        font-size: 13px;
        padding: 5px 15px!important;
    }
    tr{
        border: 1px solid #e5e5e5;
        border-width: 1px;
        border-collapse: collapse;
    }
    th{
        font-size: 14px;  
    }
}

//PERFORMANCE PAY CONTENT
.performancepaycontent{
    margin-top: 20px;
    h5{
        color: #384C6C;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 5px;
        letter-spacing: 0.5px;
    }
    p{
        color: rgba(56,76,108,0.7);
        font-weight: 500;
        letter-spacing: 0.5px;
        text-align: justify;
        font-size: 14px;
    }
    ul{
        padding-left: 20px;
        margin-bottom: 5px;
        li{
            color: rgba(56,76,108,0.7);
            font-weight: 500;
            letter-spacing: 0.5px;
            text-align: justify;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
    a{
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #384c6c;
        text-transform: capitalize;
    }
}

.termsservice{
    p{
        margin-bottom: 7px;
    }
}

//GMD TOKEN
.gmdtoken{
    display: flex!important;
    align-items: center;
    justify-content: center;
    padding: 7px;
    transition: border 0.3s ease-in-out;
    border: 2px solid transparent;

    p{
        margin-bottom: 0px;
        font-weight: 600;
        color: #384C6C;
        font-size: 13px;
        letter-spacing: 0.5px;
    }
    img{
        margin-right: 5px;
        width: 20px;
    }

    &:hover {
        border: 2px solid rgba(0, 0, 0, 0.06);
    }
}

// Responsive
@media(max-width: 575px){
    .leagueimage{
        text-align: left;
        img{
            width: auto;
            height: 200px;
        }
    }
}
