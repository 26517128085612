.add-user-button {
  margin-top: 29px; 
  height: 45px;
}

.found-user-list {
  color: #384c6c;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 10px;
  border-bottom: 1px solid #d1d1d1;

  &:last-of-type {
    border-bottom: 0px;
  }
}