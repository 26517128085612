.team-leader-item-container {
  margin: 0 auto;
  width: 90%;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-bottom: 15px;
  box-sizing: border-box;
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;

  .helmet-container {
    position: relative;
    height: 100px;

    .helmet {
      width: 128px;
      height: unset;
      margin-top: -10px;
      image-rendering: unset;
      object-position: unset;
      transform: unset;
      object-fit: contain;
    }

    .logo {
      position: absolute;
      width: 30px;
      left: 60px;
      top: 24px;
    }
  }

  .team-leader-item-order {
    position: absolute;
    left: -17px;
    background-color: #D1403C;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    color: white;
    font-weight: bold;
  }
}

.team-leader-item-names {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;

  div:first-of-type {
    font-weight: bold;
  }
}

.team-leader-item-value {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.leaderboard-container {
  padding: 0 20px;

  & .leaderboard__leader {
    display: flex;
    flex: 1;
    flex-direction: column;

    & .view-top-100 {
      width: 100%;
    }

    & .leader__card {
      display: flex;
      border: 1px solid #ebebeb;
      height: 140px;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      padding: 20;

      & img {
        margin-left: 10px;
        max-width: 100px;
        max-height: 100px;
      }

      & h4 {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.5;

        @media (max-width: 600px) {
          font-size: 16px;
        }
      }

      & h5 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5;

        @media (max-width: 600px) {
          font-size: 14px;
        }
      }

      & .value__card {
        background-color: #d1403c;
        color: #fff;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        margin-right: 10px;

        & p {
          &:first-of-type {
            font-size: 26px;
          }

          font-size: 22px;
          font-weight: 700;
          margin: 0;
          padding: 0;

          @media (max-width: 600px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.leader__list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px;

  & .leader__list__item {
    display: flex;
    flex-direction: row;
    justify-items: start;
    gap: 4px;
    min-height: 20px;
    width: 100%;

    & .leader__list__item__order {
      height: 100%;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
    }

    & .leader__list__item__text-container {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    & .leader__list__item__text {
      margin-right: 20px;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.view-more-players-modal .ant-modal-content {
  width: 680px;
}

.leaders_row_container {
  display: flex;
  gap: 30px;
  align-items: start;
  justify-content: 'space-between';
}

.view-more-modal {
  & .ant-modal-content {
    width: 690px;
  }

  & .ant-modal-header {
    border: 0;

    & .ant-modal-title {
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.view-top-100 {
  text-align: end;
  text-decoration: underline;
  width: 90%;
  cursor: pointer;
  margin-bottom: 10px;
}

.scroll-container {
  max-height: 628px;
  width: 600px;
  max-width: 100%;
  overflow: auto;
  margin-left: 10px;

  @media (max-width: 650px) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    border: 1 solid #f1f1f1;
    border-radius: 5px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #384c6c;
    border: 1 solid #f1f1f1;
    border-radius: 5px;
  }
}