.organizational-chart {
    display: flex;
    font-size: 0.75em;
    padding: 0 20px;
}
.organizational-chart ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}
.organizational-chart li {
    box-sizing: border-box;
    position: relative;
    margin: 10px 0px;
    padding: 8px 12px;
    list-style: none;
    text-align: center;
    white-space: nowrap;
    background-color: #f3f5f7;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.7px;
}

.organizational-chart li::before,
.organizational-chart li::after {
    content: '';
    position: absolute;
    display: block;
    box-sizing: border-box;
}
.organizational-chart li::before {
    top: 50%;
    left: 100%;
    width: 3em;
    border: 1px solid #d7d8d9
}
.organizational-chart li::after {
    top: 7px;
    right: -3em;
    height: 1em;
    border: 1px solid #d7d8d9
}
.organizational-chart ul:last-child li::before,
.organizational-chart ul:last-child li::after {
    content: none;
}
.organizational-chart li:nth-child(odd)::after {
    top: 50%;
}
.organizational-chart ul:nth-child(2) li::after {
    height: 3em;
}
.organizational-chart ul:nth-child(2) li:nth-child(even)::after {
    top: -56%;
}
.organizational-chart ul:nth-child(3) li::after {
    height: 10em;
}
.organizational-chart ul:nth-child(3) li:nth-child(even)::after {
    top: -300%;
}
.organizational-chart ul:nth-child(4) li::after {
    height: 21.4em;
}
.organizational-chart ul:nth-child(4) li:nth-child(even)::after {
    top: -700%;
}
.organizational-chart ul:nth-child(5) li::after {
    height: 31em;
}
.organizational-chart ul:nth-child(5) li:nth-child(even)::after {
    top: -1500%;
}
.organizational-chart li.blank{
    padding: 0px;
    margin-bottom: 20px;
}
.cupbackground{
    background-color: transparent!important;
    margin-left: 10px;
    img{
        width: 50%;
        margin-bottom: 15px;
    }
    h6{
        font-size: 14px;   
        margin-bottom: 0px; 
        font-weight: 600;
        color: #384c6c;
        margin-bottom: 5px;
    }
    h4{
        font-size: 16px;
        font-weight: 600;
        color: #384c6c;
        font-style: italic;
    }
}

@media (min-width: 36rem) {
    .organizational-chart ul + ul li {
        margin-left: 2em;
    }
}

@media(max-width: 991px){
    .organizational-chart{
        width: 100%;
        overflow-x: scroll;
    }
}

:root .organizational-chart li:empty {
    visibility: hidden;
}

.elimination-chart-item {
    height: 36px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #000;
}

.third-place-game-tree {
    margin-top: 40px;
}

.elimination-is-redirectable {
    cursor: pointer;
}

.elimination-chart-item-score {
    border-left: 1px solid #000;
    padding-left: 12px;
    width: 26px;
}

.elimination-chart-item-winner {
    color: green;
}
.elimination-chart-item-loser {
    color: #c00000;
}

.elimination-chart-item-helmet {
    position: absolute;
    left: -38px;
    transform: translateY(14px);
}

.elimination-chart-item-helmet-placeholder {
    width: 26px;
}

.elimination-schedule-header {
    width: 100%;
    text-align: center;
}

.elimination-chart-game-status {
    position: absolute;
    font-size: 10px;
    color: rgb(33, 37, 41);
    bottom: -20px;
    right: 70px;
    cursor: pointer;
    text-decoration: underline;
}

.elimination-schedule-tab-container {
    padding: 0 20px;
}