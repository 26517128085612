.forgot-password-component {
  text-align: center;

  h5 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
  }

  p {
    font-weight: 500;
    line-height: 25px;
  }

  .login-button {
    width: 100%;
    margin-top: 10px;
  }

  .forgot-password-form {
    text-align: left;
  }
}