.chartdatamain {
	h5 {
		font-size: 18px;
		text-align: center;
		font-weight: 700;
		letter-spacing: 0.2px;
		color: #384c6c;
		margin: 20px 0;
	}
}

//
.chartdatamain {
	.accordion-button {
		background-color: #fff !important;
		box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05%) !important;
		border-radius: 5px !important;
		border: 1px solid #f1f1f1;
		padding: 12px;
		&:after {
			display: none;
		}
		&:focus {
			border-color: #f1f1f1 !important;
		}
	}
	.summary {
		.accordion-button {
			&:after {
				display: block;
			}
		}
	}

	.accordion-body {
		background-color: #ecf0f1;
	}
	.accordion-item {
		margin-bottom: 15px;
		border-bottom: 0px;
	}
}

//ACCORDIAN HEADER
.accordianheadermain {
	display: flex;
	justify-content: space-between;
	width: 100%;
	//ACCORDIAN LEFT
	.accordianleft {
		display: flex;
		align-items: center;
		img {
			margin-right: 15px;
		}
		div {
			p {
				font-size: 16px;
				color: #384c6c;
				font-weight: 600;
				margin-bottom: 0px;
				margin-bottom: 5px;
				&:nth-child(2) {
					color: #031424;
					font-weight: 500;
					font-size: 13px;
					letter-spacing: 0.3px;
					margin-bottom: 0px;
				}
			}
		}
	}
	//ACCORDIAN RIGHT
	.accordianright {
		display: flex;
		align-items: center;
		div {
			text-align: center;
			p {
				margin-bottom: 5px;
				color: #031424;
				font-weight: 500;
				font-size: 12px;
				letter-spacing: 0.5px;
			}
			&:nth-child(2) {
				margin-left: 15px;
				margin-right: 25px;
			}
			h6 {
				margin-bottom: 0px;
			}
		}
	}
}

.helmet-container {
	position: relative;
	.helmet {
		width: 40px;
		height: 50px;
		margin-top: 20px;
		object-position: 0 3px;
		transform: scale(1.6);
		object-fit: cover;
	}
	.logo {
		position: absolute;
		width: 15px;
		left: 18px;
		top: 28px;
	}
}
.gamesummary {
	padding: 15px 0;
	.action {
		display: flex;
		&__item {
			margin-right: 10px;
			&--bold {
				font-weight: bold;
			}
		}
	}
}

//RESPONSIVE
@media (max-width: 576px) {
	.accordianheadermain {
		display: block;
		.accordianright {
			margin-top: 20px;
		}
	}
}

.chartdatamain > .accordion-flush > .accordion-item > .accordion-header > .accordion-button {
	cursor: unset;
}
