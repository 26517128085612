.teams {
  gap: 1rem;

  h2 {
    font-size: 20px;
    margin-bottom: 1rem;
    margin-left: 0 !important;
  }
}

.section {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    background-color: #384c6c;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-transform: uppercase;

    h5 {
      font-size: 14px !important;
      margin-bottom: 0;
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .team-selector-component {
        div {
          select {
            height: unset !important;
            margin-bottom: 0 !important;
          }
        }
      }

      button {
        border-radius: 4px;
        font-size: 11px;
        padding: 0.25rem 0.5rem;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
      }

      .pinkButton {
        min-width: 100px;
        width: unset;
      }

      .pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        .icon {
          border: none;
          outline: none;
          color: #fff;
          background-color: gray;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 25px;
          font-size: 1rem;
          line-height: 25px;
          padding: 0;
          transition: transform 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            transform: scale(1.15);
          }

          span {
            padding-bottom: 0.15rem;
          }

          &:disabled {
            opacity: 0.5;
            cursor: default;
            &:hover {
              transform: scale(1);
            }
          }
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }

  &__body {
    background-color: #f3f5f7;
    color: #000000;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    min-height: 10rem;
    .friend {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      cursor: pointer;

      .img__container {
        position: relative;
        height: 100px;
        width: 100px;
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        .indicator {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 30px;
          width: 30px;
          background-color: #d1403c;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 18px;
            height: 18px;
            color: #fff;
          }
        }
      }

      p {
        font-size: 11px;
        margin: 0;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}
