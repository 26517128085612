$width: 64px;
$aspectRatio: 1889 / 1500;
$height: calc(#{$width} * #{$aspectRatio/2});

.render-helmet-container {
	position: relative;
	width: $width;
	height: $height;
	background-size: cover;

	.render-logo {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
	}
}