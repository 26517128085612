.statmain{
    .cardbody{
        padding: 1rem 7rem;
    }
}


//STATS & COMPARISON
.statdatamain{
    h5{
        font-size: 18px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 0.2px;
        color: #384C6C;
        margin: 20px 0;
    }
    .franchiselabel{
        text-align: center;
        span{
            color: #000;
            padding: 10px;
            font-weight: bold;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 0.3px;
        }
    }
}

//TABLE VIEW TEAM STAT
.teamstattable{
    margin-top: 20px;
    th{
        background: #ffff !important;
    }
    tbody{
        tr{
            box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.05%);
            &:nth-child(even){
                background-color: #F0F1F5;
            }
        }
    }
    td{
        text-align: center;
        font-weight: 500;
    }
}

//FONT WEIGHT
.weight{
    font-weight: 700;
}

//RESPONSIVE STYLE
@media(max-width: 991px){
    .statmain{
        .cardbody{
            padding: 1rem 1rem;
        }
    }
}