.season-scores {
  display: flex;
  flex-direction: column;
  gap: 20px;

  select.form-select {
    width: 25%;
    border-radius: 3px !important;
  }
}
