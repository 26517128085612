.main-content {
    background-image: url('./back.jpg');
    min-height: 750px;
    padding-top: 25px;
}

.league-entered-title {
    width: auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    font-weight: 700;
    font-family: sans-serif;

    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.league-info-section {
    width: 80%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    border-radius: 10px;
    padding: 10px 30px;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 460px) {
        gap: 15px;
    }
}

.league-prize-pool {
    // margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.league-prize-pool>p>.mvp-price {
    line-break:strict;
    font-weight: 600;
    font-size: 50px!important;
    margin: 0;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
}

.league-prize-pool span .mvp-price__crown {
    font-weight: 600;
    font-size: 30px!important;
    line-height: 50px!important;
    height: 50px!important;
    vertical-align: top;
}


.league-info {
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    @media(max-width: 460px) {
        font-size: 14px;
    }

        @media(max-width: 550px) {
            font-size: 16px;
        }
}

.league-game-info {
    // max-height: 15px;
    // margin-bottom: 10px;
}

.league-game-info p {
    font-weight: 600;
    margin: 0;
}

.league-additional-info {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    @media(max-width: 840px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    p {
        margin: 0;
    }
}

.league-info-value {
    font-weight: 500;
}

.game-id {
    font-weight: 400;
}

.instructions-text-section {
    margin-bottom: 50px;
}

.instructions {
    text-align: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-text {
        font-size: 25px;
        color: white;
        width: 60%;
        text-align: center;
        margin: auto;
    
        @media(max-width: 550px) {
            width: 80%;
            font-size: 16px;
        }

        p {
            text-align: center;
        }
    }

    .button {
        border-radius: 6px;
        color: white;
        font-size: 17px;
        height: 55px;
        width: 380px;
        font-weight: 500;
    
        @media(max-width: 550px) {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
    
            font-size: 14px;
        }
    }
}



.invite-button {
    background-color: #2C3E5A;
    margin-bottom: 20px;
}

.waiting-button {
    background-color: #C5403C;
}

#mainContent {
    background-image: url('./back.jpg');
    min-height: 750px;
    // display: inline;
}

#MainUpper p {
    width: auto;
    color: white;
    // border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    font-weight: 700;
    font-family: sans-serif;
}

#MainUpper_1 {
    min-height: 50px;
    // border: 1px solid black;
    width: 80%;
    background-color: white;
    display: flex;
    margin-left: 150px;
    margin-top: 40px;
}

#MainUpper_1 div {
    margin-left: 10px;
}

#MainUpper_1_left {
    width: auto;
    // border: 1px solid black;
    justify-items: center;
    align-items: center;
    color: red;
}

#MainUpper_1_left p {
    margin: 20px 10px 10px 20px;
}



#MainUpper_1_right {
    display: flex;
    width: auto;
    // background-color: white;
    // border: 1px solid black;

}

#MainUpper_1_right p {
    margin: 0px 0px 0px 30px;
}


#MainUpper_2_right {
    display: flex;
    justify-content: space-between;
}

#MainUpper_2_right pre {
    margin: 0px 20px 0px 20px;
}


#MainUpper_3 {
    // background-color: antiquewhite;
    min-height: 150px;
    width: auto;
    margin-top: 60px;
}

#MainUpper_3_1 p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 450;
}

#wait button {
    background-color: white;
    color: black;
}


#MainUpper_3_2 p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    // border: 1px solid black;
}



#MainUpper_3_3 {
    // background-color: #384C6C;
    width: auto;
    min-height: 30px;
}



#MainUpper_3_3 button {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #384C6C;
    // border: 1px solid red;
    margin: 4% 30% 40px 30%;
    text-decoration: none;
    width: 40%;
    height: 50px;
    font-size: larger;
}

#MainUpper_3_3 a :hover {
    cursor: pointer;
}


.MainUpper_3_31 {
    // background-color: #384C6C;
    width: auto;
    min-height: 30px;
    z-index: 10;
}



.MainUpper_3_31 button {
    color: white;
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: center;
    background-color: #384C6C;
    // border: 1px solid red;
    margin: 4% 30% 40px 30%;
    text-decoration: none;
    width: 40%;
    height: 50px;
    font-size: larger;
}

.MainUpper_3_31 a :hover {
    cursor: pointer;
}

.wait {
    width: auto;
    min-height: 30px;

    button {
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 4% 30% 40px 30%;
        text-decoration: none;
        width: 40%;
        height: 50px;
        font-size: larger;
    }
}